import React, { useState } from "react";
import { Modal, IconButton, Divider, TextField, Button } from "@mui/material";
import { GeneralModalProps } from "../assets/interfaces/interfaces";
import { showNotification } from "../redux/slices/notificationSlice";
import { useDispatch } from "react-redux";
import { MessageIcon } from "./Plans/icons/GPTIcons/MessageIcon";
import { CrossIcon } from "./Plans/icons/GPTIcons/CrossIcon";

const ContactSupportModal: React.FC<GeneralModalProps> = ({
  open,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const isFormValid = name.length && subject.length && message.length;

  const onSubmit = (event) => {
    event.preventDefault();
    try {
      const sub = encodeURIComponent(subject + " - " + name);
      const body = encodeURIComponent(message);
      const mailto = `mailto:support@tradealgo.com?subject=${sub}&body=${body}`;
      window.location.href = mailto;
      // dispatch(showNotification({ message: 'Form submitted successfully', severity: 'success', horizontal: 'center' }));
      setName("");
      setSubject("");
      setMessage("");
    }
    catch {
      dispatch(showNotification ({ message: "Form submission failed", severity: "error", horizontal: "center" }));
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="flex flex-col gap-y-6 shadow w-modal-mobile sm:w-modal-md rounded-lg p-6 bg-white text-mui-black-87 dark:bg-background/paper-elevation-16 dark:text-white">
          <div className="flex flex-col gap-y-4 relative">
            <h6 className="font-medium">Contact Support</h6>
            <IconButton
              size="small"
              onClick={handleClose}
              className="cross-icon"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <CrossIcon className="fill-current" />
            </IconButton>
            <Divider sx={{ width: "100%" }} />

            <div className="flex flex-col gap-y-2">
              <div className="body1 font-bold">Got Questions?</div>
              <div className="body2">
                <p className="mb-2">Let us know what problem you are facing.</p>
                <p>
                  If you have general questions about TradeGPT, check out our
                  {" "}
                  <a href="/faq" className="text-mui-info-blue underline">
                    FAQ & Help Center
                  </a>
                  {" "}
                  where you might find help even quicker.
                </p>
              </div>
            </div>
          </div>
          <form
            onSubmit={onSubmit}
            autoComplete="off"
            className="flex flex-col gap-y-6"
          >
            <div className="flex flex-col gap-y-6 outlined-input labelText">
              <TextField
                id="name"
                label="Your name"
                variant="outlined"
                placeholder="Your full name"
                fullWidth
                value={name}
                onKeyDown={e => e.stopPropagation()}
                onChange={e => setName(e.target.value)}
              />
              <TextField
                id="subject"
                label="Your subject"
                variant="outlined"
                placeholder="subject"
                fullWidth
                value={subject}
                onKeyDown={e => e.stopPropagation()}
                onChange={e => setSubject(e.target.value)}
              />
              <TextField
                id="message"
                label="Your message"
                variant="outlined"
                placeholder="I'd like to learn more about TradeGPT"
                multiline
                fullWidth
                value={message}
                onKeyDown={e => e.stopPropagation()}
                onChange={e => setMessage(e.target.value)}
              />
            </div>

            <div className="w-full sm:w-80 self-center">
              <Button
                className="button-primary flex gap-x-2"
                variant="contained"
                size="large"
                fullWidth
                disabled={!isFormValid}
                onClick={onSubmit}
              >
                <MessageIcon
                  className={
                    isFormValid
                      ? "fill-white"
                      : "fill-mui-black-38 dark:fill-mui-white-38"
                  }
                />
                Send Support Request
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ContactSupportModal;
