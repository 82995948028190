export const StocksIcon = ({ color = "black", className }: { color: string; className: string }) => {
  return (
    <svg
      className={`${className}`}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.417 2.91669H15.5003C16.6509 2.91669 17.5837 3.84943 17.5837 5.00002V8.59093L15.917 7.50002L13.9094 11.1364L13.8492 11.2115C14.2213 11.5172 14.4587 11.9809 14.4587 12.5C14.4587 13.4205 13.7125 14.1667 12.792 14.1667C11.8715 14.1667 11.1253 13.4205 11.1253 12.5C11.1253 11.8755 11.4688 11.3313 11.9771 11.0458L10.7276 8.86366L9.36396 11.5909L8.00033 9.77275L7.09123 11.5909L5.27305 12.0455L3.41699 11.6743V5.00002C3.41699 3.84943 4.34973 2.91669 5.50033 2.91669H12.167L12.167 10.9545C12.36 10.8764 12.571 10.8334 12.792 10.8334C13.013 10.8334 13.224 10.8764 13.417 10.9545L13.417 2.91669ZM2.16699 5.00002C2.16699 3.15907 3.65938 1.66669 5.50033 1.66669H15.5003C17.3413 1.66669 18.8337 3.15907 18.8337 5.00002V15C18.8337 16.841 17.3413 18.3334 15.5003 18.3334H5.50033C3.65938 18.3334 2.16699 16.841 2.16699 15V5.00002ZM13.6253 12.5C13.6253 12.9603 13.2522 13.3334 12.792 13.3334C12.3318 13.3334 11.9587 12.9603 11.9587 12.5C11.9587 12.0398 12.3318 11.6667 12.792 11.6667C13.2522 11.6667 13.6253 12.0398 13.6253 12.5Z"
        fill={color}
      />
    </svg>
  );
};
