export const IconsIconTrendingUp1 = ({ color = "black", className }: { color: string; className: string }) => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.39102 20.4053C3.88288 20.9148 3.86333 21.7377 4.37147 22.2668C4.87961 22.7958 5.70046 22.7958 6.22814 22.2863L11.6418 16.996L16.1564 21.3654C16.6841 21.8749 17.5049 21.8553 18.0131 21.3262L25.3811 13.8414V17.623C25.3811 18.348 25.9674 18.9358 26.6906 18.9358C27.4137 18.9358 28 18.348 28 17.623V10.6476C28 10.3145 27.8632 9.96178 27.6091 9.70706C27.3355 9.43274 26.9642 9.31518 26.6124 9.33477H20.1043C19.3812 9.33477 18.7948 9.92259 18.7948 10.6476C18.7948 11.3725 19.3812 11.9604 20.1043 11.9604H23.544L17.0359 18.5831L12.5408 14.2333C12.0327 13.7434 11.2314 13.7434 10.7232 14.2333L4.39102 20.4053Z"
        fill={color}
      />
    </svg>
  );
};
