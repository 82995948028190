import React from "react";
import { Modal, Button, Typography, IconButton } from "@mui/material";
import ReactMarkdown from "react-markdown";
import moment from "moment";
import useEnhancedNavigate from "./Session/helpers";
import { ChangeLog } from "../assets/interfaces/interfaces";
import { CrossIcon } from "./Plans/icons/GPTIcons/CrossIcon";

interface ChangeLogModalProps {
  open: boolean;
  handleClose: () => void;
  log: ChangeLog;
}

const ChangeLogModal: React.FC<ChangeLogModalProps> = ({ open, handleClose, log }) => {
  const navigate = useEnhancedNavigate();
  const navigateToChangeLogs = () => {
    navigate("/release-notes");
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose} className="flex items-center justify-center p-4 sm:p-0">
      <div className="shadow w-modal-mobile sm:w-modal-sm md:w-modal-md rounded-lg bg-white dark:bg-background/paper-elevation-16 text-mui-black-87 dark:text-white outline-none">
        <div className="flex items-start justify-between pt-4 pb-2 px-4 rounded-t-lg">
          <Typography variant="h6">
            {log.title}
          </Typography>
          <IconButton
            size="small"
            onClick={handleClose}
            className="cross-icon"
          >
            <CrossIcon className="fill-current" />
          </IconButton>
        </div>
        <div className="pb-6 px-4 text-mui-black-60 dark:text-mui-white-70">
          <Typography variant="subtitle2">
            Release Note for v
            {log.version}
            {" "}
            on
            {" "}
            {moment.utc(log.date).format("MMM D, YYYY")}
          </Typography>
        </div>
        <div>
          <ReactMarkdown className="markdown-body change-log-markdown">
            {log.description}
          </ReactMarkdown>
        </div>
        <div className="p-6 flex justify-center">
          <Button
            variant="outlined"
            className="!px-6 !py-2 !text-[15px] !text-transform-none !text-mui-gray dark:!text-white hover:bg-mui-gray-04 dark:hover:bg-mui-light-gray-08 !border-mui-gray dark:!border-white"
            onClick={navigateToChangeLogs}
          >
            Read all release notes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeLogModal;
