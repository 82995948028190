import { Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { useMemo } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useSelector } from "react-redux";
import { RootState } from "../assets/interfaces/interfaces";
import Fade from "@mui/material/Fade";

// Component to display suggestion prompts
const SuggestionPrompts = ({ onPromptSelect }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const prompts = useSelector(
    (state: RootState) => state?.prompts.prompts,
  );
  const authLoading = useSelector((state: { auth }) => state.auth.loading);
  const { status } = useSelector((state: { plan }) => state.plan);
  const disablePrompts = authLoading || status !== "succeeded";
  const numItems = useMemo(() => {
    let num = 4;
    if (isMobile) num = 2;
    return num;
  }, [isMobile]);
  return (
    <div>
      <div className="h-full flex md:w-full md:m-auto md:mb-4 gap-0 md:gap-2 justify-center">
        <div className="grow">
          <div className="absolute bottom-full left-0 mb-4 flex w-full grow gap-2 px-1 pb-1 sm:px-2 sm:pb-0 md:static md:mb-0 md:max-w-none">
            <div className="grid w-full grid-flow-row grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2">
              {prompts?.slice(0, numItems).map((prompt, index) => (
                <Fade
                  key={`${prompt.title}-${index}`}
                  in={true}
                  style={{ transformOrigin: "0 0 0", transitionDelay: `${index * 21}ms` }}
                >
                  <button disabled={disablePrompts} type="button" onClick={() => onPromptSelect(prompt.title + " " + prompt.description)} className="btn relative btn-neutral group w-full whitespace-nowrap rounded-xl px-4 py-3 text-left text-token-text-primary md:whitespace-normal">
                    <div className="flex w-full gap-2 items-center justify-center">
                      <div className="flex w-full items-center justify-between">
                        <div className="flex flex-col overflow-hidden">
                          <div className="truncate">{prompt.title}</div>
                          <div className="truncate font-normal opacity-50">{prompt.description}</div>
                        </div>
                        <div className="absolute bottom-0 right-0 top-0 flex items-center leading-none rounded-xl bg-gradient-to-l from-gray-50 from-[60%] pl-6 pr-4 text-token-text-secondary opacity-0 group-hover:opacity-100 dark:from-gray-700">
                          <Tooltip title="Click to Send">
                            <div className="rounded-lg bg-token-surface-primary p-1 shadow-xxs dark:shadow-none">
                              <ArrowUpwardIcon className="!w-4 !h-4" />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </button>
                </Fade>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuggestionPrompts;
