import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  browserLocalPersistence,
  getAuth,
  getIdToken,
  onAuthStateChanged,
  setPersistence,
  signInWithCustomToken,
  User as FUser,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { setUser, clearUser, setAuthLoading } from "./authActions";
import { fetchActivePlan } from "../../redux/slices/activePlanSlice";
import { AppDispatch } from "../../redux/store";
import { fetchUser } from "../../redux/slices/userSlice";
import { fetchToken } from "../../redux/slices/fetchAuthToken";
import axios from "axios";
import { extractParams, urlIsAppScheme } from "../Session/helpers";
import { fetchValidateUser } from "../../redux/slices/validateUserSlice";
import { sapStr, ssoAtStr } from "../../redux/sagas/authSaga";
import { useCookies } from "react-cookie";
import { doSignOutClear } from "./helpers";
import {
  getUserPreferences,
  setUserId,
} from "../../redux/slices/userPreferencesSlice";
import { User } from "../../assets/interfaces/interfaces";

export function findData(user) {
  let displayName = "";
  let phoneNumber = "";
  if (user.providerData.length) {
    user.providerData.forEach((item) => {
      if (item.displayName) {
        displayName = item.displayName;
      }
      if (item.phoneNumber) {
        phoneNumber = item.phoneNumber;
      }
    });
  }
  return { displayName, phoneNumber };
}

const AuthListener = () => {
  const [source, setSource] = useState(""); // State to store the extracted parameters
  const [cookies] = useCookies([ssoAtStr, sapStr]);
  const authLoading = useSelector(
    (state: { auth: { loading: boolean } }) => state.auth.loading,
  );
  const signOutLoading = useSelector(
    (state: { auth }) => state.auth.signOutLoading,
  );
  const tokenStatus = useSelector((state: { token }) => state.token.status);
  const dispatch = useDispatch<AppDispatch>(); // Use the typed dispatch
  const firestore = getFirestore();
  const auth = getAuth();
  const user = useSelector((state: { auth }) => state.auth.currentUser);
  const user_id = user?.userId || "";
  const params = new URLSearchParams(window.location.search);
  const cje = params?.get("cje") || "";
  useEffect(() => {
    if (
      !cookies[sapStr]
      && !!auth?.currentUser
      && tokenStatus === "succeeded"
    ) {
      doSignOutClear(dispatch);
    }
  }, [auth?.currentUser, cookies]);

  useEffect(() => {
    const paramsSource = extractParams(window.location.search);
    setSource(paramsSource);
  }, []);

  useEffect(() => {
    // if cookie present and no user id present and auth is NOT loading and sign out isnt loading
    if (!!cookies[sapStr] && !user_id && !authLoading && !signOutLoading) {
      // then log in because the cookie is present
      axios
        .get(`${process.env.REACT_APP_SPL_URL}/user/auth-token`, {
          withCredentials: true,
        })
        .then((res) => {
          const { data } = res;
          const token = data?.token;
          signInWithCustomToken(auth, token)
            .then(() => {
              // TA SSO
            })
            .catch((err) => {
              console.log(err, "TA SSO failed");
            });
        });
    }
  }, [cookies, authLoading, user_id, signOutLoading]);

  useEffect(() => {
    dispatch(setAuthLoading(true)); // Start loading
    const auth = getAuth();
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        // Persistence is set to Local
        // Now, when you sign in the user, the session will persist indefinitely
      })
      .catch((error) => {
        // Handle errors
        console.error("Error setting session persistence: ", error);
      });

    const unsubscribe = onAuthStateChanged(
      auth,
      async (
        user: FUser & Partial<User> & { signInAttempts?: number | string },
      ) => {
        if (user) {
          let phone = user?.phoneNumber || "";
          let name = user?.displayName || "";

          // Get user token all the time. If it has not expired will always be the same until the opossite.
          getIdToken(user, true).then((token) => {
            // Lets get the query params and see if it coming from the app
            const redirect
              = window.location.search.split("redirect_uri=")[1] ?? "/";
            const isMobileApp = urlIsAppScheme(redirect);

            // If it comes from the app, forget about this.
            // App session page will take care of the logged in user and authentication.
            if (isMobileApp) {
              dispatch(setAuthLoading(false)); // Stop loading
              return;
            }

            dispatch(
              fetchToken({
                token,
              }),
            ).then(() => {
              dispatch(fetchValidateUser());
              dispatch(fetchUser({ source, cje }));
              dispatch(fetchActivePlan());
              // dispatch to fetch user preferences and set userId
              dispatch(setUserId(user.uid));
              dispatch(getUserPreferences({ userId: user.uid }));
              dispatch(setAuthLoading(false)); // Stop loading after user is checked
            });
          });

          // Update user data
          const { phoneNumber, displayName } = findData(user);
          if (displayName?.length > name?.length) {
            name = displayName;
          }
          if (phone?.length > phoneNumber?.length) {
            phone = phoneNumber;
          }
          dispatch(
            setUser({
              accessToken: user.accessToken,
              userId: user.uid,
              display_name: name,
              email: user.email,
              photoURL: user.photoURL || user.photoURL,
              phoneNumber: phone,
              country: user.country,
              state: user.state,
              name: name,
              ip: user.ip,
              sign_in_attempts: user.signInAttempts,
              feature_flags: user.feature_flags,
            }),
          );
        }
        else {
          dispatch(clearUser());
          dispatch(setAuthLoading(false));
        }
      },
    );
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [dispatch, firestore, source]);

  return null;
};

export default AuthListener;
