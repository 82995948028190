import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { useState } from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import CircularProgress from "@mui/material/CircularProgress";

const toolTypesMessages = {
  anything_related_to_TRADEALGO_company: {
    loading: "Retrieving data from TradeAlgo...",
    finished: "Retrieved data from TradeAlgo.",
  },
  format_option_ticker: {
    loading: "Formatting options tickers...",
    finished: "Formatted options tickers.",
  },
  get_asset_price_info: {
    loading: "Fetching current asset price information...",
    finished: "Fetched current asset price information.",
  },
  get_company_earnings_report: {
    loading: "Retrieving company earnings reports information...",
    finished: "Retrieved company earnings reports information.",
  },
  get_company_info: {
    loading: "Retrieving detailed company information...",
    finished: "Retrieved detailed company information.",
  },
  get_economic_event_and_data: {
    loading: "Retrieving economic and market events and data...",
    finished: "Retrieved economic and market events and data.",
  },
  get_news: {
    loading: "Fetching the latest news...",
    finished: "Fetched the latest news.",
  },
  get_option: {
    loading: "Analyzing options contracts and strategies...",
    finished: "Analyzed options contracts and strategies.",
  },
  get_ownership_insiders_info: {
    loading: "Searching for insider ownership information...",
    finished: "Finished searching for insider ownership information.",
  },
  get_recommendation_analysis_and_sentiment: {
    loading: "Getting analysis and sentiment data...",
    finished: "Got analysis and sentiment data.",
  },
  get_relevant_stock: {
    loading: "Fetching relevant stocks according to the criteria...",
    finished: "Fetched relevant stocks according to the criteria.",
  },
  get_stock_financial_statement_by_lastK: {
    loading: "Retrieving the last K financial statements for the stock...",
    finished: "Retrieved the last K financial statements for the stock.",
  },
  get_stock_financial_statement_by_range: {
    loading: "Fetching financial statements within year range...",
    finished: "Fetched financial statements within year range.",
  },
  get_stock_fundamental_ratios: {
    loading: "Retrieving fundamental ratios for the stock...",
    finished: "Retrieved fundamental ratios for the stock.",
  },
  get_top_unusual_darkflow: {
    loading: "Examining tickers with unusual dark pool activity...",
    finished: "Examined tickers with unusual dark pool activity.",
  },
  google_search: {
    loading: "Performing a search on the internet...",
    finished: "Performed a search on the internet.",
  },
  no_description: {
    loading: "Consolidating and analyzing information...",
    finished: "Consolidated and analyzed information.",
  },
  plot_chart: {
    loading: "Plotting relevant charts...",
    finished: "Finished plotting relevant charts.",
  },
  query_SEC: {
    loading: "Retrieving SEC filing information...",
    finished: "Retrieved SEC filing information.",
  },
  stock_screener: {
    loading: "Screening stocks according to requested criteria...",
    finished: "Screened stocks according to requested criteria.",
  },
};

const ToolLoader = ({ loadersArray, messageIsLoading }: { loadersArray: [string, boolean][]; messageIsLoading: boolean }) => {
  const [expanded, setExpanded] = useState<boolean>(() => {
    return messageIsLoading || false;
  });

  if (!loadersArray.length) {
    return null;
  }

  return (
    <div className="tool-loader my-4">
      <Accordion expanded={expanded} onChange={() => setExpanded(prevState => !prevState)} className="bg-transparent shadow-none">
        <AccordionSummary>
          {
            messageIsLoading
              ? (
                  <div className="flex items-center -ml-2">
                    <div className="flex items-center justify-center w-8 shrink-0 leading-0">
                      <CircularProgress disableShrink color="inherit" className="text-tgpt-secondary-main" size={16}></CircularProgress>
                    </div>
                    <Typography variant="body2/regular-400" className="dark:text-white text-mui-black-87">Working on it</Typography>
                    <div className={`ml-3 dark:text-white transition-transform ${expanded ? "rotate-180" : ""}`}>
                      <ExpandMoreRoundedIcon />
                    </div>
                  </div>
                )
              : (
                  <div className="flex items-center -ml-2">
                    <div className="mx-1.5 text-xl leading-0">
                      <CheckCircleRoundedIcon fontSize="inherit" className="text-mui-success/main" />
                    </div>
                    <Typography variant="body2/regular-400" className="dark:text-white text-mui-black-87">Completed</Typography>
                    <div className={`ml-3 dark:text-white transition-transform ${expanded ? "rotate-180" : ""}`}>
                      <ExpandMoreRoundedIcon />
                    </div>
                  </div>
                )
          }
        </AccordionSummary>
        <AccordionDetails className={`flex flex-col gap-2 transition mt-1.5 ${expanded ? "opacity-100" : "opacity-0"}`}>
          {loadersArray.map((loader, index) => {
            const [key, isFinished] = loader;
            return !isFinished
              ? (
                  <div key={index} className="flex items-center">
                    <div className="flex items-center justify-center w-8 shrink-0 leading-0">
                      <CircularProgress disableShrink color="inherit" className="text-tgpt-secondary-main animation-fast" size={16}></CircularProgress>
                    </div>
                    <Typography variant="body2/regular-400" className="dark:text-white text-mui-black-87">{toolTypesMessages[key].loading}</Typography>
                  </div>
                )
              : (
                  <div key={index} className="flex items-center">
                    <div className="mx-1.5 text-xl leading-0">
                      <CheckCircleRoundedIcon fontSize="inherit" className="text-mui-success/main" />
                    </div>
                    <Typography variant="body2/regular-400" className="dark:text-mui-white-70 text-mui-black-60">{toolTypesMessages[key].finished}</Typography>
                  </div>
                );
          })}

        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ToolLoader;
