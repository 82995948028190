import { GeneralModalProps, Point } from "../../assets/interfaces/interfaces";
import { Modal, IconButton, Button, Divider } from "@mui/material";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import useEnhancedNavigate from "../Session/helpers";
import { BlueCheckIcon } from "./icons/GPTIcons/BlueCheckIcon";
import { CrossIcon } from "./icons/GPTIcons/CrossIcon";

const PlanModal: React.FC<GeneralModalProps> = ({ open, handleClose }) => {
  const navigate = useEnhancedNavigate();

  const points: Point[] = [
    { id: 1, text: "Charts" },
    { id: 2, text: "Stock Screener on 10,000 Stocks" },
    { id: 3, text: "Top Gainers and Losers" },
    { id: 4, text: "Company Financials Data" },
    { id: 5, text: "Options Data" },
    { id: 6, text: "Macroeconomic US Stocks Data" },
    { id: 7, text: "Analyst Estimates" },
    { id: 8, text: "Stock Prices (OHLC)" },
    { id: 9, text: "Super Investor Holdings" },
    { id: 10, text: "Insider Trading" },
    { id: 11, text: "Earnings Calendar" },
  ];
  const allPlans = useSelector((state: { plans }) => state.plans.plans);
  const plan = useSelector((state: { plan }) => state.plan.plan);
  const term
    = useSelector(
      (state: { plan }) =>
        state?.plan?.plan?.subscription?.billing_period || "month",
    ) + "ly";
  const plans = useMemo(() => {
    const freePlan = [
      {
        id: "",
        planName: "Free",
        price: "$0",
        billingCycle: "Forever",
        prompts: 15,
        buttonText: "Manage My Subscription",
        buttonType: "primary",
        numPrompts: 15,
      },
    ];
    const currPlans = allPlans[term];
    if (currPlans?.length) {
      const paid = currPlans.map(p => ({
        planName: p.metadata.label,
        price: `$${Number(p.price / 100)?.toLocaleString() || ""}`,
        billingCycle: "Billed monthly, ex. tax",
        prompts:
          p.metadata.prompts === 999999 ? "Unlimited" : p.metadata.prompts,
        buttonText: `Upgrade to ${p.metadata.label}`,
        buttonType: "primary",
        id: p.id,
        numPrompts: p.metadata.prompts,
      }));
      const all = freePlan.concat(paid);
      return all
        .sort((a, b) => a.numPrompts - b.numPrompts)
        .map((item) => {
          const currentPlan = plan.subscription_type === item.planName;
          const buttonText = currentPlan
            ? "Manage my Subscription"
            : `Upgrade to ${item.planName}`;
          return {
            ...item,
            currentPlan,
            buttonText,
          };
        });
    }
    return [];
  }, [allPlans, plan]);

  const currentPlan = plans.find(a => a.currentPlan);

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="shadow w-modal-mobile sm:w-modal-md rounded-lg p-6 bg-white text-mui-black-87 dark:bg-background/paper-elevation-16 dark:text-white">
          <div className="flex flex-col gap-y-6 relative">
            <IconButton
              size="small"
              onClick={handleClose}
              className="cross-icon"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <CrossIcon className="fill-current" />
            </IconButton>
            <section className="flex flex-col gap-y-4 items-start">
              <h6>Your Current Plan</h6>
              <Divider sx={{ width: "100%" }} />
              <div>
                <p className="font-bold body1">{currentPlan?.planName || ""}</p>
                <p className="body2">
                  USD
                  {" "}
                  {currentPlan?.price || ""}
                  /
                  {term?.replace("ly", "")}
                </p>
                <p className="font-bold body2">
                  {currentPlan?.prompts === 999
                    ? "Unlimited"
                    : currentPlan?.prompts || ""}
                  {" "}
                  prompts per day
                </p>
              </div>

              <Button
                onClick={() => navigate("/plans")}
                className="button-primary"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                {currentPlan?.planName !== "Premium"
                  ? "Upgrade Now"
                  : "Manage My Plan"}
              </Button>
            </section>

            <section className="flex flex-col gap-y-3 w-[279px]">
              <div className="body1">All plans include:</div>
              <div className="flex flex-col">
                {points.map((point: Point) => (
                  <div key={point.id} className="flex flex-row py-2">
                    <BlueCheckIcon />
                    <p className="text-sm ps-4">{point.text}</p>
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PlanModal;
