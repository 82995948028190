// src/redux/slices/recommendedPromptsSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import mockRecommendedPrompts from "../../assets/JSON/recommendedPrompts.json";
import {
  RecommendedPrompts,
  RecommendedPromptsState,
} from "../../assets/interfaces/interfaces";

const initialState: RecommendedPromptsState = {
  prompts: {
    stocks: {
      quick_trades: [],
      top_picks: [],
    },
    options: {
      hot_trades: [],
      market_movers: [],
    },
  },
  promptSelected: null,
  status: "idle",
  error: null,
};

// Define the async thunk
export const fetchRecommendedPrompts = createAsyncThunk(
  "recommendedPrompts/fetchPrompts",
  async (arg, { rejectWithValue }) => {
    try {
      return mockRecommendedPrompts;
    }
    catch {
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

const recommendedPromptsSlice = createSlice({
  name: "recommendedPrompts",
  initialState,
  reducers: {
    setPromptSelected: (state, action) => {
      if (action.type === "recommendedPrompts/setPromptSelected") {
        return { ...state, promptSelected: action.payload };
      }
    },
    clearPromptSelected: (state) => {
      return { ...state, promptSelected: null };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecommendedPrompts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchRecommendedPrompts.fulfilled,
        (state, action: PayloadAction<RecommendedPrompts>) => {
          state.status = "succeeded";
          state.prompts = action.payload;
        },
      )
      .addCase(fetchRecommendedPrompts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch prompts";
      });
  },
});

export const { setPromptSelected, clearPromptSelected } = recommendedPromptsSlice.actions;
export default recommendedPromptsSlice.reducer;
