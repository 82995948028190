import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers/rootReducer"; // This should combine all your slices
import rootSaga from "./sagas/rootSaga"; // This should combine all your sagas
import { connectWebSocket } from "./slices/websocketSlice";
import { fetchActivePlan } from "./slices/activePlanSlice";
import { useDispatch } from "react-redux";
const sagaMiddleware = createSagaMiddleware();
const customMiddleware = store => (next: (x) => void) => (action: { type: string }) => {
  if (action.type === "activePlan/triggerFetch") {
    store.dispatch(fetchActivePlan());
  }
  else {
    next(action);
  }
};
const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(sagaMiddleware, customMiddleware),
});
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

sagaMiddleware.run(rootSaga);
store.dispatch(connectWebSocket());

export default store;
