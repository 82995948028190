import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { ActivePlanState } from "../../assets/interfaces/interfaces";
import { doSignOutClear } from "../../components/auth/helpers";

const initialState: ActivePlanState = {
  plan: {
    promptsLeft: 0,
    subscription_type: "Free",
    subscription: {},
  }, // Initialized as an empty array
  status: "idle",
  error: null,
};
// Define the async thunk
export const fetchActivePlan = createAsyncThunk(
  "plan/fetchActivePlan",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SPL_URL}/subscriptions/active`,
        { withCredentials: true },
      );
      return data;
    }
    catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response.status === 403) {
          // Lets log the user out if token is expired
          doSignOutClear(dispatch);
          return;
        }
        if (error.response) {
          return rejectWithValue(error.response.data);
        }
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

// Create the slice
export const activePlanSlice = createSlice({
  name: "active/plan",
  initialState,
  reducers: {
    // standard reducers here if needed
    updatePromptsLeft: (state, action: PayloadAction<number>) => {
      state.plan.promptsLeft = action.payload;
    },
    resetPlanStatus: (state) => {
      state.status = "idle";
      state.plan = initialState.plan; // Reset to initial plan state
      state.error = null; // Clear any errors
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActivePlan.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchActivePlan.fulfilled,
        (state, action: PayloadAction<ActivePlanState["plan"]>) => {
          state.status = "succeeded";
          state.plan = action.payload;
        },
      )
      .addCase(fetchActivePlan.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch active plan";
      });
  },
});

export const { updatePromptsLeft, resetPlanStatus } = activePlanSlice.actions;

// Export the reducer from the slice
export default activePlanSlice.reducer;
