import { resetPlanStatus } from "../../redux/slices/activePlanSlice";
import { resetState } from "../../redux/slices/deleteUserSlice";
import {
  clearFinchatData,
  finchatMessageCancelAll,
} from "../../redux/slices/finchatSlice";
import { clearMessages } from "../../redux/slices/pastChatsSlice";
import { resetValidUserState } from "../../redux/slices/validateUserSlice";
import { clearUser, requestSignOut } from "./authActions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { AnyAction } from "redux-saga";
import { rmAllCookiesAndStorage } from "../../redux/sagas/authSaga";
import { urlIsAppScheme } from "../Session/helpers";

export const doSignOutClear = (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  dispatch(clearFinchatData());
  dispatch(clearMessages());
  rmAllCookiesAndStorage();
  dispatch(resetState());
  dispatch(resetValidUserState());
  dispatch(requestSignOut());
  dispatch(clearUser());
  dispatch(resetPlanStatus());
  // Lets force to cancel a message if there is any loading
  dispatch(finchatMessageCancelAll());
  // Lets get to the sign in flow if its not mobile
  const redirect = window.location.search.split("redirect_uri=")[1] ?? "/";
  const isMobileApp = urlIsAppScheme(redirect);
  if (isMobileApp) {
    return;
  }
  window.location.href = "/";
};
