import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Typography, Divider, SxProps, Theme } from "@mui/material";
import DefaultSignIn from "./DefaultSignIn";
import SignUpPage from "./SignUpPage";
import { SignInModalProps } from "../../assets/interfaces/interfaces";
import { OauthButtons } from "../SocialBar";

const style: SxProps<Theme> = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  alignItems: "center",
};

const SignInModal: React.FC<SignInModalProps> = ({ flow, setFlow, setOpen, open }) => {
  const [email, setEmail] = useState<string>("");
  const handleClose = () => {
    setOpen(false);
    setFlow(0);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {flow === 0 && (
            <div className="w-[550px] h-[534px] relative bg-white rounded-[10px]">
              {/* Welcome back text */}
              <div className="flex flex-col justify-start items-center pt-8">
                <span className="text-[#1C1C1E] text-center custom-font text-[28px] font-bold leading-[30px]">
                  Welcome Back
                </span>
              </div>
              <form
                onSubmit={() => setFlow(1)}
                className="py-10 left-[128px] top-[70px] absolute flex flex-col justify-start items-center gap-6"
              >
                {/* Input field and primary action button */}
                <TextField
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  label="Email"
                  variant="outlined"
                  className="w-[295px] mb-4"
                />
                <Button
                  type="submit"
                  onClick={() => setFlow(1)}
                  variant="contained"
                  className="w-[295px] h-[50px] text-white font-inter text-[16px] font-medium leading-[30px] tracking-[-0.32px] !normal-case !bg-[#1C1C1E]"
                >
                  Continue
                </Button>

                {/* Sign-up link */}
                <div className="flex flex-col justify-start items-center gap-4">
                  <Typography
                    variant="body2"
                    className="text-center text-zinc-900 text-sm"
                  >
                    Don’t have an account?
                    {" "}
                    <span
                      onClick={() => setFlow(2)}
                      className="cursor-pointer text-blue-600"
                    >
                      Sign up here
                    </span>
                  </Typography>
                </div>

                {/* Divider with "OR" text */}
                <div className="w-[295px] flex justify-start items-center">
                  <Divider className="flex-grow" />
                  <div className="px-2">
                    <Typography
                      variant="caption"
                      className="text-zinc-900 text-xs"
                    >
                      OR
                    </Typography>
                  </div>
                  <Divider className="flex-grow" />
                </div>

                {/* Social media sign-in buttons */}
                <OauthButtons handleClose={handleClose} />
              </form>
            </div>
          )}
          {flow === 1 && (
            <DefaultSignIn
              setOpen={setOpen}
              setFlow={setFlow}
              emailValue={email}
            />
          )}

          {flow === 2 && (
            <SignUpPage handleClose={handleClose} setFlow={setFlow} />
          )}
        </Box>
      </Modal>
    </div>
  );
};
export default SignInModal;
