export const IconChevronRightLightThin = ({
  className,
}: {
  className: string;
}) => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.68306 14.8169C7.43898 14.5729 7.43898 14.1771 7.68306 13.9331L12.2411 9.375L7.68306 4.81694C7.43898 4.57287 7.43898 4.17714 7.68306 3.93306C7.92713 3.68898 8.32286 3.68898 8.56694 3.93306L13.5669 8.93306C13.811 9.17714 13.811 9.57286 13.5669 9.81694L8.56694 14.8169C8.32286 15.061 7.92714 15.061 7.68306 14.8169Z"
        fill="#1C1C1E"
      />
    </svg>
  );
};
