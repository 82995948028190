import faqData from "./data";
import FaqAccordion from "./FaqAccordion";
import React, { useState } from "react";
import TradeLogo from "../../assets/images/finchatGPT/taLogo.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TradeLogoD from "../../assets/images/finchatGPT/TradeLogo-D.png";
import SearchIcon from "../../assets/images/finchatGPT/search-icon.png";
import { Link } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

function Faq() {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string | null,
  ) => {
    setSearchTerm(newValue ?? "");
  };

  // Define container padding and max-width for all items
  const mainContainerStyle = "max-w-4xl mx-auto px-6";

  return (
    <div className="flex flex-col w-full">
      <div className="bg-black">
        <div className={`${mainContainerStyle} py-6 flex justify-between items-center`}>
          <div className="flex items-center gap-2">
            <img src={TradeLogo} alt="Logo" className="h-8 w-auto" />
            <h2 className="text-white text-xl font-semibold">TradeGPT</h2>
          </div>
          <Link to="/" className="text-white">
            <div className="flex items-center gap-2 cursor-pointer">
              <h3>Go To TradeGPT</h3>
              <ChevronRightIcon />
            </div>
          </Link>
        </div>
      </div>
      <div className="bg-black">
        <div className={`${mainContainerStyle} py-6`}>
          <h1 className="text-white text-3xl font-bold text-left">Advice and answers from the TradeGPT AI Team</h1>
        </div>
      </div>
      <div className="bg-black">
        <div className={`${mainContainerStyle} py-6`}>
          <div className="flex items-center bg-gray-800 rounded-lg">
            <img src={SearchIcon} alt="Search" className="ml-3 h-6 w-6" />
            <Autocomplete
              freeSolo
              value={searchTerm}
              onChange={handleSearchChange}
              options={[]} // Since you don't need functionality, options can be empty
              style={{ width: "100%" }}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Search for articles..."
                  variant="outlined"
                  InputProps={{ ...params.InputProps }}
                  sx={{
                    "input": {
                      "color": "white",
                      "&::placeholder": {
                        color: "white",
                        opacity: 1,
                      },
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                    "& .MuiAutocomplete-clearIndicator": {
                      color: "white",
                    },
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className={`${mainContainerStyle} py-10`}>
          <h1 className="text-4xl font-bold text-left text-black">TradeGPT - FAQ (Frequently Asked Questions)</h1>
          <h2 className="font-normal text-gray-500">Answers to questions frequently asked by users</h2>
        </div>
      </div>
      <div className="bg-white pb-6">
        <div className={`${mainContainerStyle}`}>
          {faqData.map((faqSection, sectionIndex) => {
            return (
              <div key={faqSection.mainTitle} className="mb-4">
                <h3 className="text-lg font-bold">{faqSection.mainTitle}</h3>
                {faqSection.items.map((item, itemIndex) => {
                  // unique key so it doesnt trigger an error
                  const key = `${sectionIndex}-${itemIndex}-${item.title}`;
                  return (
                    <FaqAccordion key={key} title={item.title} description={item.description} />
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <footer className="bg-white mt-auto">
        <div className={`${mainContainerStyle} py-4 flex items-center justify-center`}>
          <img src={TradeLogoD} alt="Logo-D" className="faq-footer-logo" />
          <h2 className="text-black text-xl font-semibold ml-3">TradeGPT</h2>
        </div>
      </footer>
    </div>
  );
}

export default Faq;
