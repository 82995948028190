import * as React from "react";

const AIScannerIcon = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_14614_4657)">
      <path d="M5.67101 4.25701L13.414 12L12 13.414L8.55401 9.96801C8.11134 10.7185 7.92586 11.5931 8.02577 12.4587C8.12567 13.3243 8.50549 14.1336 9.10749 14.7636C9.70948 15.3936 10.5007 15.8097 11.3609 15.9488C12.2211 16.0879 13.1031 15.9423 13.873 15.5341C14.6429 15.126 15.2584 14.4776 15.626 13.6876C15.9936 12.8976 16.0932 12.0092 15.9096 11.1574C15.726 10.3056 15.2693 9.53703 14.609 8.96856C13.9486 8.4001 13.1206 8.06282 12.251 8.00801L10.446 6.20301C11.8102 5.83717 13.2598 5.96434 14.5395 6.56214C15.8192 7.15995 16.847 8.19005 17.4419 9.47109C18.0368 10.7521 18.1607 12.202 17.7918 13.5654C17.4229 14.9288 16.5848 16.1184 15.4251 16.9246C14.2654 17.7309 12.8584 18.1022 11.4519 17.9732C10.0453 17.8441 8.72942 17.223 7.73579 16.2191C6.74217 15.2153 6.13454 13.8931 6.01989 12.4853C5.90524 11.0775 6.29091 9.67441 7.10901 8.52301L5.68001 7.09401C4.48749 8.63136 3.8961 10.5506 4.01654 12.4925C4.13697 14.4344 4.96098 16.2659 6.33431 17.6441C7.70765 19.0224 9.53618 19.8528 11.4777 19.9802C13.4191 20.1075 15.3405 19.5229 16.882 18.3359C18.4236 17.1488 19.4798 15.4407 19.8528 13.5311C20.2258 11.6216 19.8902 9.64152 18.9086 7.96159C17.9271 6.28165 16.367 5.017 14.5204 4.40432C12.6737 3.79164 10.6671 3.87295 8.87601 4.63301L7.37401 3.13201C8.80173 2.38603 10.3891 1.99759 12 2.00001C17.523 2.00001 22 6.47701 22 12C22 17.523 17.523 22 12 22C6.47701 22 2.00001 17.523 2.00001 12C1.9985 10.5178 2.32718 9.0538 2.96217 7.71446C3.59716 6.37511 4.52251 5.19403 5.67101 4.25701Z" fill={fill} />
    </g>
    <defs>
      <clipPath id="clip0_14614_4657">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AIScannerIcon;
