// AppWrapper.js
import React, { ReactNode } from "react";
import { useSelector, useDispatch } from "react-redux";
import NotificationSnackbar from "./Snackbar";
import { hideNotification } from "../redux/slices/notificationSlice";

interface AppWrapperProps {
  children: ReactNode; // This type allows for any renderable React content
}

const AppWrapper: React.FC<AppWrapperProps> = ({ children }) => {
  const dispatch = useDispatch();
  const notification = useSelector((state: { notification }) => state.notification);
  const handleSnackbarClose = () => {
    dispatch(hideNotification());
  };
  return (
    <>
      {/* Global components like NotificationSnackbar */}
      <NotificationSnackbar
        open={notification.open}
        onClose={handleSnackbarClose}
        message={notification.message}
        severity={notification.severity}
        horizontal={notification.horizontal}
      />
      {/* Main application content */}
      {children}
    </>
  );
};

export default AppWrapper;
