import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { fetchAllPlan } from "../../redux/slices/allPlanSlice";
import Plans from "./Plans";
import SignInModal from "../auth/SignInModal";
import { CircularProgress } from "@mui/material";

const PlansWrapper = () => {
  // Current user
  const currentUser = useSelector((state: { auth }) => state.auth.currentUser);
  // Loading states
  const authLoading = useSelector((state: { auth }) => state.auth.loading);
  const currentPlanLoading
    = useSelector((state: { plan }) => state.plan.status) === "loading";
  const plansLoading
    = useSelector((state: { plans }) => state.plans.status) === "loading";
  // Plans data
  const { plans, status: allStatus } = useSelector((state: { plans }) => state.plans);
  const { plan, status } = useSelector((state: { plan }) => state.plan);
  const dispatch = useDispatch<AppDispatch>(); // Use the typed dispatch
  const [shouldRenderPlans, setShouldRenderPlans] = useState(false);
  const [flow, setFlow] = useState<number>(0);
  const [yearly, setYearly] = useState<boolean>(false);
  const isLoading = authLoading || plansLoading || currentPlanLoading;
  const term = useSelector((state: { plan }) => state?.plan?.plan?.subscription?.billing_period || "month") + "ly";
  useEffect(() => {
    if (term === "yearly") {
      setYearly(true);
    }
  }, [term]);
  const allPlans = useMemo(() => {
    const freePlan = [
      {
        id: "",
        planName: "Free",
        price: "$0",
        billingCycle: "Forever",
        prompts: 15,
        buttonText: "Manage My Subscription",
        buttonType: "primary",
        numPrompts: 15,
      },
    ];
    const term = yearly ? "yearly" : "monthly";
    const availPlans = plans[term];
    if (availPlans?.length) {
      const paid = plans[term].map(p => ({
        planName: p.metadata.label,
        price: `$${Number(p.price / 100).toLocaleString("en-US")}`,
        billingCycle: `Billed ${yearly ? "yearly" : "monthly"}`,
        prompts: p.metadata.prompts === 999 ? "Unlimited" : p.metadata.prompts,
        buttonText: `Upgrade to ${p.metadata.label}`,
        buttonType: "primary",
        id: p.id,
        extraText: yearly
          ? p.metadata.prompts === 999
            ? (
                <span>
                  {" "}
                  <span className="strikethrough save-text">$3588</span>
                  {" "}
                  <span className="save-text"> save $589</span>
                  {" "}
                </span>
              )
            : (
                <span>
                  {" "}
                  <span className="strikethrough save-text">$348</span>
                  {" "}
                  <span className="save-text"> save $53</span>
                  {" "}
                </span>
              )
          : "",
        taAccess: p.metadata.prompts === 999,
        numPrompts: p.metadata.prompts,
      }));

      const all = freePlan.concat(paid);
      return all
        .sort((a, b) => a.numPrompts - b.numPrompts)
        .map((item) => {
          const currentPlan = plan.subscription_type === item.planName;
          const buttonText = currentPlan
            ? "Manage my Subscription"
            : `Upgrade to ${item.planName}`;
          return {
            ...item,
            currentPlan,
            buttonText,
          };
        });
    }
    return [];
  }, [plans, plan, yearly]);

  useEffect(() => {
    dispatch(fetchAllPlan());
  }, []);

  useEffect(() => {
    // Check if all conditions are met to render the Plans component
    const shouldRender
      = currentUser
      && allPlans.length
      && status === "succeeded"
      && allStatus === "succeeded";

    setShouldRenderPlans(shouldRender); // Set flag to render Plans
  }, [allPlans, status, allStatus]);

  if (isLoading) {
    return (
      <div className="flex w-full h-screen items-center justify-center">
        <CircularProgress color="secondary" />
      </div>
    );
  }

  if (shouldRenderPlans) {
    return <Plans yearly={yearly} setYearly={setYearly} allPlans={allPlans} />;
  }

  return (
    <SignInModal
      flow={flow}
      open={true}
      setFlow={setFlow}
      setOpen={() => null}
    />
  );
};

export default PlansWrapper;
