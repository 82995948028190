import { getAuth, createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { useDispatch } from "react-redux";
import { setAuthLoading, setUser } from "./authActions";
import { UserAuthData } from "../../assets/interfaces/interfaces";
import { AppDispatch } from "../../redux/store";
import { v4 as uuidv4 } from "uuid";
import useEnhancedNavigate from "../Session/helpers";

export const useSignup = () => {
  const dispatch = useDispatch<AppDispatch>(); // Use the typed dispatch
  const auth = getAuth();
  const navigate = useEnhancedNavigate();

  const signup = async ({ email, password, displayName, phoneNumber, landing }: UserAuthData) => {
    try {
      dispatch(setAuthLoading(true)); // Start loading
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const firebaseUser: any = userCredential.user;
      await updateProfile(firebaseUser, { displayName });
      const updatedUser = getAuth().currentUser;
      dispatch(setAuthLoading(false)); // Stop loading
      if (updatedUser) {
        dispatch(setUser({ ...firebaseUser, updatedUser, userId: firebaseUser.uid }));
      }
      localStorage.setItem("phoneNumber", phoneNumber);
      if (landing) {
        const newSessionId = uuidv4();
        navigate(`/chat/${newSessionId}`);
      }
      return updatedUser;
    }
    catch (error) {
      console.error(error);
      dispatch(setAuthLoading(false)); // Stop loading
      throw error;
    }
  };

  return signup;
};
