export const BlueCheckIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_18029_8835)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM15.0333 7.10041C15.2729 6.85189 15.2656 6.45623 15.0171 6.21668C14.7686 5.97713 14.3729 5.9844 14.1333 6.23292L8.15615 12.4339L5.86556 10.0696C5.62538 9.82166 5.2297 9.81539 4.98178 10.0556C4.73387 10.2958 4.7276 10.6914 4.96778 10.9393L7.7084 13.7682C7.82632 13.8899 7.98861 13.9585 8.15808 13.9583C8.32755 13.9581 8.48966 13.8891 8.60727 13.7671L15.0333 7.10041Z"
          fill="#009EFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_18029_8835">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
