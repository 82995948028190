import { useEffect, useRef, useState } from "react";
import { PreferencesModalInterface } from "../../assets/interfaces/interfaces";
import {
  Modal,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  CircularProgress,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { updateUserPreference } from "../../redux/slices/userPreferencesSlice";
import { useDebounce } from "../../helpers";
import { CrossIcon } from "../Plans/icons/GPTIcons/CrossIcon";

type TradingFocusOptions = "equity" | "options" | "any";
type MarketCapOptions = "large" | "medium" | "small" | "any";
type RiskAppetiteOptions = "high" | "medium" | "small" | "any";

const InfoToolTip = styled(({ className, ...props }: TooltipProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Determine placement based on screen size
  const placement = isSmallScreen ? "top" : "right";

  return (
    <Tooltip {...props} classes={{ popper: className }} placement={placement} />
  );
})(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "4px 8px 4px 8px",
    textAlign: "center",
    width: "60%",
  },
}));

export default function PreferencesModal({
  open,
  setOpen,
}: PreferencesModalInterface) {
  const dispatch = useDispatch<AppDispatch>();
  const userPreferences = useSelector(
    (state: { userPreferences }) => state.userPreferences,
  );
  const [tradingFocus, setTradingFocus] = useState<TradingFocusOptions>("any");
  const [marketCap, setMarketCap] = useState<MarketCapOptions>("any");
  const [riskAppetite, setRiskAppetite] = useState<RiskAppetiteOptions>("any");
  const [isPreferencesSubmitted, setIsPreferencesSubmitted]
    = useState<boolean>(false);
  const [showWarning, setShowWarning] = useState<boolean>(false);

  const initialLoadRef = useRef(true); // tracking to make sure that initial prefs is only sent once
  const updatePreferences = async () => {
    const updatedPreferences = {
      tradingFocus,
      marketCap,
      riskAppetite,
    };
    await dispatch(
      updateUserPreference({
        userId: userPreferences.userId,
        preferences: updatedPreferences,
      }),
    );
  };

  // if component is opened for the first time (userPreferences.hasOpenedModal is false), send an initial POST req w default values
  useEffect(() => {
    setIsPreferencesSubmitted(false);
    setShowWarning(false);

    if (
      initialLoadRef.current
      && open
      && userPreferences.hasOpenedModal === false
    ) {
      initialLoadRef.current = false;
      updatePreferences();
    }

    if (
      userPreferences
      && userPreferences.status !== "loading"
      && userPreferences.hasOpenedModal === true
    ) {
      setTradingFocus(userPreferences.preferences.tradingFocus);
      setMarketCap(userPreferences.preferences.marketCap);
      setRiskAppetite(userPreferences.preferences.riskAppetite);
    }
  }, [open, userPreferences]);

  const hasStateChanged
    = userPreferences.preferences
    && (userPreferences.preferences.tradingFocus !== tradingFocus
    || userPreferences.preferences.marketCap !== marketCap
    || userPreferences.preferences.riskAppetite !== riskAppetite);

  const handleClose = () => {
    if (showWarning) {
      // warning already shown, so can close modal without saving
      setOpen(false);
      return;
    }
    if (hasStateChanged) {
      setShowWarning(true);
    }
    else {
      setOpen(false);
    }
  };

  const handleSubmit = async () => {
    if (!hasStateChanged) {
      setIsPreferencesSubmitted(true);
    }
    else {
      await updatePreferences();
      if (userPreferences.status === "succeeded") {
        setIsPreferencesSubmitted(true);
      }
    }
  };

  const PreferenceItem = ({
    stateVar,
    prefType,
    setter,
    options,
  }: {
    stateVar?: string;
    prefType: string;
    setter?: (string) => void;
    options?: string[];
  }) => {
    const [selectedOption, setSelectedOption] = useState(stateVar);
    useDebounce(selectedOption, setter, 200);

    const handleAlignment = (newAlignment) => {
      if (newAlignment !== null) {
        setSelectedOption(newAlignment);
      }
    };

    return (
      <div className="flex flex-col gap-y-4 sm:flex-row justify-between">
        <div className="flex gap-x-2 tracking-0.15px text-base text-mui-black-60 dark:text-mui-white-70">
          {prefType}
          {prefType !== "Trading Focus" && (
            <InfoToolTip
              placement="top"
              title={
                prefType === "Market Cap"
                  ? "Your preference for company size, to gauge related risks"
                  : "Your comfort level with potential investment volatility"
              }
            >
              <HelpOutlineIcon sx={{ height: 20, width: 20, marginTop: 0.2 }} />
            </InfoToolTip>
          )}
        </div>
        <div className="sm:w-[316px]">
          <ToggleButtonGroup
            exclusive
            aria-label="text alignment"
            className="togglegroup-border"
            fullWidth
          >
            {options.map(option => (
              <ToggleButton
                key={option}
                value={option}
                size="medium"
                className="category-button no-border right-border"
                selected={selectedOption === option}
                onClick={() => {
                  handleAlignment(option);
                }}
                aria-label="left aligned"
              >
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow w-modal-mobile sm:w-modal-md md:w-modal-lg rounded-lg p-6 bg-white text-mui-black-87 dark:bg-background/paper-elevation-16 dark:text-white">
          {isPreferencesSubmitted && (
            <div className="flex text-sm items-center">
              <div className="flex-grow text-center">
                Preferences saved. TradeGPT will now provide advice based on
                your preferences.
              </div>
              <IconButton
                size="small"
                onClick={handleClose}
                className="cross-icon"
              >
                <CrossIcon className="fill-current" />
              </IconButton>
            </div>
          )}

          {!isPreferencesSubmitted && (
            <div className="flex flex-col gap-y-6">
              <div className="flex justify-between items-center">
                <h6 className="text-xl leading-[32px] tracking-0.15px font-medium">
                  Preferences
                </h6>
                <IconButton
                  size="small"
                  onClick={handleClose}
                  className="cross-icon"
                >
                  <CrossIcon className="fill-current" />
                </IconButton>
              </div>
              {showWarning && (
                <>
                  <div className="text-sm">
                    Your preferences have not been saved. Your changes will be
                    lost if you close this window now.
                  </div>
                  <div className="flex flex-col gap-y-4 md:flex-row gap-x-6 justify-center">
                    <Button
                      variant="contained"
                      color="error"
                      size="large"
                      fullWidth
                      className="button-danger"
                      onClick={handleClose}
                    >
                      Close & Discard Changes
                    </Button>
                    <Button
                      variant="outlined"
                      color="inherit"
                      size="large"
                      fullWidth
                      className="button-outlined"
                      onClick={() => setShowWarning(false)}
                    >
                      Return to Preferences
                    </Button>
                  </div>
                </>
              )}
              {!showWarning && (
                <>
                  <div className="text-sm border-b-[1px] dark:border-mui-white-56 border-mui-black-56 pb-6">
                    State your preference to help TradeGPT tailor to your
                    current needs.
                  </div>
                  <PreferenceItem
                    stateVar={tradingFocus}
                    setter={setTradingFocus}
                    prefType="Trading Focus"
                    options={["equity", "options", "any"]}
                  />
                  <PreferenceItem
                    stateVar={marketCap}
                    setter={setMarketCap}
                    prefType="Market Cap"
                    options={["large", "medium", "small", "any"]}
                  />
                  <PreferenceItem
                    stateVar={riskAppetite}
                    setter={setRiskAppetite}
                    prefType="Risk Appetite"
                    options={["high", "medium", "small", "any"]}
                  />
                  <div className="flex flex-col gap-y-4">
                    <Button
                      variant="contained"
                      size="large"
                      color="inherit"
                      className="button-submit button-loading"
                      fullWidth
                      onClick={handleSubmit}
                      disabled={
                        userPreferences.status === "loading"
                        && userPreferences.hasOpenedModal
                      }
                    >
                      {userPreferences.status === "loading"
                      && userPreferences.hasOpenedModal
                        ? (
                            <div className="flex items-center space-x-2">
                              <CircularProgress color="inherit" size={20} />
                              <span>Saving...</span>
                            </div>
                          )
                        : (
                            "Save"
                          )}
                    </Button>

                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      size="large"
                      color="inherit"
                      className="button-outlined"
                      disabled={
                        userPreferences.status === "loading"
                        && userPreferences.hasOpenedModal
                      }
                    >
                      Close & Discard Changes
                    </Button>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}
