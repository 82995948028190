export const IconChevronLeftLightThin = ({ className }: { className: string }) => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M14.7803 4.71967C15.0732 5.01256 15.0732 5.48744 14.7803 5.78033L9.31066 11.25L14.7803 16.7197C15.0732 17.0126 15.0732 17.4874 14.7803 17.7803C14.4874 18.0732 14.0126 18.0732 13.7197 17.7803L7.71967 11.7803C7.42678 11.4874 7.42678 11.0126 7.71967 10.7197L13.7197 4.71967C14.0126 4.42678 14.4874 4.42678 14.7803 4.71967Z"
        fillRule="evenodd"
      />
    </svg>
  );
};
