import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { ChatMessage, FetchSessionArg, PastChatState } from "../../assets/interfaces/interfaces";

const initialState: PastChatState = {
  pastMessages: [], // Initialized as an empty array
  status: "idle",
  error: null,
};

// Define the async thunk
export const fetchSharedSession = createAsyncThunk(
  "chat/fetchSharedSession",
  async (arg: FetchSessionArg, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_SPL_URL}/tradegpt-session/${arg.sessionId}`, { withCredentials: true });
      return data;
    }
    catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

// Create the slice
export const sharedSessionSlice = createSlice({
  name: "shared/session",
  initialState,
  reducers: {
    // standard reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSharedSession.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSharedSession.fulfilled, (state, action: PayloadAction<ChatMessage[]>) => {
        state.status = "succeeded";
        state.pastMessages = action.payload;
      })
      .addCase(fetchSharedSession.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch messages";
      });
  },
});

// Export the reducer from the slice
export default sharedSessionSlice.reducer;
