export const SendIcon = ({ color = "white", className = "" }: { color?: string; className?: string }) => {
  return (
    <svg
      className={`${className}`}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8164 0.138519C13.0221 -0.28348 14.192 0.852298 13.8059 2.06998L10.3728 12.8973C9.95828 14.2048 8.156 14.327 7.56882 13.0874L5.4096 8.529L5.45438 8.46631L5.38009 8.51937L1.14678 6.6379C-0.102926 6.08248 -0.0296037 4.28461 1.26122 3.83282L11.8164 0.138519ZM12.3306 1.55009C12.3278 1.55023 12.3218 1.55084 12.3119 1.55431L1.75674 5.24861C1.7543 5.24946 1.75241 5.25024 1.75098 5.25088C1.75054 5.25266 1.75016 5.25496 1.75005 5.25764C1.74994 5.26032 1.75013 5.26264 1.75042 5.26445C1.7518 5.26521 1.75362 5.26614 1.75598 5.26718L5.20587 6.80047L7.46315 5.18812C8.29954 4.5907 9.32999 5.62116 8.73257 6.45755L7.14258 8.68354L8.92443 12.4452C8.92554 12.4476 8.92651 12.4494 8.92731 12.4507C8.92913 12.451 8.93147 12.4511 8.93414 12.4509C8.93682 12.4507 8.93912 12.4503 8.9409 12.4498C8.9415 12.4484 8.94222 12.4464 8.943 12.444L12.3761 1.61661C12.3792 1.60661 12.3797 1.60056 12.3797 1.59777C12.3798 1.59481 12.3794 1.59234 12.3787 1.58981C12.3772 1.58419 12.3728 1.57491 12.3632 1.56562C12.3537 1.55634 12.3443 1.55221 12.3386 1.55084C12.3361 1.55022 12.3336 1.54995 12.3306 1.55009Z"
        fill={color}
      />
    </svg>
  );
};
