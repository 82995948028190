export const NewWindowIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7087 2.29242C12.7087 1.94725 12.9885 1.66742 13.3337 1.66742L17.4254 1.66742C17.4391 1.66692 17.4529 1.66685 17.4669 1.66725C17.4861 1.6678 17.505 1.66921 17.5238 1.67144C17.5338 1.67258 17.5438 1.67398 17.5538 1.67561C17.5827 1.68032 17.6108 1.687 17.6382 1.69552C17.7361 1.72595 17.8236 1.77976 17.8943 1.85042L17.8912 1.85028L17.9007 1.85999L17.9038 1.86013C17.9492 1.90755 17.9857 1.96037 18.0133 2.01647C18.0543 2.09969 18.0774 2.19337 18.0774 2.29242C18.0774 2.32046 18.0755 2.34806 18.072 2.37511L18.029 6.28591C18.0192 6.63095 17.7315 6.90269 17.3865 6.89286C17.0414 6.88304 16.7697 6.59536 16.7795 6.25033L16.7795 3.84908L10.2339 10.3946C9.98986 10.6387 9.59413 10.6387 9.35005 10.3946C9.10597 10.1505 9.10597 9.75482 9.35005 9.51074L15.9434 2.91742H13.3337C12.9885 2.91742 12.7087 2.6376 12.7087 2.29242ZM15.2087 15.0003C15.2087 15.1154 15.1154 15.2087 15.0003 15.2087L5.00032 15.2085C4.88526 15.2085 4.79199 15.1152 4.79199 15.0002V5.00034C4.79199 4.88528 4.88527 4.79201 5.00033 4.79201H11.2856C11.6308 4.79201 11.9106 4.51218 11.9106 4.16701C11.9106 3.82183 11.6308 3.54201 11.2856 3.54201H5.00033C4.19491 3.54201 3.54199 4.19492 3.54199 5.00034V15.0002C3.54199 15.8056 4.1949 16.4585 5.0003 16.4585L15.0003 16.4587C15.8057 16.4587 16.4587 15.8058 16.4587 15.0003V8.75046C16.4587 8.40529 16.1788 8.12546 15.8337 8.12546C15.4885 8.12546 15.2087 8.40529 15.2087 8.75046V15.0003Z"
        fill="currentColor"
        fillOpacity="0.87"
      />
    </svg>
  );
};
