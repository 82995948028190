import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ChangeTitleArg, NewTitleState } from "../../assets/interfaces/interfaces";
import { fetchPastChats } from "./pastChatsSlice";
import { fetchPastSession } from "./pastSessionSlice";
import { fetchSharedSession } from "./sharedSessionSlice";

const initialState: NewTitleState = {
  title: "",
  status: "idle",
  error: null,
};

// Define the async thunk
export const fetchChangeTitle = createAsyncThunk(
  "chat/fetchChangeTitle",
  async (arg: ChangeTitleArg, { dispatch, rejectWithValue }) => {
    try {
      const { title, session_id, userId } = arg;
      const { data } = await axios.put(`${process.env.REACT_APP_SPL_URL}/tradegpt-update-chat-title`, {
        session_title: title, session_id,
      }, { withCredentials: true });
      dispatch(fetchPastChats({ userId }));
      if (arg.fetchSession) {
        dispatch(fetchPastSession({ sessionId: session_id }));
        dispatch(fetchSharedSession({ sessionId: session_id }));
      }
      return data;
    }
    catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

// Create the slice
export const changeTitleSlice = createSlice({
  name: "chat-title",
  initialState,
  reducers: {
    // standard reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChangeTitle.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChangeTitle.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(fetchChangeTitle.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch messages";
      });
  },
});

// Export the reducer from the slice
export default changeTitleSlice.reducer;
