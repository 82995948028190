import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordianInterface } from "../../assets/interfaces/interfaces";

const FaqAccordion: React.FC<AccordianInterface> = ({ title, description }) => {
  return (
    <div>
      <Accordion elevation={0} className="border-b border-gray-300">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <span>{title}</span>
        </AccordionSummary>
        <AccordionDetails>
          <span>
            {description}
          </span>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FaqAccordion;
