import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { showNotification } from "../../redux/slices/notificationSlice";
import { OauthSignInFucntion } from "../../assets/interfaces/interfaces";
import { v4 as uuidv4 } from "uuid";

export const signInWithGoogle: OauthSignInFucntion = async (closeModal, dispatch, navigate) => {
  try {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential && credential.accessToken;
    const user = result.user;
    if (navigate) {
      const newSessionId = uuidv4();
      navigate(`/chat/${newSessionId}`);
    }
    dispatch(showNotification({ message: "Sign In Successful!", severity: "success", horizontal: "right" }));
    closeModal();
    return { token, user };
  }
  catch (error) {
    console.log({ error });
    dispatch(showNotification({ message: error.message, severity: "error", horizontal: "right" }));
    return { errorCode: error.code, errorMessage: error.message, email: error.email, credential: GoogleAuthProvider.credentialFromError(error) };
  }
};
