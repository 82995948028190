import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { DeleteChatState } from "../../assets/interfaces/interfaces";

const initialState: DeleteChatState = {
  status: "idle",
  error: null,
};

// Define the async thunk
export const fetchDeleteUser = createAsyncThunk(
  "chat/fetchDeleteUser",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`${process.env.REACT_APP_SPL_URL}/user/tradegpt`, {
        withCredentials: true,
      });
      // dispatch data if needed
      return data;
    }
    catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

// Create the slice
export const deleteUserSlice = createSlice({
  name: "delete-user",
  initialState,
  reducers: {
    resetState: () => initialState, // Resetting to initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeleteUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDeleteUser.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(fetchDeleteUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch messages";
      });
  },
});

export const { resetState } = deleteUserSlice.actions;
// Export the reducer from the slice
export default deleteUserSlice.reducer;
