import { useState } from "react";
import Button from "@mui/material/Button";
import {
  renderPhoneField,
  renderTextField,
  validateSignUpForm,
} from "../../auth/SignUpPage";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { setAuthLoading } from "../../auth/authActions";
import {
  createUserWithEmailAndPassword,
  getAuth,
  updateProfile,
} from "firebase/auth";
import { showNotification } from "../../../redux/slices/notificationSlice";

function SessionSignUpPage({
  setFlow,
  handleClose,
  onSuccess,
}: {
  setFlow;
  handleClose: () => void;
  onSuccess: () => void;
}) {
  const dispatch = useDispatch<AppDispatch>();
  const auth = getAuth();

  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [phoneNumberError, setPhoneNumberError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");

  const handleSignup = async (event) => {
    event.preventDefault();
    const { valid, errors } = validateSignUpForm({
      email,
      name,
      phoneNumber,
      password,
    });

    // Update error states
    setEmailError(errors.email);
    setNameError(errors.name);
    setPhoneNumberError(errors.phoneNumber);
    setPasswordError(errors.password);
    if (!valid) return;
    try {
      dispatch(setAuthLoading(true)); // Start loading
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
      const firebaseUser = userCredential.user;

      await updateProfile(firebaseUser, { displayName: name });
      dispatch(setAuthLoading(false)); // Stop loading

      localStorage.setItem("phoneNumber", phoneNumber);

      onSuccess();
      handleClose();
    }
    catch (error) {
      console.error(error);
      dispatch(setAuthLoading(false)); // Stop loading
      // show error
      dispatch(
        showNotification({
          message: error.code.replace("auth/", "").replaceAll("-", " "),
          severity: "error",
          horizontal: "right",
        }),
      );
      throw error;
    }
  };
  return (
    <form
      onSubmit={handleSignup}
      className="w-full h-full justify-center flex flex-col items-center gap-8"
    >
      <div className="justify-start items-start inline-flex">
        <div className="text-center text-zinc-900 text-[28px] font-bold font-['Inter'] leading-[30px]">
          Create Account
        </div>
      </div>
      {renderTextField({
        label: "Email",
        value: email,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          setEmail(e.target.value),
        error: emailError,
      })}
      {renderTextField({
        label: "Name",
        value: name,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          setName(e.target.value),
        error: nameError,
      })}
      {renderPhoneField({
        label: "Phone Number",
        value: phoneNumber,
        onChange: value => setPhoneNumber(value),
        error: phoneNumberError,
      })}
      {renderTextField({
        label: "Password",
        value: password,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          setPassword(e.target.value),
        error: passwordError,
        type: "password",
      })}
      <Button
        type="submit"
        onClick={handleSignup}
        variant="contained"
        className="w-[295px] h-[50px] text-white font-inter text-[16px] font-medium leading-[30px] tracking-[-0.32px] !normal-case !bg-[#1C1C1E]"
      >
        Continue
      </Button>
      <div className="flex-col justify-start items-center gap-4 flex">
        <div className="justify-start items-start gap-1 inline-flex">
          <div className="text-center text-zinc-900 text-sm font-normal font-['Inter'] leading-[18px]">
            Already have an account?
          </div>
          <div
            onClick={() => setFlow(0)}
            className="cursor-pointer text-center text-blue-600 text-sm font-normal font-['Inter'] leading-[18px]"
          >
            Log in here
          </div>
        </div>
      </div>
    </form>
  );
}

export default SessionSignUpPage;
