import React, { useState } from "react";
import Button from "@mui/material/Button";
import { RootState } from "../../assets/interfaces/interfaces";
import { useSelector, useDispatch } from "react-redux";
import CancelModal from "./CancelModal";
import { AppDispatch } from "../../redux/store";
import { fetchChangeSubscription } from "../../redux/slices/changeSubscriptionSlice";
import UpgradeDowngradeModal from "./UpgradeModal";
import axios from "axios";
import { fetchActivePlan } from "../../redux/slices/activePlanSlice";
import Alert from "../../assets/images/finchatGPT/alert.png";
import CheckFill from "../../assets/images/finchatGPT/Check_fill.png";

declare global {
  interface Window {
    Chargebee;
  }
}
const getPlanRank = (planName) => {
  const ranks = { Free: 1, Pro: 2, Premium: 3 };
  return ranks[planName] || 0;
};
const PlanCard = ({
  planName,
  price,
  billingCycle,
  prompts,
  id,
  currentPlan,
  currentPlanName,
  nonRenewing,
  exp,
  extraText,
  taAccess,
  yearly,
}) => {
  const user = useSelector((state: RootState) => state.auth.currentUser);
  const currentPlanRank = getPlanRank(currentPlanName);
  const planRank = getPlanRank(planName);
  const dispatch: AppDispatch = useDispatch();
  const [cancelModal, setCancelModal] = useState(false);
  const [upgradeModal, setUpgradeModal] = useState(false);
  const [upgrade, setUpgrade] = useState(false);
  let finalButtonText = "";
  let buttonType, disabled;
  if (currentPlan) {
    if (planRank === 1 || !nonRenewing) {
      disabled = true;
      buttonType = "disabled";
      finalButtonText = "Current Plan";
    }
    if (nonRenewing && (planRank === 2 || planRank === 3)) {
      finalButtonText = `Reactivate ${planName}`;
    }
  }
  else if (currentPlanRank < planRank) {
    finalButtonText = `Upgrade to ${planName}`;
  }
  else {
    if (nonRenewing && planRank === 1) {
      disabled = true;
      buttonType = "disabled";
      finalButtonText = "Pending";
    }
    else {
      finalButtonText = `Downgrade to ${planName}`;
    }
  }

  const handleCancel = () => {
    dispatch(fetchChangeSubscription({ cancel: true }));
    setCancelModal(false);
  };
  const handleUpgradeDowngrade = (text: string) => {
    const cbInstance = window.Chargebee.getInstance();
    if (text.toLocaleLowerCase().includes("reactivate")) {
      axios.post(`${process.env.REACT_APP_SPL_URL}/subscriptions/remove-cancellation`, {}, { withCredentials: true }).then(() => {
        dispatch(fetchActivePlan());
      }).catch((err) => {
        console.log(err, "err in rm cancel");
      });
    }
    else {
      cbInstance.openCheckout({
        hostedPage: async function () {
          // This sampe response should be fetched as a result of an ajax call
          const { data } = await axios.post(`${process.env.REACT_APP_SPL_URL}/subscriptions/checkout-update`, { price_id: id }, { withCredentials: true });
          return data;
        },
        // success: function(hostedPageId) {
        //   // Optional
        //   // will be called when a successful checkout happens.
        // },
        close: function () {
          // Optional
          setTimeout(() => {
            dispatch(fetchActivePlan());
          }, 3500);
        },
      });
    }

    setUpgradeModal(false);
  };
  const reactivating = finalButtonText?.toLocaleLowerCase()?.includes("reactivate");
  return (
    <div className="relative block max-w-[320px] min-w-[320px] h-auto bg-white rounded-[20px] border border-solid border-[#d2d5da]">
      <CancelModal planName={currentPlanName} handleClose={() => setCancelModal(false)} open={cancelModal} handleCancel={handleCancel} />
      <UpgradeDowngradeModal reactivating={reactivating} planName={planName} currentPlanName={currentPlanName} upgrade={upgrade} open={upgradeModal} handleClose={() => setUpgradeModal(false)} handleConfirm={() => handleUpgradeDowngrade(finalButtonText)} />
      {/* Top Section */}
      <div className="flex items-center gap-[16px] pt-[24px] p-[20px] pl-[32px] bottom-bordered relative">
        <div className="flex w-[136px] items-start relative">
          <div className="relative flex-1 mt-[-1.00px] font-black text-colorstextlightprimary text-[24px]">
            {planName}
          </div>
        </div>
        {planName.includes("Pro") && (
          <div className="inline-flex items-start p-[8px] relative flex-[0_0_auto] mt-[-2.50px] mb-[-2.50px] bg-[#007bff1a] rounded-[10px]">
            <div className="relative font-footnote-regular text-[#000000]">
              Most popular 🔥
            </div>
          </div>
        )}
      </div>

      <div className="min-h-[195px] flex flex-col items-start gap-[1px] p-[20px] pl-[32px] relative">
        <div className="text-[#1C1C1E] font-sfprotext text-[48px] font-extrabold leading-[1] tracking-tight">
          {prompts}
        </div>
        <div className="text-[#1C1C1E] font-inter text-[36px] font-normal font-normal leading-[1] tracking-normal">
          <b className="text-[48px] font-extrabold "> prompts </b>
          {" "}
          <br />
          a day
        </div>
        {taAccess && (
          <div
            className="relative top-[17px] Point self-stretch justify-start items-center gap-1 inline-flex"
          >
            <div className="CheckFill w-5 h-5 relative">
              <img
                alt="checkbox"
                className="Subtract w-[20px] h-[20px] left-[1.50px] top-[1.50px] absolute"
                src={CheckFill}
              />
            </div>
            <div
              className={`text-black text-sm font-normal font-['Inter'] leading-[18px] plan-text`}
            >
              Access to TradeAlgo Terminal Plan
            </div>
          </div>
        )}
      </div>
      {/* Button Section */}
      <div className=" w-full flex ">
        <div className="mt-4 flex justify-center items-center w-full px-[24px] py-0">
          {disabled
            ? (
                <Button
                  variant="contained"
                  type="button"
                  disabled={true}
                  className={`!rounded-md !w-[256px] !h-[44px] !text-white !font-inter !text-[14px] !normal-case !font-semibold !leading-[18px] !tracking-tight !p-[16px] whitespace-nowrap !bg-black text-white ${disabled ? "!opacity-50" : ""}`}
                >
                  {finalButtonText}
                </Button>
              )
            : currentPlanRank === 1 && !!id
              ? (
                  <Button
                    className={` !rounded-md !w-[256px] !h-[44px] !text-white !font-inter !text-[14px] !normal-case !font-semibold !leading-[18px] !tracking-tight !p-[16px] whitespace-nowrap !bg-black text-white${buttonType === "disabled" ? "!opacity-50" : ""}`}
                    data-cb-email={user?.email}
                    data-cb-item-0={id}
                    data-cb-type="checkout"
                    variant="contained"
                    type="button"
                    disabled={buttonType === "disabled"}
                  >
                    {finalButtonText}
                  </Button>
                )
              : (
                  <Button
                    className={` !rounded-md !w-[256px] !h-[44px] !text-white !font-inter !text-[14px] !normal-case !font-semibold !leading-[18px] !tracking-tight !p-[16px] whitespace-nowrap !bg-black text-white${buttonType === "disabled" ? "!opacity-50" : ""}`}
                    variant="contained"
                    type="button"
                    disabled={buttonType === "disabled"}
                    onClick={() => {
                      if (planName === "Free") {
                        setCancelModal(true);
                      }
                      else {
                        if (planName === "Pro") {
                          setUpgrade(false);
                        }
                        else {
                          setUpgrade(true);
                        }
                        setUpgradeModal(true);
                      }
                    }}
                  >
                    {finalButtonText}
                  </Button>
                )}
        </div>
      </div>
      {
        nonRenewing && (
          <div className={`text-black flex font-inter text-xs font-semibold leading-4 gap-[10px] px-[24px] pt-6 `}>
            <img src={Alert} className=" pt-[1px] w-[16px] h-[16px]" />
            <div>
              Your subscription has been cancelled and will expire on
              {" "}
              {exp}
              .
              {reactivating ? "Click to reactivate the subscription" : finalButtonText.includes("Upgrade") ? "Click to upgrade to the Tradealgo Premium Subscription" : "After which, your plan will be downgraded to the Free package."}
            </div>
          </div>
        )
      }
      <div className="mt-8">

        <div className="flex flex-col items-start gap-[3px] py-[16px] pl-[32px] top-bordered relative">
          {
            !!extraText && (
              extraText
            )
          }
          <div className="flex w-[136px] items-center relative text-[#1C1C1E] font-inter !text-[24px] font-bold leading-normal tracking-tighter">
            {price}
            {" "}
            <span className="text-[#1C1C1E] text-center font-inter text-[16px] font-normal leading-[22px] tracking-tighter relative top-[1px] left-[5px]">
              /
              <span className="relative top-[1px] left-[5px]">{yearly ? "year" : "month"}</span>
            </span>
          </div>
          <div className="flex items-start text-[#525252] text-center font-inter text-[16px] font-normal leading-[22px] tracking-tighter">
            {billingCycle}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
