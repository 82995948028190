import { FormEvent, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { DefaultSignInProps, User } from "../../../assets/interfaces/interfaces";
import { showNotification } from "../../../redux/slices/notificationSlice";
import { validateSignInForm } from "../../auth/DefaultSignIn";
import { clearUser, setAuthLoading, setUser } from "../../auth/authActions";
import { getAuth, signInWithEmailAndPassword, User as FUser } from "firebase/auth";

const SessionDefaultSignIn: React.FC<
  DefaultSignInProps & { onSuccess: () => void }
> = ({ emailValue, setFlow, onSuccess }) => {
  const dispatch = useDispatch();
  const auth = getAuth();

  const [email, setEmail] = useState<string>(emailValue);
  const [password, setPassword] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    const { valid, errors } = validateSignInForm(email, password);
    // Update error states
    setEmailError(errors.email);
    setPasswordError(errors.password);
    if (!valid) return;
    try {
      dispatch(setAuthLoading(true)); // Start loading
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      );

      const firebaseUser: FUser & Partial<User> & { signInAttempts?: string | number } = userCredential.user;
      // Fetch additional user details from Firestore
      if (!firebaseUser) {
        // Handle the case where Firestore document doesn't exist
        dispatch(clearUser());
        return;
      }
      const userProfile = {
        accessToken: firebaseUser.accessToken,
        userId: firebaseUser.uid,
        display_name: firebaseUser.displayName,
        email: firebaseUser.email,
        photoURL: firebaseUser.photoURL || userCredential.user.photoURL,
        phoneNumber: firebaseUser.phoneNumber,
        country: firebaseUser.country,
        state: firebaseUser.state,
        name: firebaseUser.name,
        ip: firebaseUser.ip,
        sign_in_attempts: firebaseUser.signInAttempts,
        feature_flags: firebaseUser.feature_flags,
      };
      dispatch(setUser(userProfile));
      dispatch(
        showNotification({
          message: "Sign In Successful!",
          severity: "success",
          horizontal: "right",
        }),
      );
      // onSuccess callback
      onSuccess();
    }
    catch (error) {
      console.error(error);
      dispatch(clearUser());
      dispatch(
        showNotification({
          message: error.code.replace("auth/", "").replaceAll("-", " "),
          severity: "error",
          horizontal: "right",
        }),
      );

      throw error; // You can handle the error as needed
    }
    finally {
      dispatch(setAuthLoading(false)); // Stop loading
      console.log("setting auth loading");
    }
  };

  return (
    <div className="w-full h-[534px] justify-center relative bg-white rounded-[10px] flex-col items-center gap-10 inline-flex">
      <div className="items-start inline-flex">
        <div className="text-center text-zinc-900 text-[28px] font-bold font-['Inter'] leading-[30px]">
          Enter Password
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="flex-col justify-center items-center gap-6 flex"
      >
        <TextField
          className="w-[295px] mb-4"
          label="Email"
          variant="outlined"
          value={email}
          error={!!emailError}
          helperText={emailError}
          onChange={e => setEmail(e.target.value)}
        />
        <TextField
          className="w-[295px]"
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          error={!!passwordError}
          helperText={passwordError}
          onChange={e => setPassword(e.target.value)}
        />
        <div className="justify-start items-start gap-1 inline-flex">
          <div className="cursor-pointer text-center text-blue-600 text-sm font-normal font-['Inter'] leading-[18px]">
            Forgot Password?
          </div>
        </div>
        <Button
          type="submit"
          variant="contained"
          className="w-[295px] h-[50px] text-white font-inter text-[16px] font-medium leading-[30px] tracking-[-0.32px] !normal-case !bg-[#1C1C1E]"
        >
          Continue
        </Button>
        <div className="flex-col justify-start items-center gap-4 flex">
          <div className="justify-start items-start gap-1 inline-flex">
            <div className="text-center text-zinc-900 text-sm font-normal font-['Inter'] leading-[18px]">
              Don’t have an account?
            </div>
            <div
              onClick={() => setFlow(2)}
              className="text-center cursor-pointer text-blue-600 text-sm font-normal font-['Inter'] leading-[18px]"
            >
              Sign up here
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SessionDefaultSignIn;
