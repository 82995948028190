import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function SessionLoggedInUser({
  user,
  handleCurrentUser,
  handleSignOut,
}: {
  user;
  handleCurrentUser: () => void;
  handleSignOut: () => void;
}) {
  // Lets get user avatar if its logged in
  const [userAvatar, setUserAvatar] = useState<string>("");
  // Photo url
  useEffect(() => {
    if (!user?.email)
      return setUserAvatar("https://gravatar.com/avatar/?s=200&d=mp");
    if (user?.photoURL) return setUserAvatar(user.photoURL);

    const encoder = new TextEncoder();
    const data = encoder.encode(user.email);
    crypto.subtle.digest("SHA-256", data).then((hash) => {
      const hashString = Array.from(new Uint8Array(hash))
        .map(b => b.toString(16).padStart(2, "0"))
        .join("");
      setUserAvatar(
        `https://www.gravatar.com/avatar/${hashString}?s=200&d=identicon`,
      );
    });
  }, [user?.email, user?.photoURL]);

  return (
    <div className="flex gap-y-10 mt-14 flex-col items-center w-full px-6 h-full">
      <div className="flex gap-y-2 flex-col items-center">
        <img
          alt="User"
          loading="lazy"
          width="100"
          height="100"
          decoding="async"
          className="rounded-full"
          src={userAvatar}
        />

        <div className="text-sm font-semibold">{user.display_name}</div>
      </div>

      <div className="flex gap-y-3 flex-col items-center">
        <Button
          variant="contained"
          className="w-full h-[50px] text-white font-inter text-[16px] font-medium leading-[30px] tracking-[-0.32px] !normal-case !bg-[#1C1C1E]"
          onClick={handleCurrentUser}
        >
          Continue
        </Button>
        <Button
          variant="outlined"
          className="!text-[#1C1C1E] !border-[#1C1C1E] font-inter text-[16px] font-medium leading-[30px] tracking-[-0.32px] !normal-case"
          onClick={handleSignOut}
        >
          Use another account
        </Button>
      </div>
    </div>
  );
}
