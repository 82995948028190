import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Button,
  IconButton,
  TextField,
  ToggleButton,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import likeDark from "../assets/images/finchatGPT/likeDark.png";
import likeLight from "../assets/images/finchatGPT/likeLight.png";
import dislikeDark from "../assets/images/finchatGPT/dislikeDark.png";
import dislikeLight from "../assets/images/finchatGPT/dislikeLight.png";
import checkLight from "../assets/images/finchatGPT/checkLight.png";
import checkDark from "../assets/images/finchatGPT/checkDark.png";
import { useEffect, useState } from "react";
import { Message } from "../assets/interfaces/interfaces";
import { AppDispatch } from "../redux/store";
import { useDispatch } from "react-redux";
import { sendFeedback } from "../redux/slices/feedbacksSlice";
import { useSelector } from "react-redux";
import { CrossIcon } from "./Plans/icons/GPTIcons/CrossIcon";

const style = {
  position: "absolute" as const,
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};

const additionalInfoForTooltip = {
  accuracy:
    "The response provided correct and precise trading information or analysis.",
  relevance:
    "The response was relevant to the current market conditions or recent events.",
  insightfulness:
    "The response offered valuable insights or unique perspectives on the market.",
  actionability:
    "The response included reasonable advice and/or clear steps to follow.",
  inaccuracy:
    "The response contained incorrect or misleading trading information.",
  irrelevance:
    "The response did not address the specific trading query or was not applicable to current market conditions.",
  lackOfInsight:
    "The response lacked depth or failed to provide new or useful information.",
  riskiness:
    "The response suggested overly risky strategies or ignored important risk factors.",
};

const InfoToolTip = styled(
  ({ className, ...props }: TooltipProps & { category: string }) => {
    return (
      <Tooltip {...props} classes={{ popper: className }} placement="top" />
    );
  },
)(({ category }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16.8px",
    height: "59px",
    width:
      category === "irrelevance" || category === "riskiness"
        ? "245px"
        : "177px",
  },
}));
export default function FeedbackModal({
  open,
  onClose,
  isLiked,
  message,
}: {
  open: boolean;
  onClose;
  isLiked: boolean;
  message?: Partial<Message> | null;
}) {
  const dispatch: AppDispatch = useDispatch();
  const handleClose = () => {
    if (feedbackText !== "" || selectedCategories.length > 0) {
      setShowWarning(true);
    }
    else {
      onClose();
      setShowWarning(false);
    }
  };

  const isDarkMode = useSelector((state: { theme }) => state.theme.darkMode);
  const [feedbackText, setFeedbackText] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const positiveCategories = [
    { label: "Accuracy", value: "accuracy" },
    { label: "Relevance", value: "relevance" },
    { label: "Insightfulness", value: "insightfulness" },
    { label: "Actionability", value: "actionability" },
  ];
  const negativeCategories = [
    { label: "Inaccuracy", value: "inaccuracy" },
    { label: "Irrelevance", value: "irrelevance" },
    { label: "Lack of insight", value: "lackOfInsight" },
    { label: "Riskiness", value: "riskiness" },
  ];

  const closeThankYouModal = () => {
    closeWarningModal();
    setShowThankYou(false);
  };
  const closeWarningModal = () => {
    setShowWarning(false);
    onClose();
  };

  useEffect(() => {
    setSelectedCategories([]);
    setFeedbackText("");
    setShowWarning(false);
  }, [open]);

  function sendResponse() {
    try {
      if (message) {
        const payload = {
          message_id: message.chat_id,
          is_thumbs_up: isLiked,
          feedback_text: feedbackText,
          reason_cat: selectedCategories,
        };
        dispatch(sendFeedback(payload));
        setShowWarning(false);
        setShowThankYou(true);
      }
    }
    catch (error) {
      console.log(`tradegpt-post-feedback ERROR: `, error);
    }
  }

  const handleSelectCat = (selectedCategory: string) => {
    setSelectedCategories((prevSelected) => {
      if (prevSelected.includes(selectedCategory)) {
        return prevSelected.filter(category => category !== selectedCategory);
      }
      else {
        return [...prevSelected, selectedCategory];
      }
    });
  };

  let imgSrc;
  if (isLiked) {
    if (isDarkMode) {
      imgSrc = likeDark;
    }
    else {
      imgSrc = likeLight;
    }
  }
  else {
    if (isDarkMode) {
      imgSrc = dislikeDark;
    }
    else {
      imgSrc = dislikeLight;
    }
  }

  const isButtonDisabled = !feedbackText.length;

  const WarningModal = () => (
    <Modal open={showWarning} onClose={closeWarningModal}>
      <Box
        sx={style}
        className="w-modal-mobile sm:w-modal-sm md:w-modal-md lg:w-modal-lg rounded-lg p-6 bg-white text-mui-black-87 dark:bg-background/paper-elevation-16 dark:text-white"
      >
        <div className="flex flex-col gap-6 relative">
          <div className="flex justify-between items-start">
            <h6 className="text-xl pe-2">
              We're looking forward to hear from you!
            </h6>
            <IconButton
              size="small"
              onClick={closeWarningModal}
              className="cross-icon"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <CrossIcon className="fill-current" />
            </IconButton>
          </div>
          <div className="text-sm">
            We noticed you were almost ready to submit your feedback to us—would
            you like to continue giving us your feedback?
          </div>
          <div className="flex flex-col w-full gap-y-4 md:flex-row gap-x-6 justify-center">
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={() => setShowWarning(false)}
              className="button-primary"
            >
              Return to feedback
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              size="large"
              fullWidth
              className="button-outlined"
              onClick={closeWarningModal}
            >
              No, maybe next time
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );

  const ThankYouModal = () => (
    <Modal open={showThankYou} onClose={closeThankYouModal}>
      <Box
        sx={style}
        className="w-modal-mobile sm:w-modal-sm md:w-modal-md lg:w-modal-lg rounded-lg p-6 dark:bg-background/paper-elevation-16 dark:text-white bg-white text-mui-black"
      >
        <div className="flex flex-col gap-6 relative items-center">
          <div className="flex justify-between items-start">
            <img
              src={isDarkMode ? checkDark : checkLight}
              alt="check"
              className="h-[64px] w-[64px]"
            />
            <IconButton
              size="small"
              onClick={closeThankYouModal}
              className="cross-icon"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <CrossIcon className="fill-current" />
            </IconButton>
          </div>
          <div className="flex flex-col gap-y-4 items-center text-center">
            <div className="text-xl leading-[32px] tracking-[0.15px] font-medium">
              Thank you for your feedback!
            </div>
            <div className="text-sm tracking-[0.17px]">
              Your feedback will be used to better TradeGPT.
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );

  return (
    <>
      {showWarning && <WarningModal />}
      {showThankYou && !showWarning && <ThankYouModal />}
      {!showWarning && !showThankYou && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="feedback-modal"
        >
          <Box
            sx={style}
            className="w-modal-mobile sm:w-modal-sm md:w-modal-md lg:w-modal-lg rounded-lg p-6 bg-white text-mui-black-87 dark:bg-background/paper-elevation-16 dark:text-white"
          >
            <div className="flex flex-col gap-6 relative">
              {/* top section */}
              <div className="flex flex-row gap-x-6 pb-6 justify-between border-b  border-mui-black-12 dark:border-mui-white-12">
                <img className="w-[68px] h-[68px]" src={imgSrc} alt="thumbs" />
                <div className="flex sm:w-[540px] justify-between items-start">
                  <div className="flex flex-col gap-4">
                    <h6 className="text-xl leading-[32px] tracking-[0.15px] font-medium pe-5">
                      {`Let us know what you ${
                        isLiked ? "liked" : "disliked"
                      }.`}
                    </h6>
                    <p className="text-sm">
                      Your feedback is valuable—help shape the future of
                      TradeGPT.
                    </p>
                  </div>
                  <IconButton
                    size="small"
                    onClick={handleClose}
                    className="cross-icon"
                    sx={{ position: "absolute", top: 0, right: 0 }}
                  >
                    <CrossIcon className="fill-current" />
                  </IconButton>
                </div>
              </div>
              {/* text area */}
              <div className="flex flex-1 gap-4 flex-wrap">
                {(isLiked ? positiveCategories : negativeCategories).map(
                  (category) => {
                    const isSelected = selectedCategories.includes(
                      category.value,
                    );
                    const tooltipTitle
                      = additionalInfoForTooltip[category.value];
                    return (
                      <InfoToolTip
                        key={category.value}
                        title={tooltipTitle}
                        category={category.value}
                        placement="top"
                        arrow
                      >
                        <ToggleButton
                          size="small"
                          value={category.value}
                          key={category.value}
                          selected={isSelected}
                          className="category-button"
                          onClick={() => {
                            handleSelectCat(category.value);
                          }}
                        >
                          <p>{category.label}</p>
                        </ToggleButton>
                      </InfoToolTip>
                    );
                  },
                )}
              </div>
              <div>
                <TextField
                  size="medium"
                  variant="outlined"
                  value={feedbackText}
                  onChange={e => setFeedbackText(e.target.value)}
                  className="w-full outlined-input"
                  placeholder={`What went ${isLiked ? "well" : "wrong"}?`}
                />
              </div>
              {/* submit button */}
              <div className="flex justify-end leading-[26px] tracking-[0.46px] text-white dark:text-mui-black-87">
                <Button
                  disabled={isButtonDisabled}
                  variant="contained"
                  size="large"
                  color="inherit"
                  className="button-submit"
                  onClick={sendResponse}
                >
                  Submit feedback
                  <ChevronRightIcon className="ms-2" />
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
}
