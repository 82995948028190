import React from "react";
import { Link } from "react-router-dom";
import TradeDark from "../assets/images/finchatGPT/TradeLogo-D.png";
import TradeDarkCircle from "../assets/images/finchatGPT/TradeDark-Circle.png";
import { v4 as uuidv4 } from "uuid";

const NotFoundPage = () => {
  return (
    <div className="flex flex-col min-h-screen w-full bg-white">
      <div className="p-4 text-right">
        <Link to={`/chat/${uuidv4()}`}>
          <img src={TradeDark} alt="Trade Logo" className="inline-block w-6 h-6" />
          <span className="align-middle text-xl font-bold ml-2">TradeGPT</span>
        </Link>
      </div>
      <div className="flex-grow flex items-center justify-center">
        <div className="text-center mb-60">
          <div className="inline-flex items-center justify-center p-2">
            <img src={TradeDarkCircle} alt="Trade Logo" className="w-16 h-16" />
          </div>
          <h1 className="text-4xl font-bold text-gray-900 mt-2">404 - PAGE NOT FOUND</h1>
          <p className="mt-3 text-lg text-black">
            Unfortunately, the page you are looking for does not exist.
            <br />
            Please check your URL or return to the
            {" "}
            <Link to={`/chat/${uuidv4()}`} className="text-blue-600 underline">Home Page</Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
