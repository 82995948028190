import axios from "axios";
import { getAuth, signOut as firebaseSignOut } from "firebase/auth";

// Function to sign out the user
export const signOutUser = async () => {
  const auth = getAuth();
  try {
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_SPL_URL}/signout`,
      withCredentials: true, // This ensures cookies are sent with the request
    });
    await firebaseSignOut(auth);
    // console.log("User signed out successfully");
  }
  catch (error) {
    console.error("Error signing out: ", error);
  }
};
