import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { TokenState, UserArg } from "../../assets/interfaces/interfaces";
import Cookies from "universal-cookie";
import { doSignOutClear } from "../../components/auth/helpers";
import { ssoAtStr } from "../sagas/authSaga";

const initialState: TokenState = {
  token: "",
  status: "idle",
  error: null,
};

// Define the async thunk
export const fetchToken = createAsyncThunk(
  "token/fetchToken",
  async (arg: UserArg, { dispatch, rejectWithValue }) => {
    try {
      const cookies = new Cookies();
      const { token, isMobileApp } = arg;
      const { data } = await axios.post(
        `${process.env.REACT_APP_SPL_URL}/authenticate${
          isMobileApp ? "?response_type=body" : ""
        }`,
        { token },
        { withCredentials: true },
      );
      const success = data?.success;
      if (success) {
        const ssoToken = data[ssoAtStr];
        // Adjust options as needed, e.g., { path: '/', secure: true, sameSite: 'none' }
        cookies.set(ssoAtStr, ssoToken, {
          domain: ".tradealgo.com",
          path: "/",
          secure: true,
          sameSite: "strict",
        });
      }
      return { data };
    }
    catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response.status === 401) {
          // Lets log the user out if token is expired
          doSignOutClear(dispatch);
          return;
        }
        if (error.response) {
          return rejectWithValue(error.response.data);
        }
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

// Create the slice
export const fetchTokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    // standard reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchToken.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchToken.fulfilled, (state, action: PayloadAction<{ data: string }>) => {
        state.status = "succeeded";
        state.token = action.payload.data;
      })
      .addCase(fetchToken.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch token";
      });
  },
});

// Export the reducer from the slice
export default fetchTokenSlice.reducer;
