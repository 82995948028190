import { useEffect } from "react";
import { useLocation, useParams, Outlet } from "react-router-dom";

const LocationListener = ({ onLocationChange }) => {
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    onLocationChange(location, params);
    // not including params in deps because useParams returns a new object on every render
  }, [location, onLocationChange]);

  return <Outlet />;
};

export default LocationListener;
