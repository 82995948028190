import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { UserState, fetchUserArg } from "../../assets/interfaces/interfaces";
import { fetchUpdateFirestore } from "./updateFieldSlice";
import { getAuth } from "firebase/auth";
import { findData } from "../../components/auth/AuthListener";
import { parsePhoneNumber } from "libphonenumber-js";
import { injectCjConversion, injectTrackingJS } from "../../components/Session/helpers";
import { showPromo } from "./IntroPromoSlice";
import { doSignOutClear } from "../../components/auth/helpers";
import Cookies from "universal-cookie";
import type { User } from "../../assets/interfaces/interfaces";
const initialState: UserState = {
  user: {
    accessToken: "",
    userId: "",
    phoneNumber: "",
    feature_flags: "",
    country: "",
    state: "",
    email: "",
    display_name: "",
    name: "",
    ip: "",
    sign_in_attempts: "",
    photoURL: "",
  }, // Initialized as an empty array
  status: "idle",
  error: null,
};

// Define the async thunk
export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (arg: fetchUserArg, { rejectWithValue, dispatch }) => {
    try {
      const { source } = arg;
      const auth = getAuth();
      const user = auth?.currentUser || "";
      const { data, status } = await axios.get(
        `${process.env.REACT_APP_SPL_URL}/user`,
        { withCredentials: true },
      );
      if (user && status === 200 && !data?.user?.tradegpt) {
        const email = user?.email || "";
        let phone
          = localStorage.getItem("phoneNumber") || user.phoneNumber || "";
        let name = user?.displayName || "";
        const userId = user?.uid || "";
        const cookies = new Cookies();
        const cjeCookie = cookies.get("cje");
        let country = "";
        const { phoneNumber, displayName } = findData(user);
        if (displayName?.length > name?.length) {
          name = displayName;
        }
        if (phone?.length < phoneNumber?.length) {
          phone = phoneNumber;
        }
        if (phone.length) {
          country = parsePhoneNumber(phone)?.country || "";
        }
        const firestoreFields = {
          email,
          tradegpt: {
            phoneNumber: phone,
            displayName,
            country,
          },
        };

        // inject cje script
        injectCjConversion("464052", 0, userId, email, cjeCookie, userId);

        injectTrackingJS(1);
        dispatch(fetchUpdateFirestore({ firestoreFields, source }));
        dispatch(showPromo());
        // dispatch the modal to show Intro To Tradegpt
      }
      return data;
    }
    catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response.status === 403) {
          // Lets log the user out if token is expired
          doSignOutClear(dispatch);
          return;
        }
        if (error.response) {
          return rejectWithValue(error.response.data);
        }
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

// Create the slice
export const activeUserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // standard reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch active plan";
      });
  },
});

// Export the reducer from the slice
export default activeUserSlice.reducer;
