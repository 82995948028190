import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Modal,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Divider,
  ListItemIcon,
} from "@mui/material";
import { SettingsIcon } from "./Plans/icons/GPTIcons/SettingsIcon";
import { useSelector, useDispatch } from "react-redux";
import { toggleDarkMode } from "../redux/slices/themeSlice";
import {
  RootState,
  SettingsModalInterface,
} from "../assets/interfaces/interfaces";
import { fetchDeleteAllSessionsSlice } from "../redux/slices/deleteAllSessionsSlice";
import { AppDispatch } from "../redux/store";
import ConfirmDeleteModal from "./Sidebar/ConfirmDeletemodal";
import { fetchDeleteUser } from "../redux/slices/deleteUserSlice";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { CrossIcon } from "./Plans/icons/GPTIcons/CrossIcon";

const SettingsModal: React.FC<SettingsModalInterface> = ({
  open,
  setOpen,
  generalActive,
  setGeneralActive,
  handleProfileMenuClose,
  clearAllChatData,
  handleSignOut,
}) => {
  const handleClose = () => {
    setOpen(false);
    setGeneralActive(true);
    handleProfileMenuClose();
  };
  const accountDeletedStatus = useSelector(
    (state: { deleteUser }) => state?.deleteUser?.status,
  );
  const isDarkMode = useSelector((state: { theme }) => state.theme.darkMode);
  const dispatch: AppDispatch = useDispatch();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const { messages } = useSelector((state: RootState) => state.chat);
  // Initialize theme based on Redux state
  const [theme, setTheme] = useState(isDarkMode ? "dark" : "light");
  const handleThemeChange = (event: SelectChangeEvent) => {
    const newTheme = event.target.value as string;
    setTheme(newTheme);
    dispatch(toggleDarkMode());
    localStorage.setItem(
      "tradegpt-darkmode",
      newTheme === "dark" ? "true" : "false",
    );
    document.body.className = newTheme === "dark" ? "dark" : "light";
  };
  const handleGeneralSettingsClick = () => {
    setGeneralActive(true);
  };
  const user = useSelector((state: { auth }) => state.auth.currentUser);

  const userId = user?.userId || "";

  const handleDeleteUser = () => {
    dispatch(fetchDeleteUser());
    setConfirmOpen(false);
    setDeleteUser(false);
  };

  useEffect(() => {
    if (accountDeletedStatus === "succeeded") {
      handleSignOut();
    }
  }, [accountDeletedStatus]);

  const handleDeleteAllChats = () => {
    dispatch(fetchDeleteAllSessionsSlice({ userId }));
    clearAllChatData();
  };

  // set general active to false is screen is <768px
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSmallScreen(true);
        setGeneralActive(false);
      }
      else {
        setIsSmallScreen(false);
        setGeneralActive(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [open, isSmallScreen]);

  return (
    <>
      {!confirmOpen && (
        <Modal open={open} onClose={handleClose}>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="shadow w-modal-mobile sm:w-modal-sm md:w-modal-md lg:w-modal-lg rounded-lg p-6 bg-white text-mui-black-87 dark:bg-background/paper-elevation-16 dark:text-white">
              <div className="relative">
                <IconButton
                  size="small"
                  onClick={handleClose}
                  className="cross-icon"
                  sx={{ position: "absolute", top: 0, right: 0 }}
                >
                  <CrossIcon className="fill-current" />
                </IconButton>
              </div>

              {/* General Settings Content */}
              <div className="w-full md:flex justify-between">
                {((!generalActive && isSmallScreen)
                || (generalActive && !isSmallScreen)) && (
                  <div className="flex flex-col gap-y-6 w-[279px] md:w-1/3 lg:w-[152px]">
                    <h6>Settings</h6>
                    <MenuItem
                      onClick={handleGeneralSettingsClick}
                      sx={{ width: "100%" }}
                      selected={generalActive}
                      className="menulist-item-custom"
                    >
                      <div className="flex items-center justify-between w-[279px] md:justify-center">
                        <div className="flex items-center">
                          <SettingsIcon className="fill-mui-black-87 dark:fill-white me-2" />
                          General
                        </div>
                        <div className="md:hidden">
                          <ListItemIcon className="ps-4 pt-2">
                            <ChevronRightIcon className="right-arrow text-mui-black-87 dark:text-white" />
                          </ListItemIcon>
                        </div>
                      </div>
                    </MenuItem>
                  </div>
                )}

                {generalActive && (
                  <div className="md:flex-1 flex flex-col gap-y-4 md:pt-14">
                    <h6 className="pb-2 md:hidden">General</h6>
                    <div className="flex-1 flex justify-between items-center w-full py-2 px-4">
                      <div className="body1">Theme</div>
                      <div className="w-1/2 flex justify-end">
                        <FormControl variant="standard" fullWidth>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={theme}
                            label={theme}
                            onChange={handleThemeChange}
                            className="standard-select select-input"
                            MenuProps={{
                              classes: { paper: "dropdown-menulist" },
                            }}
                          >
                            <MenuItem value="dark">Dark</MenuItem>
                            <MenuItem value="light">Light</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <Divider />
                    <div className="flex-1 flex justify-between items-center w-full py-2 px-4">
                      <div className="body1 w-1/2">Delete all chats</div>
                      <Button
                        disabled={!messages.length}
                        onClick={() => {
                          setOpen(false); // Close the first modal
                          setConfirmOpen(true); // Open the second modal after a short delay
                        }}
                        color="error"
                        variant="contained"
                        className="button-danger"
                        size="large"
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
      <ConfirmDeleteModal
        open={confirmOpen}
        handleClose={() => {
          setDeleteUser(false);
          setConfirmOpen(false);
        }}
        handleChatDelete={deleteUser ? handleDeleteUser : handleDeleteAllChats}
      />
    </>
  );
};
export default SettingsModal;
