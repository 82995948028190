import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { ValidateUserState } from "../../assets/interfaces/interfaces";
import { doSignOutClear } from "../../components/auth/helpers";

const initialState: ValidateUserState = {
  status: "idle",
  error: null,
  isValid: false,
};

// Define the async thunk
export const fetchValidateUser = createAsyncThunk(
  "chat/fetchValidateUser",
  async (arg, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SPL_URL}/user/tradegpt/check-deleted`,
        {
          withCredentials: true,
        },
      );
      return data;
    }
    catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response.status === 403) {
          // Lets log the user out if token is expired
          doSignOutClear(dispatch);
          return;
        }
        if (error.response) {
          return rejectWithValue(error.response.data);
        }
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

// Create the slice
export const validateUserSlice = createSlice({
  name: "validate-user",
  initialState,
  reducers: {
    resetValidUserState: () => initialState, // Resetting to initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchValidateUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchValidateUser.fulfilled,
        (state, action: PayloadAction<{ isValid: boolean }>) => {
          state.status = "succeeded";
          state.isValid = action.payload.isValid;
        },
      )
      .addCase(fetchValidateUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch messages";
      });
  },
});

export const { resetValidUserState } = validateUserSlice.actions;
// Export the reducer from the slice
export default validateUserSlice.reducer;
