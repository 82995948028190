// src/features/websocketSlice.js
import { createSlice, createAction } from "@reduxjs/toolkit";

// Initial state for the WebSocket messages
const initialState = {
  messages: [], // Store received messages
  status: "idle", // Reflect WebSocket connection status ('idle', 'connected', 'disconnected', etc.)
};

// Slice for WebSocket messages
const websocketSlice = createSlice({
  name: "websocket",
  initialState,
  reducers: {
    // Reducer to handle incoming messages
    messageReceived: (state, action) => {
      if (action.type === "websocket/messageReceived") {
        state.messages = action.payload;
      }
    },
    // Optional: Reducer for sending messages
    // Note: The actual sending happens in middleware, but you can track sent messages in your state if needed
    messageSent: (/** state, action **/) => {

    },
    // Update WebSocket connection status
    updateStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  // Optional: extraReducers can be used if you have other async thunks or actions from different slices that affect WebSocket state
});

// Destructure and export the actions
export const { messageReceived, messageSent, updateStatus } = websocketSlice.actions;

// Custom action creator outside of slice for sending a message.
// This can be dispatched and caught by middleware to actually send the message via WebSocket.
export const sendMessage = createAction<string[]>("websocket/sendMessage");
export const connectWebSocket = createAction("websocket/connectRequest");

// Export the reducer
export default websocketSlice.reducer;
