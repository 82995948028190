import { UserPreferencePayload, UserPreferenceState, fetchUserPrefArg } from "../../assets/interfaces/interfaces";
import axios from "axios";
import { doSignOutClear } from "../../components/auth/helpers";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

const initialState: UserPreferenceState = {
  userId: "",
  preferences: null,
  status: "",
  error: null,
  hasOpenedModal: null,
};

export const updateUserPreference = createAsyncThunk(
  "user/updateUserPreference",
  async (arg: fetchUserPrefArg, { rejectWithValue, dispatch }) => {
    try {
      const query = {
        user_id: arg.userId,
        trading_focus: arg.preferences.tradingFocus,
        market_cap: arg.preferences.marketCap,
        risk: arg.preferences.riskAppetite,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_SPL_URL}/tradegpt-user-prefs`,
        query,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );
      const updatedPreference = {
        preferences: {
          tradingFocus: response.data.trading_focus,
          marketCap: response.data.market_cap,
          riskAppetite: response.data.risk,
        },
      };
      return updatedPreference;
    }
    catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response.status === 403) {
          // Lets log the user out if token is expired
          doSignOutClear(dispatch);
          return;
        }
        if (error.response) {
          return rejectWithValue(error.response.data);
        }
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

export const getUserPreferences = createAsyncThunk(
  "user/fetchUserPreferences",
  async (arg: fetchUserPrefArg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SPL_URL}/tradegpt-user-prefs/${arg.userId}`, { withCredentials: true },
      );

      if (Object.keys(data).length === 0) {
        return;
      }

      const userPreferences = {
        preferences: {
          tradingFocus: data.trading_focus,
          marketCap: data.market_cap,
          riskAppetite: data.risk,
        },
      };
      return userPreferences;
    }
    catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response.status === 403) {
          doSignOutClear(dispatch);
          return;
        }
        if (error.response) {
          return rejectWithValue(error.response.data);
        }
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

export const userPreferenceSlice = createSlice({
  name: "userPreferences",
  initialState,
  reducers: {
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserPreferences.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserPreferences.fulfilled, (state, action: PayloadAction<UserPreferencePayload>) => {
        // Set status and handle empty preferences case
        state.status = "succeeded";

        if (!action.payload || Object.keys(action.payload).length === 0) {
          state.hasOpenedModal = false;
        }
        else {
          state.preferences = action.payload.preferences;
          state.hasOpenedModal = true;
        }
      })
      .addCase(getUserPreferences.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch user preferences";
      })
      .addCase(updateUserPreference.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUserPreference.fulfilled, (state, action: PayloadAction<UserPreferencePayload>) => {
        state.status = "succeeded";
        state.preferences = action.payload.preferences;
        state.hasOpenedModal = true;
      })
      .addCase(updateUserPreference.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to update user preferences";
      });
  },
});

export const { setUserId } = userPreferenceSlice.actions;
export default userPreferenceSlice.reducer;
