import ReactGA from "react-ga4";
import UAParser from "ua-parser-js";
import { parseAnalyticsParams } from "./helpers";

const ENVIRONMENT = process.env.REACT_APP_ENV || "development";

const parser = new UAParser();

export const TRACKING_ID = ENVIRONMENT === "prod" ? "G-L6PJ0P400Z" : "G-GQJS32GLKL";

export const setGAUser = (user_id) => {
  ReactGA.set({ user_id });
};

export const logPageView = (path, params = {}) => {
  try {
    const device_type = parser.getOS().name;
    const parsedParams = parseAnalyticsParams({ category: "page_view", path, device_type, ...params });
    ReactGA.event("page_route_event", parsedParams);
  }
  catch (error) {
    console.error("Error logging page view:", error);
  }
};

export const logClickEvent = (eventName, params = {}) => {
  try {
    const device_type = parser.getOS().name;
    const parsedParams = parseAnalyticsParams({ category: "homepage_click_event", device_type, ...params });
    ReactGA.event(eventName, parsedParams);
  }
  catch (error) {
    console.error("Error logging click event:", error);
  }
};

export const logRecommendedPromptsActions = (category, action, customFields = {}) => {
  try {
    const deviceType = parser.getOS().name;
    ReactGA.event(action, { category, device_type: deviceType, ...customFields });
  }
  catch (error) {
    console.error("Error logging recommended prompts actions", error);
  }
};
