import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { FirestoreArg, updateFirestoreState } from "../../assets/interfaces/interfaces";

const initialState: updateFirestoreState = {
  firestoreFields: {},
  status: "idle",
  error: null,
};

// Define the async thunk
export const fetchUpdateFirestore = createAsyncThunk(
  "firestore/updateFields",
  async (arg: FirestoreArg, { rejectWithValue }) => {
    try {
      const { firestoreFields, source } = arg;
      const { status } = await axios.put(`${process.env.REACT_APP_SPL_URL}/user/fields`, { ...firestoreFields }, { withCredentials: true });
      if (status === 200) {
        // for new customers only, update fields only triggers on initial sign up.
        await axios.post(`${process.env.REACT_APP_SPL_URL}/invoices/customer`, { source }, { withCredentials: true });
      }
      return { firestoreFields };
    }
    catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

// Create the slice
export const updateFieldSlice = createSlice({
  name: "firestore",
  initialState,
  reducers: {
    // standard reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUpdateFirestore.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUpdateFirestore.fulfilled, (state, action: PayloadAction<{ firestoreFields: unknown }>) => {
        state.status = "succeeded";
        state.firestoreFields = action.payload.firestoreFields;
      })
      .addCase(fetchUpdateFirestore.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to update fields";
      });
  },
});

// Export the reducer from the slice
export default updateFieldSlice.reducer;
