import { call, put, take, fork, takeLatest } from "redux-saga/effects";
import { eventChannel, EventChannel } from "redux-saga";
import { connectWebSocket, messageReceived, sendMessage, updateStatus } from "../slices/websocketSlice";
import { PayloadAction, createAction } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";

let websocket: WebSocket | null = null;
export const triggerFetchActivePlan = createAction("activePlan/triggerFetch");

function createWebSocketConnection() {
  return eventChannel((emit) => {
    websocket = new WebSocket(process.env.REACT_APP_WEBSOCKET || "");

    websocket.onopen = () => {
      console.log("WebSocket connected");
      emit({ type: "STATUS", status: "connected" });
    };
    websocket.onmessage = (event) => {
      // Assuming the message is a plain text or JSON string
      emit({ type: "MESSAGE", data: event.data });
    };
    websocket.onerror = (error) => {
      console.error("WebSocket error:", error);
      emit({ type: "STATUS", status: "disconnected" });
    };
    websocket.onclose = () => {
      emit({ type: "STATUS", status: "disconnected" });
    };

    return () => {
      websocket?.close();
    };
  });
}

function* handleWebSocketEvents(socketChannel: EventChannel<unknown>): Generator {
  while (true) {
    const event = (yield take(socketChannel)) as { type: string; data: string; status: string };

    if (event.type === "MESSAGE") {
      const parsed = JSON.parse(event.data);
      // Assuming `parsed` has a `channel` property
      if (parsed.channel && parsed.channel.toLowerCase().includes("active")) {
        console.log("Condition met, fetching active plan...");
        // Directly invoke the async thunk using `yield call`
        yield put(triggerFetchActivePlan());
      }
      else {
        // If the condition is not met but it's still a MESSAGE type, handle as a regular message
        yield put(messageReceived(parsed));
      }
    }
    else if (event.type === "STATUS") {
      // Handle status updates
      console.log(`WebSocket status update: ${event.status}`);
      yield put(updateStatus(event.status));
    }
  }
}
function* websocketSaga() {
  // This saga acts as the single entry point for WebSocket logic
  yield takeLatest(connectWebSocket.type, function* () {
    // Check if a connection already exists and is open
    if (websocket && websocket.readyState === WebSocket.OPEN) {
      console.log("WebSocket already connected.");
      return;
    }

    const socketChannel = yield call(createWebSocketConnection);

    // Handling WebSocket events
    yield fork(handleWebSocketEvents, socketChannel);

    // Cleanup logic when disconnecting
    yield take("websocket/disconnect");
    socketChannel.close();
  });
}
function* sendMessageSaga() {
  yield takeLatest(sendMessage.type, function* (action: PayloadAction<string>) {
    if (websocket && websocket.readyState === WebSocket.OPEN) {
      websocket.send(JSON.stringify(action.payload));
    }
    else {
      console.error("WebSocket is not connected or not available.");
      // Implement retry logic or handle error as needed
    }
  });
}

export default function* rootWebSocketSaga(): SagaIterator {
  yield fork(websocketSaga);
  yield fork(sendMessageSaga);
}
