const faq = [
  {
    mainTitle: "General",
    items: [
      {
        title: <strong>What is TradeGPT?</strong>,
        description: "TradeGPT is a Finance-based chatbot that is built upon 30 years of finance data ranging from stocks, options, forex to Economics and trading.",
      },
      {
        title: <strong>Does TradeGPT have access to the internet?</strong>,
        description: "If TradeGPT cannot find any anwsers to your prompt using our data and tools, it will utilise web-sourcing to search the web for answers. The web sourcing tool used is duckduckgo.",
      },
      {
        title: <strong>How does TradeGPT work?</strong>,
        description: (
          <div>
            <div>TradeGPT harnesses Large Language Model technology, propriertary tools and comprehensive data resources to offer precise and insightful responses to a wide range of finance-related queries.</div>
            <div><b>LLM</b></div>
            <div>At the core of TradeGPT is a state-of-the-art Language Learning Model (LLM). This model has been trained on a vast corpus of text, enabling it to understand and generate human-like responses.</div>
            <div><b> Propietary Tools </b></div>
            <div>We have also developed proprietary tools that are integrated with the LLM. These tools enhance the model's ability to parse queries, understand context, and provide relevant answers. They are continuously updated for improved performance.</div>
            <div><b> In-House Databases </b></div>
            <div>TradeGPT taps into our extensive in-house databases, which contain a wealth of finance-related information. These databases include historical financial data, market trends, economic data and market news etc.</div>
          </div>
        ),
        html: true,
      },
      {
        title: <strong>What are the types of questions I can ask TradeGPT?</strong>,
        description: (
          <div>
            <div>Here are some suggestions on what you can ask TradeGPT for:</div>
            <div><b>Price & Performance</b></div>
            <ol>
              <li>1. Ask for stock comparison between 2 stocks (example: AAPL VS Samsung)</li>
              <li>2. Ask for latest price data for a specific stock or a forex pair.</li>
              <li>3. Retrieve OHLC (Open, High, Low, Close) info for a stock ticker at any given date.</li>
            </ol>
            <div><b>Company Financials</b></div>
            <ol>
              <li>1. Retrieve detailed financial data and ratios like balance sheet, income statement, profit margin, ROE, P/E, D/E, O/B etc.</li>
              <li>2. Ask for historical and upcoming EPS and revenue release, as well as upcoming earning calendar date given a stock ticker.</li>
              <li>3. Retrieve detailed information regard a company (sector, market cap, CIK number etc).</li>
              <li>4. Compare a financial parameter between 2 companies. (example: Compare the revenue between Google VS MSFT)</li>
            </ol>
            <div><b>News Related</b></div>
            <ol>
              <li>1. Retrieve the latest news article regarding a specific company.</li>
              <li>2. Get latest highlights and information on the market, by scooping out impactful news that are highly discussed in the market.</li>
            </ol>
          </div>
        ),
        html: true,
      },
      {
        title: <strong>What is the source of the financial data?</strong>,
        description: "Source of TradeGPT's financial data comes from a few sources, such as Finnhub and Polygon. Data includes stock prices, historical market data, financial statements of companies etc.",
      },
    ],
  },
  {
    mainTitle: "Pricing",
    items: [
      {
        title: <strong>How does your pricing work?</strong>,
        description: (
          <div>
            <div className="mb-4">TradeGPT offers a tiered pricing structure designed to cater to various usage needs and budgets. As you move up each tier, the cost per prompt becomes more economical, providing greater value for frequent users.</div>
            <div className="mb-4">Heres a breakdown of our current pricing plans:</div>
            <ol>
              <li className="mb-4">
                <strong>Free Plan: 15</strong>
                {" "}
                prompts a day.
              </li>
              <li className="mb-4">
                <strong>Pro Plan: 50</strong>
                {" "}
                prompts a day for
                {" "}
                <strong>US$29</strong>
                {" "}
                per month or
                {" "}
                <strong>US$295</strong>
                {" "}
                per year.
              </li>
              <li className="mb-4">
                <strong>Premium Plan: Unlimited</strong>
                {" "}
                prompts for
                {" "}
                <strong>US$299</strong>
                {" "}
                per month or
                {" "}
                <strong>US$2,995</strong>
                {" "}
                per year.
              </li>
            </ol>
            <div> Please note that unused prompts do not roll over to the next month, so it's important to choose a plan that closely aligns with your expected usage.</div>
          </div>
        ),
      },
    ],
  },
  {
    mainTitle: "Subscription",
    items: [
      {
        title: <strong>How does the monthly subscription work?</strong>,
        description: "You will be billed on the same day each month, corresponding to your initial sign-up date. For example, if you sign up on the 5th of March 2024, your next billing date would be the 5th of April 2024.                                                                                                                                                             The payment method you provided at sign-up will be automatically charged on your billing date each month. You can view and manage your subscription details, including the billing date, through your account settings.",
      },
      {
        title: <strong>How do i update my payment method?</strong>,
        description: "You can view and manage your payment method details through your account settings. Click on your Profile Name > My Plan > Manage Subscription.",
      },
      {
        title: <strong>Can I upgrade my subsription later on?</strong>,
        description: "Yes, you may upgrade your subscription. Example from the Pro to Pro Plus plan.                                                                                                                                                           If you upgrade or downgrade your plan mid-cycle, charges will be prorated accordingly. This ensures that you only pay for the portion of the service you use under each plan.",
      },
      {
        title: <strong>Can I cancel my subscription</strong>,
        description: " If you decide to cancel your subscription, you can do so at any time. Your access to the service will continue until the end of your current billing cycle.",
      },
      {
        title: <strong>What is the refund policy for the packages</strong>,
        description: "All the plans are non-refundable. Once you have subscribed to a plan, you will be billed on a recurring basis for each billing period. We do not offer refunds for any unused portion of the monthly subscription period.                                                                                                                                          If you choose to cancel your subscription, you will not be eligible for a refund for the remaining days of that billing period.",
      },
    ],
  },
  {
    mainTitle: "Prompts",
    items: [
      {
        title: <strong>What is considered as 1 prompt?</strong>,
        description: "Each time you enter a question or a command and receive a response, it counts as one prompt. This includes any form of query, request for information, or command for an action that you input into the system.                                                                                                                                                     The length of your input does not affect how it's counted. Whether is it a short or lengthy one, each individual submission is considered as one prompt. If you ask a follow-up question based on the response you received, it is counted as a separate prompt.",
      },
      {
        title: <strong>How does TradeGPT track and manage my usage and prompts?</strong>,
        description: "Depending on the package you subscribe to, you will have a set limit of prompts available for use in each cycle. This limit varies based on the tier of your subscription plan.                                                                                                                                                                                     Any prompts that you don't use within a billing cycle will not be carried over to the next month. At the start of each new billing cycle, your prompt count will reset to according to the package you signed up for.",
      },
      {
        title: <strong>How can i purchase additional prompts and what are the costs?</strong>,
        description: "You can buy an additional pack of 80 prompts for $19. This option is available regardless of your current subscription plan. To purchase additional prompts, simply go to your account settings and select the option to 'Buy More Prompts'. ",
      },
    ],
  },
];

export default faq;
