import { memo } from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { TypingAnimationProps } from "../assets/interfaces/interfaces";
import "katex/dist/katex.min.css";
import { InlineMath, BlockMath } from "react-katex";
import rehypeRaw from "rehype-raw";

const escapeUnescapedDollars = latexString =>
  latexString.replace(/(?<!\\)\$/g, "\\$");

const isTableHeader = (line) => {
  const columns = line
    .split("|")
    .map(col => col.trim())
    .filter(col => col.length > 0);
  return columns.length >= 2;
};

const parseText = (text) => {
  const parts = [];
  let lastIndex = 0;
  let inTable = false;

  const processText = (start, end) => {
    if (start < end) {
      const content = text.slice(start, end);
      if (content) {
        parts.push({ type: "text", content });
      }
    }
  };

  for (let i = 0; i < text.length; i++) {
    const char = text.slice(i, i + 2);
    const tripleBacktick = text.slice(i, i + 3);

    // latex is detected and we are not in a table
    if (
      !inTable
      && (["\\[", "\\("].includes(char)
      || tripleBacktick === "```"
      || text[i] === "\n")
    ) {
      processText(lastIndex, i);
      lastIndex = i;
    }

    // when a table is detected, treat it as text + look out for latex that comes after
    if (!inTable && text[i] === "\n") {
      const lineStart = i + 1;
      const lineEnd = text.indexOf("\n", lineStart);
      const lineContent = text
        .slice(lineStart, lineEnd === -1 ? undefined : lineEnd)
        .trim();

      if (isTableHeader(lineContent)) {
        processText(lastIndex, i);
        inTable = true;
        lastIndex = i + 1;
        continue;
      }
    }

    if (inTable) {
      if (["\\[", "\\("].includes(char) || tripleBacktick === "```") {
        processText(lastIndex, i);
        lastIndex = i;
        inTable = false;
      }
      else {
        continue;
      }
    }

    if (text[i] === "\n" && !inTable) {
      parts.push({ type: "newline", content: "\n" });
      lastIndex = i + 1;
      continue;
    }

    // latex processing
    if (char === "\\[" || tripleBacktick === "```") {
      let startIndex = 0, endIndex = 0, endChar = "";

      if (char === "\\[") {
        startIndex = i + 2;
        endChar = "\\]";
      }
      else {
        const latexStart = text.indexOf("latex", i + 3);
        if (latexStart !== -1 && latexStart <= i + 7) {
          startIndex = latexStart + 5;
          endChar = "```";
        }
        else {
          continue;
        }
      }

      for (let j = startIndex; j < text.length; j++) {
        if (text.slice(j, j + endChar.length) === endChar) {
          endIndex = j;
          break;
        }
      }

      if (endIndex) {
        parts.push({
          type: "block",
          content: escapeUnescapedDollars(
            text.slice(startIndex, endIndex).trim(),
          ),
        });
        i = endIndex + endChar.length - 1;
        lastIndex = endIndex + endChar.length;
      }
    }

    if (char === "\\(") {
      for (let j = i + 2; j < text.length; j++) {
        if (text.slice(j, j + 2) === "\\)") {
          parts.push({ type: "inline", content: text.slice(i + 2, j) });
          i = j + 1;
          lastIndex = j + 2;
          break;
        }
      }
    }
  }

  processText(lastIndex, text.length);
  return parts;
};

const TypingAnimation = ({ text, components }: TypingAnimationProps) => {
  const contentParts = parseText(text);

  const renderContent = (part, index) => {
    switch (part.type) {
      case "newline":
        return <div key={index} className="h-2" />;
      case "inline":
        return (
          <span key={index} className="inline mx-1">
            <InlineMath math={part.content} />
          </span>
        );
      case "block":
        return <BlockMath key={index} math={part.content} />;
      case "text":
        return (
          <ReactMarkdown
            key={index}
            className="markdown-body inline-block"
            components={components}
            remarkPlugins={[gfm]}
            rehypePlugins={[rehypeRaw]}
          >
            {part.content}
          </ReactMarkdown>
        );
      default:
        return null;
    }
  };

  return (
    <div className="typing-animation">
      {contentParts.map((part, index) => renderContent(part, index))}
    </div>
  );
};

export default memo(TypingAnimation);
