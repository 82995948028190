// src/slices/IntroPromoSlice.ts
import { createSlice } from "@reduxjs/toolkit";
import { IntroPromoState } from "../../assets/interfaces/interfaces";

const initialState: IntroPromoState = {
  show: false,
};

const IntroPromoSlice = createSlice({
  name: "introPromo",
  initialState,
  reducers: {
    showPromo: (state) => {
      state.show = true;
    },
    hidePromo: (state) => {
      state.show = false;
    },
  },
});

export const { showPromo, hidePromo } = IntroPromoSlice.actions;
export default IntroPromoSlice.reducer;
