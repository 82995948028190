import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Divider } from "@mui/material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CancelModal({ planName, open, handleClose, handleCancel }: { planName: string; open: boolean; handleClose; handleCancel }) {
  return (
    <React.Fragment>
      <Dialog
        classes={{ paper: "!bg-[#191919] !min-w-[350px] !max-w-[550px]" }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle classes={{ root: "text-white" }}>Are You Sure?</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText classes={{ root: "!text-white" }} id="alert-dialog-slide-description">
            This will cancel your TradeGPT
            {" "}
            {planName}
            {" "}
            Subscription.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "11px" }}>
          <Button
            sx={{
              backgroundColor: "black",
              color: "white",
              textTransform: "none",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: "rgba(185,28,28)",
              color: "white",
              textTransform: "none",
            }}
            onClick={handleCancel}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
