export const CrossIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.44195 0.558058C1.19788 0.313981 0.802147 0.313981 0.55807 0.558058C0.313992 0.802136 0.313992 1.19786 0.55807 1.44194L5.11608 5.99996L0.558058 10.558C0.313981 10.8021 0.313981 11.1978 0.558058 11.4419C0.802136 11.6859 1.19786 11.6859 1.44194 11.4419L5.99997 6.88384L10.558 11.4419C10.8021 11.6859 11.1978 11.6859 11.4419 11.4419C11.6859 11.1978 11.6859 10.8021 11.4419 10.558L6.88385 5.99996L11.4419 1.44195C11.6859 1.19787 11.6859 0.802143 11.4419 0.558066C11.1978 0.313988 10.8021 0.313988 10.558 0.558066L5.99997 5.11607L1.44195 0.558058Z"
        fill="currentColor"
        fillOpacity="0.56"
      />
    </svg>
  );
};
