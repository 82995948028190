import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import useEnhancedNavigate, { injectCjConversion, injectTikTokScript, injectTrackingJS, removeTikTokScript } from "./Session/helpers";
import Cookies from "universal-cookie";
import { RootState } from "../assets/interfaces/interfaces";
import { useSelector } from "react-redux";
const amountLookup = {
  2900: "TradeGpt-Pro-USD-Monthly",
  29900: "TradeGpt-Premium-USD-Monthly",
  29500: "TradeGpt-Pro-USD-Yearly",
  299500: "TradeGpt-Premium-USD-Yearly",
  0: "free",
};
const event_id_lookup = {
  "TradeGpt-Premium-USD-Yearly": 5,
  "TradeGpt-Pro-USD-Yearly": 4,
  "TradeGpt-Premium-USD-Monthly": 3,
  "TradeGpt-Pro-USD-Monthly": 2,
  "free": 1,
};
export default function ThankYou() {
  const navigate = useEnhancedNavigate();
  const [orderId, setOrderId] = useState(null);
  const [amount, setAmount] = useState(null);
  const [injected, setInjected] = useState(false);
  const user = useSelector((state: RootState) => state.auth.currentUser);
  useEffect(() => {
    // Extract sub_id from URL
    const urlParams = new URLSearchParams(window.location.search);
    const orderIdFromUrl = urlParams.get("sub_id");
    const amountFromUrl = urlParams.get("amount");
    setOrderId(orderIdFromUrl);
    setAmount(amountFromUrl);
    // Inject the script when the component mounts
    injectTikTokScript();

    return () => {
      // Remove the script when the component unmounts
      removeTikTokScript();
    };
  }, []);

  useEffect(() => {
    if (!!orderId && !!user?.email && !!amount && !injected) {
      const { email, userId = "" } = user;
      const cookies = new Cookies();
      const cjeCookie = cookies.get("cje") || "";
      const product_id = amountLookup[amount] ? amountLookup[amount] : "";
      const queuedPlan = event_id_lookup[product_id] ? event_id_lookup[product_id] : "";
      const items = [
        {
          unitPrice: amount / 100, itemId: product_id, quantity: 1, discount: "0",
        },
      ];
      injectTrackingJS(queuedPlan);
      injectCjConversion("464053", amount / 100, userId, email, cjeCookie, orderId, items);
      setInjected(true);
    }
  }, [orderId, user, amount, injected]);

  return (
    <div className="flex items-center flex-col w-full justify-center h-screen bg-white">
      <div className="flex flex-col items-center space-y-10 max-w-[350px]">
        <h1 className="text-[28px] font-bold text-[#1C1C1E] font-['Inter'] text-center">
          Your new subscription is ready
        </h1>
        <p className="text-center font-['Inter'] font-normal">
          You will receive a
          {" "}
          <span className="font-['Inter'] font-semibold">welcome email</span>
          {" "}
          and an
          {" "}
          <span className="font-['Inter'] font-semibold">invoice email</span>
          {" "}
          once payment is successful.
        </p>

        <Button
          onClick={() => navigate("/chat")}
          variant="contained"
          className="w-[295px] h-[46px] text-white font-inter text-[16px] font-normal leading-[22px] tracking-[-0.43px] !normal-case !bg-[#1C1C1E]"
        >
          Continue to TradeGPT
        </Button>
      </div>
    </div>
  );
}
