import { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import PhoneNumberInput from "./PhoneNumberInput";
import { useSignup } from "./useSignup";
import { showNotification } from "../../redux/slices/notificationSlice";
import { useDispatch } from "react-redux";
import {
  FormData,
  RenderTextFieldFunc,
  RenderPhoneFieldFunc,
  ValidationResult,
} from "../../assets/interfaces/interfaces";
import { isValidPhoneNumber } from "libphonenumber-js";
import { AppDispatch } from "../../redux/store";

export const validateSignUpForm = ({
  email,
  name,
  phoneNumber,
  password,
}: FormData): ValidationResult => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const errors: {
    email?: string;
    name?: string;
    phoneNumber?: string;
    password?: string;
  } = {};
  let isValid = true;
  if (!email) {
    errors.email = "Email is required.";
    isValid = false;
  }
  else if (!emailRegex.test(email)) {
    errors.email = "Please enter a valid email address.";
    isValid = false;
  }

  if (!name) {
    errors.name = "Name is required.";
    isValid = false;
  }

  if (!phoneNumber) {
    errors.phoneNumber = "Phone number is required.";
    isValid = false;
  }
  else if (!isValidPhoneNumber(phoneNumber)) {
    errors.phoneNumber = "Please enter a valid phone number.";
    isValid = false;
  }

  if (password.length < 6) {
    errors.password = "Password must be at least 6 characters long.";
    isValid = false;
  }

  return { valid: isValid, errors };
};
export const renderTextField = ({
  label,
  value,
  onChange,
  error,
  type = "text",
}: RenderTextFieldFunc) => (
  <TextField
    label={label}
    variant="outlined"
    value={value}
    onChange={onChange}
    className="w-[295px] mb-4"
    error={!!error}
    helperText={error}
    type={type}
  />
);

export const renderPhoneField = ({
  label,
  value,
  onChange,
  error,
}: RenderPhoneFieldFunc) => (
  <PhoneNumberInput
    label={label}
    error={!!error}
    helperText={error}
    value={value}
    onChange={onChange}
  />
);

function SignUpPage({
  setFlow,
  handleClose,
}: {
  setFlow;
  handleClose: () => void;
}) {
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [phoneNumberError, setPhoneNumberError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const dispatch: AppDispatch = useDispatch();
  const signup = useSignup();
  const handleSignup = async (event) => {
    event.preventDefault();
    const { valid, errors } = validateSignUpForm({
      email,
      name,
      phoneNumber,
      password,
    });
    // Update error states
    setEmailError(errors.email);
    setNameError(errors.name);
    setPhoneNumberError(errors.phoneNumber);
    setPasswordError(errors.password);
    if (!valid) return;
    try {
      await signup({ email, password, displayName: name, phoneNumber });
      handleClose();
    }
    catch (error) {
      // handle error
      dispatch(
        showNotification({
          message: error.code.replace("auth/", "").replaceAll("-", " "),
          severity: "error",
          horizontal: "right",
        }),
      );
    }
  };
  return (
    <form
      onSubmit={handleSignup}
      className="w-[550px] h-[534px] justify-center flex-col items-center gap-8 inline-flex"
    >
      <div className="justify-start items-start inline-flex">
        <div className="text-center text-zinc-900 text-[28px] font-bold font-['Inter'] leading-[30px]">
          Create Account
        </div>
      </div>
      {renderTextField({
        label: "Email",
        value: email,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          setEmail(e.target.value),
        error: emailError,
      })}
      {renderTextField({
        label: "Name",
        value: name,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          setName(e.target.value),
        error: nameError,
      })}
      {renderPhoneField({
        label: "Phone Number",
        value: phoneNumber,
        onChange: value => setPhoneNumber(value),
        error: phoneNumberError,
      })}
      {renderTextField({
        label: "Password",
        value: password,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          setPassword(e.target.value),
        error: passwordError,
        type: "password",
      })}
      <Button
        type="submit"
        onClick={handleSignup}
        variant="contained"
        className="w-[295px] h-[50px] text-white font-inter text-[16px] font-medium leading-[30px] tracking-[-0.32px] !normal-case !bg-[#1C1C1E]"
      >
        Continue
      </Button>
      <div className="flex-col justify-start items-center gap-4 flex">
        <div className="justify-start items-start gap-1 inline-flex">
          <div className="text-center text-zinc-900 text-sm font-normal font-['Inter'] leading-[18px]">
            Already have an account?
          </div>
          <div
            onClick={() => setFlow(0)}
            className="cursor-pointer text-center text-blue-600 text-sm font-normal font-['Inter'] leading-[18px]"
          >
            Log in here
          </div>
        </div>
      </div>
    </form>
  );
}

export default SignUpPage;
