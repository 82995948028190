import React, { useEffect, useState } from "react";
import {
  Modal,
  Tabs,
  Tab,
  Button,
  ToggleButton,
  Typography,
  Card,
  Box,
  CardContent,
  IconButton,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { IconsIconOptions1 as OptionsIcon } from "./Plans/icons/IconsIconOptions1";
import { StocksIcon } from "./Icons/IconsStocksIcon";
import { SendIcon } from "./Icons/IconsSendIcon";
import { GraphUpIcon } from "./Icons/IconsGraphUpIcon";
import { FireFilledIcon } from "./Icons/IconsFIreFilledIcon";
import { CalDollarIcon } from "./Icons/IconsCalDollarFilled";
import { MessageStarIcon } from "./Icons/IconsMessageStarFilled";
import { CrossIcon } from "./Plans/icons/GPTIcons/CrossIcon";
import { useDispatch, useSelector } from "react-redux";
import useEnhancedNavigate from "./Session/helpers";
import { fetchRecommendedPrompts, setPromptSelected } from "../redux/slices/recommendedPromptsSlice";
import { AppDispatch } from "../redux/store";
import { logRecommendedPromptsActions } from "../analytics";

interface RecommendedPromptsModalProps {
  open: boolean;
  handleClose: () => void;
  onLimitReached: () => void;
}

const getPromptType = (topic: "stocks" | "options", stocksOptions: 0 | 1, optionsOption: 0 | 1) => {
  if (topic === "stocks") {
    return stocksOptions ? "top_picks" : "quick_trades";
  }
  else {
    return optionsOption ? "market_movers" : "hot_trades";
  }
};

const getEventType = (topic: "stocks" | "options", stocksOptions: 0 | 1, optionsOption: 0 | 1) => {
  if (topic === "stocks") {
    return { category: "recommended_prompt_event", action: stocksOptions ? "stocks_top_picks_view" : "stocks_quick_trade_view" };
  }
  else {
    return { category: "recommended_prompt_event", action: optionsOption ? "options_market_move_view" : "options_hot_trade_view" };
  }
};

const RecommendedPromptsModal: React.FC<RecommendedPromptsModalProps> = ({
  open,
  handleClose,
  onLimitReached,
}) => {
  const navigate = useEnhancedNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [selectedMainTab, setSelectedMainTab] = useState<"options" | "stocks">("options");
  const [selectedOptionsTab, setSelectedOptionsTab] = useState<0 | 1>(0);
  const [selectedStocksTab, setSelectedStocksTab] = useState<0 | 1>(0);
  const recommededPrompts = useSelector((state: { recommendedPrompts }) => state.recommendedPrompts.prompts);
  const plan = useSelector((state: { plan }) => state.plan?.plan);
  const darkMode = useSelector((state: { theme }) => state.theme.darkMode);
  const muiSelectedTabStyles = {
    "textTransform": "none",
    "lineHeight": "1.5rem",
    "letterSpacing": 0.4,
    "&.Mui-selected": {
      color: darkMode ? "rgba(0, 158, 255, 1)" : "rgba(0, 123, 255, 1)",
    },
  };

  useEffect(() => {
    dispatch(fetchRecommendedPrompts());
  }, []);

  useEffect(() => {
    if (open) {
      logRecommendedPromptsActions("recommended_prompt_event", "recommended_prompt_click");
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      const { category, action } = getEventType(selectedMainTab, selectedStocksTab, selectedOptionsTab);
      logRecommendedPromptsActions(category, action);
    }
  }, [selectedMainTab, selectedOptionsTab, selectedStocksTab, open]);

  const handleChange = (newValue) => {
    if (newValue) {
      setSelectedMainTab(newValue);
    }
  };

  const handleStocksTabChange = (event, newValue) => {
    setSelectedStocksTab(newValue);
  };

  const handleOptionsTabChange = (event, newValue) => {
    setSelectedOptionsTab(newValue);
  };

  const handlePromptSelect = (text) => {
    if (plan.promptsLeft === 0) {
      onLimitReached();
      handleClose();
      return;
    }
    if (text.trim()) {
      const sessionIdSafe = uuidv4();
      const plainText = text.replaceAll(/<\/?b>/g, "");

      dispatch(setPromptSelected(plainText));
      navigate(`/chat/${sessionIdSafe}`);
      logRecommendedPromptsActions(
        "recommended_prompt_event",
        "prompt_click",
        {
          prompt_message: plainText,
          prompt_category: getPromptType(selectedMainTab, selectedStocksTab, selectedOptionsTab),
        });
      handleClose();
    }
  };

  return (
    <>
      <Modal className="!outline-none" tabIndex={-1} open={open} onClose={handleClose}>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 !outline-none">
          <div className="shadow w-modal-mobile sm:w-modal-md md:w-modal-lg rounded-lg pb-6 bg-white text-mui-black-87 dark:bg-background/paper-elevation-16 dark:text-white">
            <div className="flex items-start justify-between p-6 rounded-t-lg">
              <Typography variant="h6">
                Turbocharge your trading with these prompts.
              </Typography>
              <IconButton
                size="small"
                onClick={handleClose}
                className="cross-icon"
              >
                <CrossIcon className="fill-current" />
              </IconButton>
            </div>
            <div className="px-6">
              <div
                className="flex justify-between gap-4 w-full"
              >
                <ToggleButton selected={selectedMainTab === "options"} onChange={() => handleChange("options")} size="small" className="flex-1 space-x-2 category-button rounded" value="options">
                  <OptionsIcon color="currentColor" className="w-5 h-5" />
                  <span>Options</span>
                </ToggleButton>
                <ToggleButton selected={selectedMainTab === "stocks"} onChange={() => handleChange("stocks")} size="small" className="flex-1 space-x-2 category-button rounded" value="stocks">
                  <StocksIcon color="currentColor" className="" />
                  <span>Stocks</span>
                </ToggleButton>
              </div>
            </div>
            <div className="px-6">
              {
                selectedMainTab === "options"
                  ? (
                      <Tabs
                        value={selectedOptionsTab}
                        onChange={handleOptionsTabChange}
                        classes={{
                          indicator: `!bg-mui-primary-blue-light dark:!bg-mui-primary-blue-dark`,
                        }}
                        textColor="inherit"
                        className="pt-2"
                      >
                        <Tab
                          className="!min-h-[42px]"
                          sx={muiSelectedTabStyles}
                          icon={<FireFilledIcon />}
                          iconPosition="start"
                          label="Hot Trades"
                        />
                        <Tab
                          className="!min-h-[42px]"
                          sx={muiSelectedTabStyles}
                          icon={<GraphUpIcon />}
                          iconPosition="start"
                          label="Market Moves"
                        />
                      </Tabs>
                    )
                  : (
                      <Tabs
                        value={selectedStocksTab}
                        onChange={handleStocksTabChange}
                        classes={{
                          indicator: `dark:bg-mui-primary-blue-dark !bg-mui-primary-blue-light`,
                        }}
                        textColor="inherit"
                        className="pt-2"
                      >
                        <Tab
                          className="!min-h-[42px]"
                          sx={muiSelectedTabStyles}
                          icon={<CalDollarIcon />}
                          iconPosition="start"
                          label="Quick Trades"
                        />
                        <Tab
                          className="!min-h-[42px]"
                          sx={muiSelectedTabStyles}
                          icon={<MessageStarIcon />}
                          iconPosition="start"
                          label="Top Picks"
                        />
                      </Tabs>
                    )
              }
              <div className="card-container mt-4 h-[360px] pb-4 overflow-y-auto space-y-4">
                {
                  recommededPrompts?.[selectedMainTab]?.[getPromptType(selectedMainTab, selectedStocksTab, selectedOptionsTab)].map((prompt, index) => (
                    <Card key={`card-${index}`} variant="outlined" className="flex align-center dark:bg-background/paper-elevation-16 dark:border-mui-white-12 border-token-divider-light dark:text-white">
                      <CardContent sx={{ flex: 1 }}>
                        <Typography
                          variant="body2/regular-nums"
                          component="div"
                          dangerouslySetInnerHTML={{
                            __html: prompt.query,
                          }}
                        />
                      </CardContent>
                      <Box sx={{ display: "flex", alignItems: "center", paddingRight: 2 }}>
                        <Button
                          onClick={() => handlePromptSelect(prompt.query)}
                          className="gap-2 !text-transform-none !text-mui-light-gray dark:!text-mui-gray !bg-black dark:!bg-white"
                          variant="contained"
                          size="small"
                        >
                          Use prompt
                          <SendIcon color="currentColor" />
                        </Button>
                      </Box>
                    </Card>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RecommendedPromptsModal;
