import { User } from "../../assets/interfaces/interfaces";
// Action Types
export const SET_USER = "SET_USER";
export const CLEAR_USER = "CLEAR_USER";
export const SET_AUTH_LOADING = "SET_AUTH_LOADING";
export const SIGN_OUT = "SIGN_OUT";
export const SIGN_OUT_REQUEST = "SIGN_OUT_REQUEST";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const SIGN_OUT_FAILURE = "SIGN_OUT_FAILURE";

// Action Creators
export const requestSignOut = () => ({
  type: SIGN_OUT_REQUEST,
  signOutLoading: true,
});

export const signOutSuccess = () => ({
  type: SIGN_OUT_SUCCESS,
  signOutLoading: true,
});

export const signOutFailure = error => ({
  type: SIGN_OUT_FAILURE,
  payload: error,
});

// Action Creator for setting loading state
export const setAuthLoading = (isLoading: boolean) => ({
  type: SET_AUTH_LOADING,
  payload: isLoading,
});
// Action Creators
export const setUser = (user: User) => ({
  type: SET_USER,
  payload: {
    accessToken: user.accessToken,
    userId: user.userId, // User ID
    display_name: user.display_name || user.displayName, // User's display name
    email: user.email, // User's email
    photoURL: user.photoURL, // User's photo URL
    phoneNumber: user.phoneNumber, // User's phone number
    country: user.country, // User's country
    state: user.state, // User's state
    name: user.name, // User's name
    ip: user.ip, // User's IP address
    sign_in_attempts: user.sign_in_attempts, // User's sign in attempts
    feature_flags: user.feature_flags, // User's feature flags
  },
});

export const clearUser = () => ({
  type: CLEAR_USER,
});
