import { Link } from "react-router-dom";
import TradeDark from "../assets/images/finchatGPT/TradeLogo-D.png";
import TradeDarkCircle from "../assets/images/finchatGPT/TradeDark-Circle.png";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { resetState } from "../redux/slices/deleteUserSlice";
import { resetValidUserState } from "../redux/slices/validateUserSlice";
import { clearUser, requestSignOut } from "./auth/authActions";
import { resetPlanStatus } from "../redux/slices/activePlanSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store";
import useEnhancedNavigate from "./Session/helpers";

const DeletedAccountPage = () => {
  const navigate = useEnhancedNavigate();
  const dispatch = useDispatch<AppDispatch>(); // Use the typed dispatch

  useEffect(() => {
    dispatch(resetState());
    dispatch(resetValidUserState());
    dispatch(requestSignOut());
    dispatch(clearUser());
    dispatch(resetPlanStatus());
  }, []);

  return (
    <div className="flex flex-col min-h-screen w-full bg-white">
      <div className="p-4 text-right">
        <Link to="/">
          <img src={TradeDark} alt="Trade Logo" className="inline-block w-6 h-6" />
          <span className="align-middle text-xl font-bold ml-2">TradeGPT</span>
        </Link>
      </div>
      <div className="flex-grow flex items-center justify-center">
        <div className="text-center mb-60">
          <div className="inline-flex items-center justify-center p-2">
            <img src={TradeDarkCircle} alt="Trade Logo" className="w-16 h-16" />
          </div>
          <h1 className="text-4xl font-bold text-gray-900 mt-2">Oops!</h1>
          <p className="mt-3 text-lg text-black max-w-[450px]">
            You do not have an account because it has been  deleted or deactivated. If you believe this was an error, please contact us at
            {" "}
            <a className="text-blue-500 underline" href="mailto:support@tradealgo.com">support@tradealgo.com</a>
            .
          </p>
          <Button onClick={() => navigate("/")} sx={{ color: "black", borderColor: "black", marginTop: "20px" }} variant="outlined">Go back</Button>
        </div>
      </div>
    </div>
  );
};

export default DeletedAccountPage;
