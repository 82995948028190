export const EditIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7187 4.01195L12.3202 5.61339C12.4636 5.44389 12.6129 5.25141 12.7478 5.04646C12.9759 4.69991 13.1364 4.35648 13.1823 4.05514C13.2247 3.77659 13.1703 3.54968 12.9747 3.34891C12.782 3.15111 12.5635 3.09559 12.2905 3.13627C11.9925 3.18067 11.6489 3.34126 11.2996 3.57184C11.0897 3.71045 10.8922 3.86439 10.7187 4.01195ZM11.6258 6.33322L9.99956 4.70698L4.37335 10.3332L5.99958 11.9595L11.6258 6.33322ZM3.45604 11.2505L3.66624 11.0403L5.23361 12.6077L3.00593 13.276L3.45604 11.2505ZM12.1431 2.14719C12.6745 2.06801 13.235 2.183 13.691 2.65113C14.144 3.11618 14.2515 3.67673 14.1709 4.20577C14.0937 4.71203 13.8463 5.19634 13.5832 5.59618C13.0867 6.35057 12.4407 6.94247 12.3431 7.03019L6.03163 13.3416C5.96532 13.4079 5.88359 13.4567 5.79377 13.4837L2.58543 14.4462C2.17085 14.5706 1.77554 14.203 1.86943 13.7805L2.50722 10.9104C2.5306 10.8052 2.5835 10.7089 2.6597 10.6327L9.30274 3.98962C9.39049 3.89204 9.99024 3.23795 10.7488 2.73726C11.1502 2.47228 11.6366 2.22265 12.1431 2.14719ZM9.31536 3.97709L9.31873 3.97379L9.31708 3.97538L9.31536 3.97709ZM12.3555 7.01761L12.3572 7.01595L12.3589 7.01419L12.3555 7.01761Z"
        fill="currentColor"
      />
    </svg>
  );
};
