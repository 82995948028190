import { createAsyncThunk } from "@reduxjs/toolkit";
import { SendFeedbackArg } from "../../assets/interfaces/interfaces";
import axios from "axios";

// Define the async thunk
export const sendFeedback = createAsyncThunk(
  "chat/fetchChangeTitle",
  async (arg: SendFeedbackArg, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_SPL_URL}/tradegpt-post-feedback`, arg, {
        withCredentials: true,
      });
      return data;
    }
    catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);
