import { Tooltip } from "@mui/material";
import { useMemo, useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { MessageInputProps, RootState } from "../assets/interfaces/interfaces";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SuggestionPrompts from "./SuggestionPrompts";
import StopIcon from "@mui/icons-material/Stop";
import { getAuth } from "firebase/auth";

// Component for the user to input their messages
const MessageInput: React.FC<MessageInputProps> = ({
  sessionId,
  handleSendMessage,
  setOpen,
  setMessages,
  setFlow,
  setSignInModalOpen,
  handleCancel,
  showPrompts,
  handlePromptSelect,
}) => {
  const [inputValue, setInputValue] = useState("");
  const messageTextArea = useRef<HTMLTextAreaElement>(null);
  const messageButton = useRef<HTMLButtonElement>(null);
  const finchatMessage = useSelector(
    (state: RootState) => state?.finchat?.finchatMessages?.[sessionId],
  );
  const auth = getAuth();
  const isUserSignedIn = useMemo(() => {
    return !!auth?.currentUser;
  }, [auth?.currentUser]);

  const finchatmessageLoading = finchatMessage?.outputLoading;

  const handleSend = () => {
    if (!finchatmessageLoading && isUserSignedIn) {
      handleSendMessage(inputValue, "user_type");
      setInputValue("");
      if (messageTextArea.current) {
        messageTextArea.current.style.height = "auto";
      }
      if (messageButton.current) {
        messageButton.current.classList.remove("bottom-1.5", "md:bottom-3");
      }
    }
    else {
      if (!finchatMessage?.outputLoading) setOpen(true);
    }
  };

  const handleAutoHeight = () => {
    if (messageButton.current && messageTextArea.current) {
      messageTextArea.current.style.height = "auto"; // Reset height to auto to calculate new height

      const computedStyle = window.getComputedStyle(messageTextArea.current);
      const maxHeightValue = computedStyle.getPropertyValue("max-height");
      const numericMaxHeight = parseFloat(maxHeightValue.replace("px", ""));

      const lineHeight = parseFloat(
        getComputedStyle(messageTextArea.current).lineHeight,
      );
      const textareaHeight = messageTextArea.current.scrollHeight;
      const calculatedNumberOfLines = Math.ceil(textareaHeight / lineHeight);

      messageTextArea.current.style.height = `${Math.min(
        textareaHeight,
        numericMaxHeight,
      )}px`;
      if (textareaHeight >= numericMaxHeight) {
        messageTextArea.current.style.overflowY = "auto";
      }
      if (calculatedNumberOfLines > 1) {
        messageButton.current.classList.remove(
          "bottom-1/2",
          "transform",
          "translate-y-1/2",
        );
        messageButton.current.classList.add("bottom-1.5", "md:bottom-3"); // middle align
      }
      else {
        messageButton.current.classList.add(
          "bottom-1/2",
          "transform",
          "translate-y-1/2",
        );
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent default action (new line) when Enter is pressed without Shift
      if (!finchatmessageLoading && isUserSignedIn) {
        handleSendMessage(inputValue, "user_type");
        setInputValue("");
        if (messageTextArea.current) {
          messageTextArea.current.style.height = "auto";
        }
      }
      else {
        if (!isUserSignedIn) {
          setMessages({});
          setFlow(0);
          setSignInModalOpen(true);
        }
      }
    }
  };

  useEffect(() => {
    if (inputValue === "" && messageTextArea.current) {
      messageTextArea.current.style.height = "auto"; // Reset height to auto to ensure it shrinks back
    }
  }, [inputValue]);

  const { status } = useSelector((state: { plan }) => state.plan);
  const authLoading = useSelector((state: { auth }) => state.auth.loading);
  const disableSend = !inputValue.trim().length || authLoading || status !== "succeeded" || finchatmessageLoading;
  return (
    <div className="w-full pt-2 md:pt-0 dark:border-white/20 md:border-transparent md:dark:border-transparent md:w-[calc(100%-.5rem)]">
      <form className="stretch mx-2 flex flex-row gap-3 last:mb-2 md:mx-4 md:last:mb-6 lg:mx-auto lg:max-w-2xl xl:max-w-3xl">
        <div className="relative flex h-full flex-1 items-stretch md:flex-col">
          {showPrompts && (
            <SuggestionPrompts onPromptSelect={handlePromptSelect} />
          )}
          <div className="flex w-full items-center">
            <div className="overflow-hidden [&:has(textarea:focus)]:border-token-border-xheavy [&:has(textarea:focus)]:shadow-[0_2px_6px_rgba(0,0,0,.05)] flex flex-col w-full dark:border-token-border-heavy flex-grow relative border border-token-border-heavy dark:text-white rounded-2xl bg-white dark:bg-gray-800 shadow-[0_0_0_2px_rgba(255,255,255,0.95)] dark:shadow-[0_0_0_2px_rgba(52,53,65,0.95)]">
              <textarea
                ref={messageTextArea}
                value={inputValue}
                rows={1}
                onChange={(e) => {
                  handleAutoHeight();
                  setInputValue(e.target.value);
                }}
                onKeyPress={handleKeyPress}
                tabIndex={0}
                placeholder="Type your message…"
                className="m-0 w-full resize-none border-0 bg-transparent py-[10px] pr-10 focus:ring-0 focus-visible:ring-0 focus-visible:ring-transparent dark:bg-transparent md:py-3.5 md:pr-12 placeholder-black/50 dark:placeholder-white/50 pl-3 md:pl-4 scroll-container max-h-52"
                // disabled={finchatmessageLoading}
              >
              </textarea>
              {finchatMessage?.outputLoading
                ? (
                    <div className="absolute bottom-1/2 transform translate-y-1/2 right-2 md:right-3">
                      <div className="flex h-full">
                        <div className="flex h-full flex-row items-center justify-center gap-3">
                          <button
                            type="button"
                            onClick={() => handleCancel()}
                            className="rounded-full border-2 border-gray-950 p-0.5 dark:border-gray-200 leading-0"
                            aria-label="Stop generating"
                          >
                            <StopIcon className="!w-4 !h-4" />
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                : (
                    <button
                      ref={messageButton}
                      type="button"
                      disabled={disableSend}
                      onClick={handleSend}
                      className="absolute bottom-1/2 transform translate-y-1/2 right-2 md:right-3 dark:hover:bg-white  bg-black disabled:opacity-10 disabled:text-gray-400 text-white p-0.5 border border-black rounded-lg dark:border-white dark:bg-white transition-colors leading-none"
                    >
                      <Tooltip title="Send Message">
                        <ArrowUpwardIcon className="!w-6 !h-6 text-white dark:text-black" />
                      </Tooltip>
                    </button>
                  )}
            </div>
          </div>
        </div>
      </form>
      <div className="relative px-2 py-2 text-center text-xs text-token-text-secondary md:px-[60px]">
        <span>
          TradeGPT is for informational purposes only. Consult a professional
          before making investment decisions.
        </span>
      </div>
    </div>
  );
};

export default MessageInput;
