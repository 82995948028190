import Modal from "@mui/material/Modal";
import { Button, IconButton } from "@mui/material";
import { BrokenBot } from "./Plans/icons/BrokenBot/BrokenBot";
import { CrossIcon } from "./Plans/icons/GPTIcons/CrossIcon";

export default function LimitModal({
  open,
  setOpen,
  onUpgrade,
  name,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onUpgrade: () => void;
  name: string;
}) {
  const handleClose = () => setOpen(false);
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="limit-modal">
      <div className="fixed inset-0 flex items-center justify-center">
        <div className="shadow w-modal-mobile sm:w-modal-md rounded-lg p-6 bg-white text-mui-black-87 dark:bg-background/paper-elevation-16 dark:text-white">
          {/* top section */}
          <div className="flex flex-col gap-y-6 relative">
            <div className="flex flex-col gap-y-4 items-center justify-center">
              <BrokenBot className="fill-black dark:fill-white" />
              <h6 className="font-medium">
                Hello
                {" "}
                {name}
                ,
              </h6>
              <IconButton
                size="small"
                onClick={handleClose}
                className="cross-icon"
                sx={{ position: "absolute", top: 0, right: 0 }}
              >
                <CrossIcon className="fill-current" />
              </IconButton>
              <p className="body2 text-center">
                We noticed that you reached the limit for your account. Please
                upgrade your plan to ensure you can continue using TradeGPT
                without interruption.
              </p>
            </div>
            {/* upgrade button */}
            <div className="flex justify-center">
              <Button
                onClick={onUpgrade}
                className="button-primary"
                variant="contained"
                color="primary"
                size="large"
              >
                Upgrade now
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
