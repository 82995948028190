import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { DeleteAllChatSessionsArg, DeleteSessionsState } from "../../assets/interfaces/interfaces";
import { fetchPastChats } from "./pastChatsSlice";

const initialState: DeleteSessionsState = {
  status: "idle",
  error: null,
};

// Define the async thunk
export const fetchDeleteAllSessionsSlice = createAsyncThunk(
  "chat/fetchDeleteAllSessionsSlice",
  async (arg: DeleteAllChatSessionsArg, { dispatch, rejectWithValue }) => {
    try {
      const { userId } = arg;
      const { data } = await axios.delete(`${process.env.REACT_APP_SPL_URL}/tradegpt-delete-user-chats/${arg.userId}`, { withCredentials: true });
      dispatch(fetchPastChats({ userId }));
      return data;
    }
    catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

// Create the slice
export const deleteAllSessionsSlice = createSlice({
  name: "delete-all-sessions",
  initialState,
  reducers: {
    // standard reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeleteAllSessionsSlice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDeleteAllSessionsSlice.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(fetchDeleteAllSessionsSlice.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch messages";
      });
  },
});

// Export the reducer from the slice
export default deleteAllSessionsSlice.reducer;
