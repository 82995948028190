import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    "border": 0,
    "width": "200px",
    "height": "40px",
    "textTransform": "none",
    "fontFamily": "Inter !important",
    "fontSize": "13px",
    "fontStyle": "normal",
    "fontWeight": 600,
    "letterSpacing": "-0.08px",
    "color": "black",
    "backgroundColor": "#EEEEEF",
    "&:hover": {
      backgroundColor: "#EEEEEF",
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&.Mui-selected": {
      background: "#1C1C1E",
      boxShadow: "0px 3px 1px 0px rgba(0, 0, 0, 0.04), 0px 3px 8px 0px rgba(0, 0, 0, 0.12)",
      color: "white",
      borderRadius: "8px !important",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "#1C1C1E",
      color: "white",
      boxShadow: "none",
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)(() => ({
  // Add your styling here to match the design in your image
  "&.MuiButtonBase-root": {
    display: "flex",
    justifyContent: "flex-start !important",
    paddingLeft: "18px",
  },
}));

const SavingsBadge = styled(Badge)(() => ({
  // Add your styling here for the savings badge
  "& .MuiBadge-badge": {
    width: "91px !important",
    height: "20px",
    whiteSpace: "nowrap",
    background: "#34C759",
    color: "#1C1C1E",
    fontFamily: "Inter",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 600,
  },
  "&.MuiBadge-root": {
    position: "relative",
    left: "62px",
  },
}));

function PricingToggle({ pricingPlan, setPricingPlan }) {
  const handlePricingChange = (e, newPricing) => {
    console.log({ newPricing });
    if (pricingPlan && newPricing === "monthly") {
      setPricingPlan(false);
    }
    else if (!pricingPlan && newPricing === "annually") {
      setPricingPlan(true);
    }
  };

  return (
    <StyledToggleButtonGroup
      value={pricingPlan ? "annually" : "monthly"}
      exclusive
      onChange={handlePricingChange}
      aria-label="pricing plan"
    >
      <ToggleButton value="monthly" aria-label="monthly">
        Monthly
      </ToggleButton>
      <StyledToggleButton value="annually" aria-label="annually">
        Annually
        <SavingsBadge badgeContent="save 15% more" color="primary">
        </SavingsBadge>
      </StyledToggleButton>
    </StyledToggleButtonGroup>
  );
}

export default PricingToggle;
