import { List, ListItem, ListItemText } from "@mui/material";
import TermsAndPoliciesLayout from "./components/TermsAndPoliciesLayout";

const listItemPrimaryTypography = {
  style: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#1C1C1E",
  },
};

const listItemSecondaryTypography = {
  style: {
    fontSize: 18,
    color: "#1C1C1E",
  },
};

export default function PrivacyPolicy() {
  return (
    <TermsAndPoliciesLayout type="privacy" headerTitle="Privacy Policy">
      <p className="font-bold">Effective Date: 30 January 2024</p>
      Welcome to the Privacy Policy for TradeGPT. This document serves as a
      legally binding agreement between you, the user, and TA Fintech Inc, the
      entity responsible for the operation and administration of TradeGPT. This
      Privacy Policy outlines the way we collect, utilize, disclose, and
      safeguard your information when you engage with our chatbot. By accessing
      or using TradeGPT, you expressly consent to the terms set forth herein.
      <br />
      <br />
      <p className="font-bold text-[28px]">1) Information We Collect:</p>
      <List sx={{ p: 0 }}>
        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• User Input Data:"
            secondary=" In our commitment to continually enhance the functionality of
                TradeGPT, we diligently gather and retain the messages and
                inputs voluntarily supplied by you. These inputs play a pivotal
                role in the iterative refinement of our service, contributing
                significantly to the augmentation of your interactive
                experience. By accumulating and analyzing the data derived from
                your inputs, we strive to tailor TradeGPT to better comprehend
                and respond to your needs and queries over time. It is important
                to note that the data collected in this category is handled with
                the utmost confidentiality and is not shared with external
                parties, preserving the privacy and security of your
                interactions."
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• Usage Data:"
            secondary={(
              <>
                As you engage with TradeGPT, we may, in accordance with our
                commitment to transparency and user empowerment, collect
                specific usage data to enhance the overall quality of our
                service. This encompasses information related to your
                interaction patterns, encompassing timestamps, frequency of
                engagement, and the specific features of TradeGPT that you
                utilize. It is imperative to underscore that this data is
                subjected to a rigorous anonymization process, ensuring that any
                personally identifiable information is meticulously dissociated.
                <br />
                <br />
                The sole purpose of collecting usage data is to derive insights
                into the broader usage trends and patterns, ultimately
                facilitating strategic improvements to TradeGPT. This data is
                exclusively utilized for internal analytical purposes,
                contributing to the optimization of user experience and ensuring
                that our service aligns seamlessly with your preferences.
                <br />
                <br />
                We recognize the sensitivity of the information shared during
                your interactions with TradeGPT, and as such, we are steadfast
                in our commitment to upholding the highest standards of data
                privacy and security. Your trust is paramount, and we employ
                robust measures to safeguard the data entrusted to us,
                maintaining the confidentiality and integrity of your experience
                with TradeGPT.
              </>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">2) How We Use Your Information:</p>
      <List sx={{ p: 0 }}>
        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• Enhancing TradeGPT:"
            secondary="The data we collect plays a pivotal role in the ongoing
                  refinement of the functionality and performance of TradeGPT. By
                  leveraging insights gleaned from the collected information, we
                  implement iterative enhancements that contribute to the
                  optimization of TradeGPT. This iterative process is fundamental
                  to ensuring that our chatbot evolves to meet the dynamic needs
                  and expectations of our users. The collected data serves as a
                  valuable resource, guiding our development efforts towards
                  delivering an experience that is not only efficient and
                  effective but also attuned to the evolving landscape of user
                  preferences and expectations."
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• Personalization:"
            secondary="At TradeGPT, we understand the importance of a personalized and
                  tailored user experience. To achieve this, we utilize aggregated
                  and anonymized data to customize TradeGPT in harmony with
                  individual preferences. This personalized approach enables us to
                  provide you with a more intuitive and relevant interaction,
                  tailoring responses and suggestions to align seamlessly with your
                  unique needs and preferences. It is essential to note that the
                  personalization process is executed with a meticulous commitment
                  to user privacy, as the data used for customization is rigorously
                  anonymized, ensuring that individual identities and sensitive
                  information remain safeguarded. Our objective is to elevate your
                  experience with TradeGPT, making each interaction not only
                  efficient but also distinctly tailored to your preferences."
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">3) Data Security:</p>
      <List sx={{ p: 0 }}>
        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• Encryption:"
            secondary="At TradeGPT, the sanctity and confidentiality of your data are
                  paramount. To fortify this commitment, we implement
                  industry-standard security protocols that incorporate robust
                  encryption mechanisms. This rigorous encryption is applied both
                  during the transmission and storage of your data, ensuring that
                  sensitive information remains secure and impervious to
                  unauthorized access. The use of advanced encryption technology
                  is a cornerstone of our approach to data security, reflecting
                  our dedication to maintaining the highest standards of
                  confidentiality and safeguarding your information against
                  potential threats."
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• Access Controls:"
            secondary="Your trust is of utmost importance to us, and as such, access to
                  your information is rigorously controlled and restricted solely
                  to authorized personnel. Our access controls are meticulously
                  designed to ensure that only individuals with the requisite
                  authorization can access and handle your data. Periodic reviews
                  of our security protocols are conducted to guarantee the ongoing
                  integrity and effectiveness of our access controls. This
                  commitment to stringent access management aligns with our
                  overarching objective of preserving the privacy and security of
                  your data throughout its lifecycle. Rest assured that your
                  information is handled with the utmost care, and every effort is
                  made to prevent unauthorized access or misuse."
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">4) Information Sharing:</p>
      <List sx={{ p: 0 }}>
        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• Third-Party Integration:"
            secondary="In our pursuit of enhancing the functionality of TradeGPT, we may
                  integrate with third-party platforms or services. This integration
                  is undertaken with the explicit goal of augmenting the
                  capabilities and features of TradeGPT, providing you with a more
                  comprehensive and enriched experience. It is imperative to note
                  that when utilizing third-party integrations, you are encouraged
                  to review and familiarize yourself with the privacy policies of
                  these external entities. The data handling practices of these
                  third-party platforms are governed by their respective policies,
                  and we advocate for your informed understanding of how your
                  information may be processed or utilized when interacting with
                  TradeGPT in conjunction with these integrations."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• Legal Compliance:"
            secondary="While we steadfastly uphold the principles of data privacy, it is
                  important to acknowledge that disclosure of your information may
                  be necessitated by legal requirements or legitimate legal
                  requests. In the event of such mandates, TradeGPT will adhere to
                  applicable laws and regulations, disclosing the requisite
                  information to comply with legal obligations. We assure you that
                  any such disclosures will be made in accordance with legal
                  frameworks, and every effort will be undertaken to limit the scope
                  of disclosure to what is strictly necessary under the prevailing
                  legal circumstances. Your privacy remains a paramount
                  consideration, and we are committed to transparency and adherence
                  to the law in all matters related to information sharing."
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">5) Disclosure Clause:</p>
      We are committed to transparency regarding the handling and disclosure of
      your information. This disclosure clause elucidates the circumstances
      under which your information may be shared or disclosed.
      <br />
      <List sx={{ p: 0 }}>
        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• Third-Party Integration:"
            secondary="TradeGPT may integrate with third-party platforms or services to
                  enhance its functionality. In the process of such integration,
                  limited information may be shared with these third parties to
                  facilitate a seamless user experience. It is imperative to
                  understand that the data shared is confined to what is strictly
                  necessary for collaborative functionality. We strongly advise
                  reviewing the privacy policies of these third-party entities to
                  comprehend their data handling practices, ensuring a
                  comprehensive understanding of how your information may be
                  utilized in conjunction with TradeGPT."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• Legal Compliance:"
            secondary="In adherence to applicable laws and regulations, TradeGPT may
                  disclose your information if mandated by law or in response to
                  legitimate legal requests. Such disclosure will be made in
                  strict accordance with legal frameworks, and every effort will
                  be undertaken to limit the scope of disclosure to what is
                  essential under the prevailing legal circumstances. We are
                  committed to maintaining the highest standards of legal
                  compliance while safeguarding your privacy."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• User Opt-Out:"
            secondary="You retain the prerogative to abstain from providing certain
                  information or opting out of data collection within the purview
                  of TradeGPT. However, it is essential to be cognizant that
                  opting out or withholding certain information may have
                  implications on the full functionality of TradeGPT. Certain
                  features and personalized experiences may be contingent upon the
                  availability of specific data inputs."
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">6) Data Retention:</p>
      <List sx={{ p: 0 }}>
        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• User Input Data:"
            secondary="User input data, comprising messages and interactions provided
                  to TradeGPT, will be retained for a limited duration. We
                  acknowledge the importance of data privacy and adhere to a
                  prudent data retention policy. Specifically:"
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• Duration:"
            secondary="User input data will be stored
                  for a period deemed necessary for the improvement and
                  optimization of TradeGPT's functionality and performance."
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• Anonymization:"
            secondary="While retained, efforts will be made to dissociate user input
                  data from personally identifiable information. Aggregated and
                  anonymized data may be used for analytical purposes beyond the
                  retention period."
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• Usage Data:"
            secondary="Data related to your interaction patterns, including timestamps,
                  frequency of engagement, and specific features utilized, will be
                  retained in a manner that safeguards your privacy and aligns
                  with our commitment to data security. Specifically:"
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• Duration:"
            secondary="Usage data will be stored for a period necessary to derive
                  meaningful insights into user behavior and preferences,
                  contributing to the continuous enhancement of TradeGPT."
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• Anonymization:"
            secondary="Usage data will undergo a stringent anonymization process,
                  ensuring that personally identifiable information is
                  dissociated from the stored data. Aggregated, anonymized data
                  will be used exclusively for internal analytical purposes."
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="Third-Party Integration Data:"
            secondary="In instances where TradeGPT integrates with third-party
                  platforms or services, limited data may be shared to facilitate
                  collaboration. Specifically:"
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• Duration:"
            secondary="Data shared with
                  third parties will be limited to what is essential for the
                  collaborative functionality and will be retained for the
                  duration required for seamless integration."
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• Third-Party Policies:"
            secondary="We advise
                  reviewing the privacy policies of third-party entities to
                  understand how your information may be handled beyond our
                  platform."
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• Legal Compliance:"
            secondary="In compliance with applicable laws and regulations, TradeGPT may
                  retain user data for a period necessary to fulfill legal
                  obligations. Specifically:"
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• Duration:"
            secondary="User data may be
                  retained as required by law or in response to legitimate legal
                  requests."
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• Scope:"
            secondary="The retention of data
                  under legal compliance will be limited to what is strictly
                  necessary under the prevailing legal circumstances."
          />
        </ListItem>
      </List>
      <p>
        For any inquiries or requests related to data retention, please contact
        us at
        {" "}
        <a
          className="underline text-[#007BFF]"
          href="mailto:support@tradealgo.com"
        >
          support@tradealgo.com
        </a>
        . Your privacy is integral to our commitment, and we strive to balance
        effective data usage with responsible and secure data management
        practices.
      </p>
      <br />
      <p className="font-bold text-[28px]">7) Your Choices:</p>
      <List sx={{ p: 0 }}>
        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• Opt-Out:"
            secondary=" We acknowledge and respect your autonomy over your personal
                  information. You retain the prerogative to abstain from
                  providing certain information or opting out of data collection
                  within the purview of TradeGPT. However, it is essential to be
                  cognizant that opting out or withholding certain information may
                  have implications on the full functionality of TradeGPT. Certain
                  features and personalized experiences may be contingent upon the
                  availability of specific data inputs. We encourage you to
                  consider the trade-off between privacy preferences and the
                  tailored functionality that TradeGPT can offer."
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• Review and Update:"
            secondary="To empower you with control and transparency over your data,
                  TradeGPT provides clear and accessible procedures for reviewing
                  and updating your information. We encourage you to periodically
                  review and update your information to ensure its accuracy and
                  relevance. Your active participation in this process contributes
                  to maintaining the precision of the data TradeGPT utilizes,
                  aligning it more closely with your evolving preferences and
                  needs. We are committed to facilitating a user-friendly process
                  for you to manage and maintain control over your information
                  within the framework of TradeGPT."
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">8) Updates to the Privacy Policy:</p>
      <List sx={{ p: 0 }}>
        <ListItem>
          <ListItemText
            primaryTypographyProps={listItemPrimaryTypography}
            secondaryTypographyProps={listItemSecondaryTypography}
            primary="• Periodic Revisions:"
            secondary="This Privacy Policy is subject to periodic revisions to ensure
                  its alignment with evolving legal standards, technological
                  advancements, and our commitment to your privacy. It is
                  incumbent upon you to routinely review this policy for any
                  amendments. By using TradeGPT, you acknowledge and agree that it
                  is your responsibility to stay informed about any updates to
                  this Privacy Policy. Continued use of TradeGPT after
                  modifications signifies your acceptance of the updated policy."
          />
        </ListItem>
      </List>
      <br />
      <br />
      <p className="font-bold text-[28px]">Contact Us:</p>
      <br />
      If you have any inquiries or concerns regarding this Privacy Policy, we
      encourage you to reach out to us. Your privacy and satisfaction are
      paramount to us. Please direct your correspondence to
      {" "}
      <a
        className="underline text-[#007BFF]"
        href="mailto:support@tradealgo.com"
      >
        support@tradealgo.com
      </a>
      . Our dedicated team is committed to addressing any queries or concerns
      promptly and effectively.
      <br />
      <br />
      <p className="font-bold text-[28px]">User Agreement:</p>
      <br />
      Your continued utilization of TradeGPT implies your unequivocal agreement
      to the stipulations articulated in this Privacy Policy. We value your
      trust and confidence in entrusting TradeGPT with your information. Your
      ongoing usage signifies your consent to abide by the principles and
      practices outlined herein. Thank you for choosing TradeGPT and for
      entrusting us with your information. We remain dedicated to providing a
      secure and transparent experience in line with the highest standards of
      privacy protection.
    </TermsAndPoliciesLayout>
  );
}
