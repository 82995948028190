import React from "react";
import TradeDark from "../assets/images/finchatGPT/TradeLogo-D.png";
import TradeDarkCircle from "../assets/images/finchatGPT/TradeDark-Circle.png";
import { v4 as uuidv4 } from "uuid";

interface ErrorBoundaryState {
  hasError: boolean;
}

interface ErrorBoundaryProps {
  children?: React.ReactNode;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    console.error("Uncaught error:", error, info);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col min-h-screen w-full bg-white">
          <div className="p-4 text-right">
            <a href={`/chat/${uuidv4()}`}>
              <img src={TradeDark} alt="Trade Logo" className="inline-block w-6 h-6" />
              <span className="align-middle text-xl font-bold ml-2">TradeGPT</span>
            </a>
          </div>
          <div className="flex-grow flex items-center justify-center">
            <div className="text-center mb-60">
              <div className="inline-flex items-center justify-center p-2">
                <img src={TradeDarkCircle} alt="Trade Logo" className="w-16 h-16" />
              </div>
              <h1 className="text-4xl font-bold text-gray-900 mt-2">Something Went Wrong.</h1>
              <p className="mt-3 text-lg text-black">
                There was a problem processing your request.
                <br />
                Please try again or return to the
                {" "}
                <a href={`/chat/${uuidv4()}`} className="text-blue-600 underline">Home Page</a>
                .
              </p>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
