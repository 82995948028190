import { PayloadAction } from "@reduxjs/toolkit";
import { SIGN_OUT_REQUEST, CLEAR_USER, SET_AUTH_LOADING, SET_USER, SIGN_OUT_FAILURE, SIGN_OUT_SUCCESS } from "../../components/auth/authActions";

const initialState = {
  currentUser: null,
  loading: true,
  error: null,
  signOutLoading: false, // This will specifically track the loading state of signing out
  authLoading: true,
};

export const authReducer = (state = initialState, action: PayloadAction) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case CLEAR_USER:
      return {
        ...state,
        currentUser: null,
      };
    case SET_AUTH_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SIGN_OUT_REQUEST: // Handle sign-out request by setting signOutLoading to true
      return {
        ...state,
        signOutLoading: true,
      };
    case SIGN_OUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
        authLoading: false,
        signOutLoading: false, // Sign-out successful, so stop loading
      };
    case SIGN_OUT_FAILURE:
      return {
        ...state,
        currentUser: null,
        authLoading: false,
        error: action.payload,
        signOutLoading: false,
      };
    default:
      return state;
  }
};
