export default function TermsAndPoliciesHeader({ title }: { title: string }) {
  return (
    <div className="space-y-20">
      <div className="text-[73px] text-[#1C1C1E] font-['Inter'] font-normal leading-[88px]">
        {title}
      </div>

      <div className="w-full h-[1px] bg-[#202123]" />
    </div>
  );
}
