export const IconsIconGraphFilled = ({ color = "white", className }: { color: string; className: string }) => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17 4V20H22V4H17ZM10 8V20H15V8H10ZM3 12V20H8V12H3Z" fill={color} />
    </svg>
  );
};
