import { FormEvent, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { CircularProgress, Typography } from "@mui/material";
import TradeDark from "../../assets/images/finchatGPT/TradeLogo-D.png";
import NotificationSnackbar from "../Snackbar";
import { useSelector } from "react-redux";
import {
  hideNotification,
  showNotification,
} from "../../redux/slices/notificationSlice";
import _ from "lodash";
import { getAuth, getIdToken } from "firebase/auth";
import { createCustomTokenForMobileApp, urlIsAppScheme } from "./helpers";
import { fetchToken } from "../../redux/slices/fetchAuthToken";
import SessionDefaultSignIn from "./components/SessionDefaultSignIn";
import SessionSignUpPage from "./components/SessionSignUpPage";
import SessionLoggedInUser from "./components/SessionLoggedInUser";
import { useAppDispatch } from "../../redux/store";
import { doSignOutClear } from "../auth/helpers";

const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const errors = { email: "" };
  let isValid = true;

  if (!email) {
    errors.email = "Email is required.";
    isValid = false;
  }
  else if (!emailRegex.test(email)) {
    errors.email = "Please enter a valid email address.";
    isValid = false;
  }

  return { valid: isValid, errors };
};

export default function Session() {
  const dispatch = useAppDispatch();
  const firebaseUser = getAuth();

  const user = useSelector((state: { auth }) => state.auth.currentUser);
  const notification = useSelector(
    (state: { notification }) => state.notification,
  );
  const authLoading = useSelector((state: { auth }) => state.auth.loading);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // User not logged in
  const [email, setEmail] = useState<string>("");
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [emailError, setEmailError] = useState<string>("");

  // We have selected user, lets log in and redirect
  const handleUserAuthenticationAndRedirect = async () => {
    setIsLoading(true);
    getIdToken(firebaseUser.currentUser, true).then((token) => {
      // Lets get the query params and see if it coming from the app
      const redirect = window.location.search.split("redirect_uri=")[1] ?? "/";
      const isMobileApp = urlIsAppScheme(redirect);

      if (!isMobileApp) {
        setIsLoading(false);
        return;
      }
      // If it was an in-app browser call, we will get the token on the response.
      dispatch(
        fetchToken({
          token,
        }),
      )
        .then(() => createCustomTokenForMobileApp(redirect))
        .catch(() => {
          // If there was an error for some reason, lets log them out
          dispatch(
            showNotification({
              severity: "error",
              horizontal: "center",
              message:
                "Something went wrong while getting your data. Sign again!",
            }),
          );
          handleSignOut();
        })
        .finally(() => setIsLoading(false));
    });
  };

  // User choose another account, lets sign out
  const handleSignOut = () => {
    doSignOutClear(dispatch);
  };

  const handleClose = () => {
    setCurrentStep(0);
  };

  const handleSnackbarClose = () => {
    dispatch(hideNotification());
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    const { valid, errors } = validateEmail(email);
    // Update error states
    setEmailError(errors.email);
    if (!valid) return;
    setCurrentStep(1);
  };

  useEffect(() => {
    if (window.location.search.includes("signup")) setCurrentStep(2);
  }, []);

  useEffect(() => {
    setIsLoading(authLoading);
  }, [authLoading]);

  return (
    <>
      <div className="flex flex-col w-full border h-screen bg-white">
        {/* Header */}
        <header className="sticky top-0 right-0 left-0 flex z-10 bg-white">
          <div className="p-6 flex items-center">
            <img
              src={TradeDark}
              alt="Trade Logo"
              className="inline-block w-6 h-6"
            />
            <span className="align-middle text-xl font-bold ml-2">
              TradeGPT
            </span>
          </div>
        </header>

        {isLoading && (
          <div className="flex gap-y-10 flex-col items-center justify-center w-full px-6 h-full">
            <CircularProgress />
          </div>
        )}

        {!isLoading && (
          <>
            {user && (
              <SessionLoggedInUser
                user={user}
                handleCurrentUser={handleUserAuthenticationAndRedirect}
                handleSignOut={handleSignOut}
              />
            )}
            {!user && (
              <div className="flex flex-col items-center h-full">
                {/* Conent */}
                {currentStep === 0 && (
                  <div className="w-full h-full items-center bg-white rounded-[10px]">
                    {/* Welcome back text */}
                    <div className="flex flex-col justify-start items-center pt-8">
                      <span className="text-[#1C1C1E] text-center custom-font text-[28px] font-bold leading-[30px]">
                        Welcome Back
                      </span>
                    </div>
                    <form
                      className="py-10 flex flex-col justify-start items-center gap-6"
                      onSubmit={handleSubmit}
                    >
                      {/* Input field and primary action button */}
                      <TextField
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        label="Email"
                        variant="outlined"
                        className="w-[295px] mb-4"
                        error={!!emailError}
                        helperText={emailError}
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        className="w-[295px] h-[50px] text-white font-inter text-[16px] font-medium leading-[30px] tracking-[-0.32px] !normal-case !bg-[#1C1C1E]"
                      >
                        Continue
                      </Button>

                      {/* Sign-up link */}
                      <div className="flex flex-col justify-start items-center gap-4">
                        <Typography
                          variant="body2"
                          className="text-center text-zinc-900 text-sm"
                        >
                          Don’t have an account?
                          {" "}
                          <span
                            onClick={() => setCurrentStep(2)}
                            className="cursor-pointer text-blue-600"
                          >
                            Sign up here
                          </span>
                        </Typography>
                      </div>
                    </form>
                  </div>
                )}

                {currentStep === 1 && (
                  <div className="-mt-14">
                    <SessionDefaultSignIn
                      emailValue={email}
                      setFlow={setCurrentStep}
                      onSuccess={handleUserAuthenticationAndRedirect}
                    />
                  </div>
                )}

                {currentStep === 2 && (
                  <SessionSignUpPage
                    handleClose={handleClose}
                    setFlow={setCurrentStep}
                    onSuccess={handleUserAuthenticationAndRedirect}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
      <NotificationSnackbar
        open={notification.open}
        onClose={handleSnackbarClose}
        message={_.capitalize(notification.message)}
        severity={notification.severity}
        horizontal={notification.horizontal}
      />
    </>
  );
}
