export const FireFilledIcon = ({ color = "currentColor", className = "" }: { color?: string; className?: string }) => {
  return (
    <svg
      className={`${className}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2436 3.29488C9.71201 3.47743 9.12748 3.90159 8.56844 4.48025C7.98928 5.07974 7.39128 5.89257 6.85611 6.91437C5.9317 8.67934 5.18817 11.0799 5.05533 14.1157C5.01882 14.4053 5 14.7004 5 15C5 18.866 8.13401 22 12 22C15.866 22 19 18.866 19 15C19 14.625 18.9705 14.2569 18.9137 13.8978L18.9135 13.8956C18.3981 7.7065 15.9544 5.34009 15.0573 4.75144C14.7467 4.54762 14.3406 4.60428 14.0962 4.86802C14.0896 4.87225 14.083 4.8766 14.0765 4.88107C13.6258 5.18944 13.3961 5.74493 13.2556 6.23081C13.1086 6.73907 13.0076 7.36602 12.9357 8.062L12.9214 8.0601C12.9027 7.93964 12.8832 7.82164 12.863 7.70606C12.5198 5.74494 11.9433 4.39196 11.046 3.48575C11.0085 3.44598 10.9669 3.41065 10.9222 3.38023C10.8556 3.33466 10.7832 3.30105 10.7081 3.2794C10.5928 3.24597 10.4686 3.23985 10.3453 3.26597C10.3109 3.27317 10.2769 3.28281 10.2436 3.29488ZM12.7528 15.0144C12.9806 15.0728 13.2311 15.2085 13.4707 15.3937C13.7189 15.5855 13.9752 15.8456 14.2045 16.1726C14.6007 16.7374 14.9194 17.5056 14.9763 18.477C14.9919 18.5697 15 18.6641 15 18.76C15 19.9971 13.6569 20.9999 12 20.9999C10.3431 20.9999 9 19.9971 9 18.76C9 18.64 9.01264 18.5222 9.03697 18.4073L9.03705 18.4066C9.25795 16.4261 10.3052 15.6688 10.6897 15.4805C10.8229 15.4152 10.9969 15.4334 11.1016 15.5178L11.1101 15.522C11.3032 15.6206 11.4017 15.7984 11.4619 15.9539C11.5249 16.1165 11.5682 16.3171 11.599 16.5398L11.6051 16.5392C11.6131 16.5007 11.6215 16.4629 11.6301 16.4259C11.7772 15.7984 12.0243 15.3654 12.4089 15.0755C12.4249 15.0627 12.4427 15.0514 12.4619 15.0417C12.4905 15.0271 12.5215 15.0164 12.5537 15.0094C12.6031 14.9987 12.6563 14.9968 12.7092 15.0051C12.7239 15.0074 12.7385 15.0105 12.7528 15.0144Z"
        fill={color}
      />
    </svg>
  );
};
