import { signOutUser } from "./../../components/auth/authFunctions";
import { SIGN_OUT_REQUEST, signOutSuccess, signOutFailure } from "./../../components/auth/authActions";
import { call, put, takeLatest } from "redux-saga/effects";
import Cookies from "universal-cookie";
export const env = process.env["REACT_APP_ENV"];
export const ssoAtStr = `sso_at_${env}`;
export const sapStr = `sso_at_present_${env}`;
export function rmAllCookiesAndStorage() {
  // clear all cookies and storage to avoid any token mixups
  localStorage.clear();
  const cookies = new Cookies();
  // Remove SSO at Cookie
  cookies.remove(ssoAtStr, { path: "/", domain: ".tradealgo.com", secure: true, sameSite: "strict" });
  cookies.remove(sapStr, { path: "/", domain: ".tradealgo.com", secure: true, sameSite: "strict" });
}
function* signOutSaga() {
  try {
    rmAllCookiesAndStorage();
    yield call(signOutUser);
    yield put(signOutSuccess()); // Dispatch success action
  }
  catch (error) {
    console.error("Error during sign out: ", error);
    yield put(signOutFailure(error)); // Dispatch failure action
  }
}

export default function* watchSignOutSaga() {
  yield takeLatest(SIGN_OUT_REQUEST, signOutSaga);
}
