export const MessageStarIcon = ({ color = "currentColor", className = "" }: { color?: string; className?: string }) => {
  return (
    <svg
      className={`${className}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4268 18.6863C17.7849 18.6863 22 15.1468 22 10.8474C22 6.50503 17.5556 3 12 3C6.44445 3 2 6.50503 2 10.8474C2 13.61 3.73721 16.036 6.5679 17.5208C6.70018 17.5899 6.74427 17.7194 6.67373 17.8489C6.32538 18.4092 5.78387 19.0469 5.37748 19.5254C5.20791 19.7251 5.06161 19.8974 4.96297 20.0245C4.61905 20.4561 4.81305 21 5.49206 21C6.55027 21 9.18695 19.869 10.6861 18.8159C10.836 18.7122 10.9594 18.669 11.0829 18.669L11.086 18.6693C11.1996 18.6778 11.3132 18.6863 11.4268 18.6863ZM12.3546 6.76562C12.2974 6.58828 12.1485 6.5 12 6.5C11.8515 6.5 11.7026 6.58828 11.6454 6.76562L10.7904 9.41432L8.1205 9.44752C7.7625 9.45215 7.61423 9.92901 7.90136 10.1523L10.0437 11.8215L9.24787 14.4898C9.14158 14.8476 9.52935 15.1431 9.82165 14.926L12 13.3096L14.1783 14.926C14.4706 15.1431 14.8592 14.8476 14.7521 14.4898L13.9563 11.8215L16.0986 10.1523C16.3858 9.92824 16.2375 9.45138 15.8795 9.44752L13.2096 9.41432L12.3546 6.76562Z"
        fill={color}
      />
    </svg>
  );
};
