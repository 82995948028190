import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { changeSubArg, changeSubState } from "../../assets/interfaces/interfaces";
import { fetchActivePlan } from "./activePlanSlice";

const initialState: changeSubState = {
  checkoutObject: {},
  status: "idle",
  error: null,
};

// Define the async thunk
export const fetchChangeSubscription = createAsyncThunk(
  "sub-update/fetchChangeSubscription",
  async (arg: changeSubArg, { dispatch, rejectWithValue }) => {
    try {
      const { price_id, cancel } = arg;
      let data;
      if (cancel) {
        const res = await axios.post(`${process.env.REACT_APP_SPL_URL}/subscriptions/cancel`, {}, { withCredentials: true });
        data = res.data;
      }
      else {
        const res = await axios.post(`${process.env.REACT_APP_SPL_URL}/subscriptions/checkout-update`, { price_id }, { withCredentials: true });
        data = res.data;
      }
      dispatch(fetchActivePlan());
      return data;
    }
    catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

// Create the slice
export const changeSubscriptionSlice = createSlice({
  name: "sub-update",
  initialState,
  reducers: {
    // standard reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChangeSubscription.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChangeSubscription.fulfilled, (state, action: PayloadAction) => {
        state.status = "succeeded";
        state.checkoutObject = action.payload;
      })
      .addCase(fetchChangeSubscription.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to update subscription";
      });
  },
});

// Export the reducer from the slice
export default changeSubscriptionSlice.reducer;
