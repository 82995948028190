import React, { useEffect, useRef, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  RootState,
  SubmenuItemProps,
} from "../../assets/interfaces/interfaces";
import { ListItemIcon } from "@mui/material";
import { useParams } from "react-router-dom";
import IosShareIcon from "@mui/icons-material/IosShare";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { fetchChangeTitle } from "../../redux/slices/changeTitleSlice";
import { fetchDeleteChat } from "../../redux/slices/deleteChatSlice";
import ConfirmDeleteModal from "./ConfirmDeletemodal";
import SharePreviewModal from "../Share/SharePreviewModal";
import classNames from "classnames";
import useEnhancedNavigate from "../Session/helpers";
import { deleteOptimisticMessage } from "../../redux/slices/pastChatsSlice";
import { useAppDispatch } from "../../redux/store";
const SideMenuItem: React.FC<SubmenuItemProps> = ({
  item,
  clearAllChatData,
}) => {
  const [hover, setHover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [title, setTitle] = useState(item.title);
  const open = Boolean(anchorEl);
  const navigate = useEnhancedNavigate();
  const handleSubmenuClick = (e) => {
    e.stopPropagation(); // This stops the event from bubbling up
    setAnchorEl(e.currentTarget);
  };
  useEffect(() => {
    setTitle(item.title);
  }, [item.title]);
  const { session_id } = useParams();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRename = () => {
    setRename(true);
    handleClose();
  };
  const [rename, setRename] = useState(false);
  const user = useSelector((state: RootState) => state.auth.currentUser);

  const renameInputRef = useRef<HTMLInputElement>(null); // Specify the ref type as HTMLInputElement
  // Set focus to the input when renaming
  useEffect(() => {
    if (rename && renameInputRef.current) {
      renameInputRef?.current.focus();
      renameInputRef?.current.select();
    }
  }, [rename, renameInputRef]);
  const dispatch = useAppDispatch();
  // Function to handle Enter key press
  const { userId } = user;
  const changeTitleReq = () => {
    dispatch(
      fetchChangeTitle({ session_id: item.sessionId, title, userId }),
    );
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      changeTitleReq();
      setRename(false); // Set rename to false when Enter is pressed
      // Additional actions can be added here if needed
    }
    if (e.key === "Escape") {
      setRename(false);
    }
  };

  const handleBlur = () => {
    setRename(false); // Cancel the title change when the input loses focus
    // copy chatgpt if title different dispatch title change
    if (title === item.title) {
      changeTitleReq();
    }
  };
  const handleChatDelete = () => {
    setDeleteModal(false);
    handleClose();
    dispatch(
      fetchDeleteChat({ session_id: item.sessionId, user_id: userId }),
    );
    dispatch(deleteOptimisticMessage(item.sessionId));
    // navigate away from dead chat
    clearAllChatData();
  };
  const [deleteModal, setDeleteModal] = useState(false);

  const openDeleteModal = () => {
    handleClose();
    setDeleteModal(true);
  };

  const [shareModal, setShareModal] = useState(false);

  const openShareModal = () => {
    handleClose();
    setShareModal(true);
  };
  return (
    <li className="relative z-[15]" key={item.sessionId} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <SharePreviewModal
        session_id={item.sessionId}
        open={shareModal}
        handleClose={() => setShareModal(false)}
      />
      <ConfirmDeleteModal
        handleChatDelete={handleChatDelete}
        handleClose={() => setDeleteModal(false)}
        open={deleteModal}
      />
      <Menu
        classes={{
          paper: "!bg-[#2E2E32]",
          list: "mt-2 min-w-[170px] max-w-xs rounded-lg",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItem
          classes={{
            root: "!flex !gap-1  !rounded !text-sm !cursor-pointer !focus:ring-0  !hover:bg-white/5 !radix-disabled:pointer-events-none !radix-disabled:opacity-50 !group !text-white",
          }}
          onClick={openShareModal}
        >
          <ListItemIcon>
            <IosShareIcon className="!w-[19px] text-white relative top-[-2px]" />
          </ListItemIcon>
          Share
        </MenuItem>
        <MenuItem
          classes={{
            root: "!flex !gap-1  !rounded  !text-sm !cursor-pointer !focus:ring-0  !hover:bg-white/5 !radix-disabled:pointer-events-none !radix-disabled:opacity-50 !group !text-white",
          }}
          onClick={handleRename}
        >
          <ListItemIcon>
            <DriveFileRenameOutlineIcon className="!w-[20px] text-white relative top-[-1px]" />
          </ListItemIcon>
          Rename
        </MenuItem>
        <MenuItem
          classes={{
            root: "!flex !gap-1  !rounded  !text-sm !cursor-pointer !focus:ring-0  !hover:bg-white/5 !radix-disabled:pointer-events-none !radix-disabled:opacity-50 !group !text-red-500",
          }}
          onClick={openDeleteModal}
        >
          <ListItemIcon>
            <DeleteIcon className="!w-[20px] !text-red-500 relative top-[-1px]" />
          </ListItemIcon>
          Delete Chat
        </MenuItem>
      </Menu>
      <div className={classNames("group relative rounded-lg active:opacity-90 cursor-pointer", { "bg-token-surface-secondary": item.sessionId === session_id, "hover:bg-token-surface-primary": item.sessionId !== session_id, "bg-token-surface-primary": (item.sessionId !== session_id && hover) })}>
        <a onClick={() => { navigate(`/chat/${item.sessionId}`); }} href={void (0)} className="flex items-center gap-2 p-2">
          <div className="relative grow overflow-hidden whitespace-nowrap">
            {
              rename
                ? (
                    <input
                      onBlur={handleBlur} // Add the onBlur event handler
                      onKeyDown={handleKeyPress}
                      ref={renameInputRef}
                      value={title}
                      onChange={e => setTitle(e.target.value)}
                      className="sidebar-input w-full ring ring-token-surface-secondary bg-transparent p-0"
                    />
                  )
                : (
                    title
                  )
            }
            <div className={classNames("absolute bottom-0 right-0 top-0 bg-gradient-to-l to-transparent", { "from-token-surface-secondary w-20 from-60%": item.sessionId === session_id, "w-8 from-black from-0% group-hover:w-20 group-hover:from-token-surface-primary group-hover:from-60%": item.sessionId !== session_id })}></div>
          </div>
        </a>
        <div className={classNames("absolute bottom-0 right-0 top-0 items-center gap-1.5 pr-2", { "flex": (item.sessionId === session_id) || hover, "hidden group-hover:flex": (item.sessionId !== session_id) && !hover })}>
          <button onClick={handleSubmenuClick} className="relative flex items-center justify-center text-token-text-primary transition hover:text-token-text-secondary" type="button">
            <MoreHorizIcon />
          </button>
        </div>
      </div>
    </li>
  );
};

export default SideMenuItem;
