import React from "react";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import MentorshipProgramIcon from "./Icons/MentorshipProgramIcon";
import OptionsAcademyIcon from "./Icons/OptionsAcademyIcon";
import DarkMarketActivityIcon from "./Icons/DarkMarketActivityIcon";
import OptionGreeksIcon from "./Icons/OptionGreeksIcon";
import AIScannerIcon from "./Icons/AIScannerIcon";
import BreakingNewsIcon from "./Icons/BreakingNewsIcon";
import UnbeatableCoverageIcon from "./Icons/UnbeatableCoverageIcon";
import MarketSignalsIcon from "./Icons/MarketSignalsIcon";

import LogoWithTextLight from "../../assets/images/finchatGPT/logo-with-text-light.png";
import LogoWithTextDark from "../../assets/images/finchatGPT/logo-with-text-dark.png";
import BannerImg from "../../assets/images/finchatGPT/banner.png";
import NewWindowIcon from "../../assets/images/finchatGPT/new-window.png";
import taLogoWhite from "../../assets/images/finchatGPT/taLogo.png";
import taLogoBlack from "../../assets/images/finchatGPT/TradeLogo-D.png";

const Dashboard: React.FC = () => {
  const isDarkMode = useSelector((state: { theme }) => state.theme.darkMode);
  const navigate = useNavigate();

  const productFeatures = [
    {
      icon: <MentorshipProgramIcon fill={isDarkMode ? "white" : "black"} />,
      title: "Mentorship Program",
      description: "Personalized coaching and equipping the users with all the key resources and erudition required to comprehend the essential concepts and rules of options trading.",
    },
    {
      icon: <OptionsAcademyIcon fill={isDarkMode ? "white" : "black"} />,
      title: "Options Academy",
      description: "Learn how to master Options Trading. A structured video-based curriculum with live trading sessions that simplifies the complexities of options trading to help beginner traders go from novice to pro.",
    },
    {
      icon: <DarkMarketActivityIcon fill={isDarkMode ? "white" : "black"} />,
      title: "Dark Market Activity",
      description: "Analyzing various data points of large orders as they hit the tape, including the order type, size, speed of the order, the pattern and volume, among many other indicators.",
    },
    {
      icon: <OptionGreeksIcon fill={isDarkMode ? "white" : "black"} />,
      title: "Option Greeks",
      description: "View the flow of unusual options like never before. Follow the smart money by watching large and unusual trades as they are made. Our options flow uncovers complex trades that are difficult to find anywhere else.",
    },
    {
      icon: <AIScannerIcon fill={isDarkMode ? "white" : "black"} />,
      title: "AI Scanner",
      description: "Options Scanner is a powerful algo that enables real-time scanning of the entire options and equities universe using a wide variety of indicators.",
    },
    {
      icon: <BreakingNewsIcon fill={isDarkMode ? "white" : "black"} />,
      title: "Breaking News",
      description: "With more than 250 data connections in most countries, TradeAlgo’s coverage of companies, markets, economies, politics and governments ensures that you get the information you need.",
    },
    {
      icon: <UnbeatableCoverageIcon fill={isDarkMode ? "white" : "black"} />,
      title: "Unbeatable Coverage",
      description: "With 2 million + instruments encompassing the entireuniverse of US and Europeanoptions and equities we process over 50 billion + events aggregated daily.",
    },
    {
      icon: <MarketSignalsIcon fill={isDarkMode ? "white" : "black"} />,
      title: "Market Signals",
      description: "Signals give traders an edge by instantly notifying them when significant market events occur. Harness our powerful proprietary scripting language and set up a signal for a desired instrument.",
    },
  ];

  return (
    <div className="tradegpt-dashboard-component">
      <div className="tradegpt-dashboard-header">TradeAlgo</div>
      <div className="tradegpt-dashboard-subheader">We build products and offer services that connect people to the investing information and tools they need.</div>

      <div className="tradegpt-dashboard-banner">
        <div className="tradegpt-dashboard-banner-content">
          <img src={isDarkMode ? LogoWithTextLight : LogoWithTextDark} alt="Logo with text" className="tradegpt-dashboard-banner-logo" />
          <div className="tradegpt-dashboard-banner-text">Experience the full potential of TradeAlgo features.</div>
          <Button onClick={() => window.open((process.env.REACT_APP_ENV === "staging" ? "https://stag-frontend.tradealgo.com" : "https://dashboard.tradealgo.com"), "_blank")} variant="contained" className="tradegpt-dashboard-CTA-button">
            <img src={NewWindowIcon} alt="New window" className="tradegpt-dashboard-CTA-icon" />
            Access TradeAlgo
          </Button>
        </div>

        <img src={BannerImg} alt="Banner" className="tradegpt-dashboard-banner-img" />
      </div>

      <div className="tradegpt-dashboard-features">
        <div className="tradegpt-dashboard-features-header">Product Features</div>
        <div className="tradegpt-dashboard-features-content">
          {
            productFeatures.map((feature, index) => (
              <div key={index} className="tradegpt-dashboard-feature">
                <div className="tradegpt-dashboard-feature-icon">{feature.icon}</div>
                <div className="tradegpt-dashboard-feature-title">{feature.title}</div>
                <div className="tradegpt-dashboard-feature-description">{feature.description}</div>
              </div>
            ))
          }
        </div>
      </div>

      <div className="tradegpt-dashboard-footer">
        <div className="tradegpt-dashboard-footer-header">
          <img src={isDarkMode ? taLogoWhite : taLogoBlack} alt="TradeAlgo logo" className="tradegpt-dashboard-footer-logo" />
          TradeGPT
        </div>

        <div className="tradegpt-dashboard-footer-links">
          <div className="tradegpt-dashboard-footer-link" onClick={() => navigate("/policies/service-terms")}>Terms & Policies</div>
          <div className="tradegpt-dashboard-footer-link" onClick={() => navigate("/policies/privacy-policy")}>Privacy Policy</div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
