import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { AllPlanState } from "../../assets/interfaces/interfaces";

const initialState: AllPlanState = {
  plans: [], // Initialized as an empty array
  status: "idle",
  error: null,
};

// Define the async thunk
export const fetchAllPlan = createAsyncThunk(
  "plan/fetchAllPlan",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_SPL_URL}/plans?currency=USD`, { withCredentials: true });
      return data;
    }
    catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

// Create the slice
export const allPlanSlice = createSlice({
  name: "all/plan",
  initialState,
  reducers: {
    // standard reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPlan.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllPlan.fulfilled, (state, action: PayloadAction<unknown[]>) => {
        state.status = "succeeded";
        state.plans = action.payload;
      })
      .addCase(fetchAllPlan.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch active plan";
      });
  },
});

// Export the reducer from the slice
export default allPlanSlice.reducer;
