import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { DeleteChatArg, DeleteChatState } from "../../assets/interfaces/interfaces";
import { fetchPastChats } from "./pastChatsSlice";

const initialState: DeleteChatState = {
  status: "idle",
  error: null,
};

// Define the async thunk
export const fetchDeleteChat = createAsyncThunk(
  "chat/fetchDeleteChat",
  async (arg: DeleteChatArg, { dispatch, rejectWithValue }) => {
    try {
      const { session_id, user_id } = arg;
      const { data } = await axios.delete(`${process.env.REACT_APP_SPL_URL}/tradegpt-delete-chat`, {
        data: {
          user_id,
          session_id,
        },
        withCredentials: true,
      });
      dispatch(fetchPastChats({ userId: user_id }));
      return data;
    }
    catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

// Create the slice
export const deleteChatSlice = createSlice({
  name: "delete-chat",
  initialState,
  reducers: {
    // standard reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeleteChat.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDeleteChat.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(fetchDeleteChat.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch messages";
      });
  },
});

// Export the reducer from the slice
export default deleteChatSlice.reducer;
