import React from "react";
import { Modal, Button, Divider, IconButton } from "@mui/material";
import PromptLogo from "../assets/images/finchatGPT/Prompts.png";
import Car from "../assets/images/finchatGPT/F1-car.png";
import IntroPromo from "../assets/images/finchatGPT/IntroPromo.png";
import { GPTLogo } from "./Plans/icons/GptLogo/GptLogo";
import { CrossIcon } from "./Plans/icons/GPTIcons/CrossIcon";

interface SidebarPromoModalProps {
  open: boolean;
  handleClose: () => void;
  promo: string | "f1" | "tradealgo" | "prompts" | "intro";
}

const SidebarPromoModal: React.FC<SidebarPromoModalProps> = ({
  open,
  handleClose,
  promo,
}) => {
  const handleClick = () => {
    let url;
    switch (promo) {
      case "tradealgo":
        url = "https://go.tradealgo.com/ai-investment-invitation";
        break;
      case "prompts":
        url
          = "https://go.tradealgo.com/tradegpt-prompts?ads_source=Platform_TradeGPT_Prompts";
        break;
      case "intro":
        url = "https://reports.tradersalgo.com/webinar-tradegpt-members/";
        break;
      case "f1":
      default:
        url = "https://go.tradealgo.com/f1";
        break;
    }
    window.open(url, "_blank");
  };
  const renderContent = () => {
    switch (promo) {
      case "tradealgo":
        return (
          <div className="shadow w-modal-mobile sm:w-modal-md rounded-lg p-6 bg-white text-mui-black-87 dark:bg-background/paper-elevation-16 dark:text-white">
            <div className="flex flex-col gap-y-6 relative">
              {/* Modal header */}
              <div className="flex flex-col gap-y-4 items-center text-center">
                <div className="h-[88px] w-[88px] p-4">
                  <GPTLogo className="fill-black dark:fill-white" />
                </div>
                <IconButton
                  size="small"
                  onClick={handleClose}
                  className="cross-icon"
                  sx={{ position: "absolute", top: 0, right: 0 }}
                >
                  <CrossIcon className="fill-current" />
                </IconButton>
                <h5 className="font-bold">Limited Time Opportunity</h5>
                {/* <Divider sx={{ width: "60%" }} /> */}
                <Divider className="w-full sm:w-[55%]" />
                {/* Modal body */}
                <h6 className="font-medium">
                  Own private shares in TradeAlgo during its hypergrowth stage
                </h6>
                <div className="body2">
                  <p className="mb-2">
                    TradeAlgo is working on two groundbreaking AI products—
                    TradeGPT (generative AI) and Midas AI (automated trading
                    platform)—that may become the world leader in the $1.3
                    trillion AI market.
                  </p>
                  <p>
                    For a limited time, you can claim private shares in
                    TradeAlgo for a chance to enjoy the upside from the
                    company's hypergrowth stage. But shares are extremely
                    limited. Enter your information below to learn more about
                    this opportunity.
                  </p>
                </div>
              </div>
              {/* Modal footer */}
              <div className="flex justify-center">
                <Button
                  className="button-primary"
                  variant="contained"
                  size="large"
                  onClick={handleClick}
                >
                  Get special invite
                </Button>
              </div>
            </div>
          </div>
        );
      case "prompts":
        return (
          <div className="bg-white rounded-lg shadow-lg w-full max-w-lg">
            {/* Modal header */}
            <div className="bg-black pt-10 pb-4 rounded-t-lg">
              <img
                src={PromptLogo}
                alt="PromptsLogo"
                className="h-auto w-32 mx-auto"
              />
            </div>
            <div className="text-center rounded-t-lg">
              <h3 className="text-3xl font-bold text-black pt-6 pb-4 px-10">
                Get 100+ TradeGPT Prompts For More Profitable Trading FREE!
              </h3>
            </div>
            <hr className="border-t border-gray-300 mx-auto w-2/12" />
            {/* Modal body */}
            <div className="pt-6 pb-4 px-10">
              <p className="text-sm text-center text-gray-600">
                Supercharge your TradeGPT experience with our
              </p>
              <p className="text-sm text-center text-gray-600">
                <b>"TradeGPT 100+ Prompts"</b>
                {" "}
                – the ultimate ebook for prompt
              </p>
              <p className="text-sm text-center text-gray-600">
                mastery. Whether you're a beginner or a seasoned trader, this
              </p>
              <p className="text-sm text-center text-gray-600">
                guide unlocks the secrets to crafting powerful prompts,
              </p>
              <p className="text-sm text-center text-gray-600">
                optimizing interactions, and maximizing TradeGPT's potential.
              </p>
              <p className="text-sm text-center text-gray-600 pt-4">
                Download now to elevate your trading game with step-by-step
              </p>
              <p className="text-sm text-center text-gray-600">
                instructions and valuable insights. Your journey to prompt
              </p>
              <p className="text-sm text-center text-gray-600">
                mastery begins here – seize the opportunity for success!
              </p>
            </div>
            {/* Modal footer */}
            <div className="flex flex-col items-center justify-center p-6 space-y-4">
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#007BFF",
                  color: "white",
                  textTransform: "none",
                  width: 270,
                }}
                onClick={handleClick}
              >
                Get Instant Access
              </Button>
            </div>
          </div>
        );
      case "intro":
        return (
          <div className="bg-white rounded-lg shadow-lg w-full max-w-lg">
            <img
              src={IntroPromo}
              alt="F1Logo"
              className="h-auto w-full rounded-t-lg"
            />
            {/* Modal header */}
            <div className="text-center">
              <h3 className="text-3xl font-bold px-10 text-black py-6">
                LEARN HOW TO MAKE MONEY WITH TRADEGPT
              </h3>
            </div>
            <hr className="border-t border-gray-300 mx-auto w-2/12" />
            {/* Modal body */}
            <div className="py-5  px-11">
              <p className="text-sm text-center text-gray-600">
                Learn to
                {" "}
                <b> boost your trading profits </b>
                {" "}
                with our free
                TradeGPT training. This session covers how to use TradeAlgo's
                AI, TradeGPT, to access instant data, analysis, and trade
                recommendations.
              </p>
              <p className="text-sm text-center text-gray-600 pt-4">
                Our experts will demonstrate effective tools and strategies for
                all traders, including beginners. Ideal for options traders and
                day traders interested in using AI. Check out the webinar for
                practical insights into AI-powered trading.
                {" "}
              </p>
            </div>
            {/* Modal footer */}
            <div className="flex flex-col items-center justify-center pt-4 pb-6">
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#007BFF",
                  color: "white",
                  textTransform: "none",
                  width: 270,
                }}
                onClick={handleClick}
              >
                Register Now
              </Button>
            </div>
          </div>
        );
      case "f1":
      default:
        return (
          <div className="bg-white rounded-lg shadow-lg w-full max-w-lg">
            <img
              src={Car}
              alt="F1Logo"
              className="h-auto w-full rounded-t-lg"
            />
            {/* Modal header */}
            <div className="text-center">
              <h3 className="text-3xl font-bold px-10 text-black pt-6 pb-4">
                Experience the Thrill of the Monaco Grand Prix
              </h3>
            </div>
            <hr className="border-t border-gray-300 mx-auto w-2/12" />
            {/* Modal body */}
            <div className="pt-4 pb-6">
              <h2 className="text-xl text-center font-semibold text-gray-900 px-14">
                Become a VIP Investor & Enjoy Fully-Inclusive F1 Experience (All
                Expenses Paid)
              </h2>
            </div>
            <div className="pb-4 px-10">
              <p className="text-sm text-center text-gray-600">
                The innovative TradeAlgo brand offers all-inclusive tickets to
              </p>
              <p className="text-sm text-center text-gray-600">
                the experience of a lifetime at the Formula 1 Grand Prix in
              </p>
              <p className="text-sm text-center text-gray-600">
                Monaco – the globe’s most iconic city for the A-Listers.
              </p>
              <p className="text-sm text-center text-gray-600 pt-4">
                Learn AI investing secrets, watch the F1 race, party with
              </p>
              <p className="text-sm text-center text-gray-600">
                celebrities, meet the F1 drivers, soak in the sun on
              </p>
              <p className="text-sm text-center text-gray-600">
                superyachts, savor chef-served meals, tour historic Monaco,
              </p>
              <p className="text-sm text-center text-gray-600">
                and relax in your private villa – with expenses all paid for.
              </p>
            </div>
            {/* Modal footer */}
            <div className="flex flex-col items-center justify-center pt-4 pb-6">
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#007BFF",
                  color: "white",
                  textTransform: "none",
                  width: 270,
                }}
                onClick={handleClick}
              >
                Find out more details
              </Button>
            </div>
          </div>
        );
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="flex items-center justify-center p-4 sm:p-0"
    >
      {renderContent()}
    </Modal>
  );
};

export default SidebarPromoModal;
