import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import SHA256 from "crypto-js/sha256";

// Utility to extract parameters
export const extractParams = (search) => {
  const params = new URLSearchParams(search);
  const source = [];
  // Only capture specific parameters that interest us and are known to have underscores
  const utmSource = params.get("utm_source");
  const utmContent = params.get("utm_content");
  // Append the values to the source array if they exist
  if (utmSource) {
    source.push(utmSource);
  }
  if (utmContent) {
    source.push(utmContent);
  }
  // Join the parts with an underscore
  return source.join("_");
};

export const useEnhancedNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const enhancedNavigate = (path, options = {}) => {
    // Append the URL search parameters to the new path
    const searchParams = location.search;
    navigate(`${path}${searchParams}`, options);
  };

  return enhancedNavigate;
};

export default useEnhancedNavigate;

export function urlIsAppScheme(url: string) {
  return url.startsWith(`tradegpt://`);
}

export function createCustomTokenForMobileApp(redirect: string) {
  // Now, lets get our customToken for the app. As the app is user web base sign in, we will need the custom token to sign in with firebase user
  axios
    .get(`${process.env.REACT_APP_SPL_URL}/user/auth-token`, {
      withCredentials: true,
    })
    .then((res) => {
      // Once we have our custom token and access token, lets send it to the app
      const { data } = res;
      const customToken = data?.token;

      const redirectUrl = addSessionDataToAuthRedirectUrl(
        redirect,
        customToken,
      );
      window.location.replace(redirectUrl);
      return;
    })
    .catch(e => alert(e));
}
export async function injectCjConversion(
  actionTrackerId,
  amount,
  userId,
  email,
  cjeCookie = "",
  orderId,
  items = [],
) {
  const emailHash = SHA256(email).toString();
  const scriptId = "cj-conversion";
  console.log({ orderId });
  // Remove the old script if it exists
  const oldScript = document.getElementById(scriptId);
  if (oldScript) {
    oldScript.parentNode.removeChild(oldScript);
  }

  const scriptCode = `
      if (!window.cj) window.cj = {};
      cj.order = {
        enterpriseId: '1575841',
        pageType: 'conversionConfirmation',
        userId: '${userId}',
        emailHash: '${emailHash}',
        orderId: '${orderId}',
        actionTrackerId: '${actionTrackerId}', 
        currency: 'USD', 
        amount: ${amount}, 
        discount: 0,
        coupon: '',
        cjeventOrder: '${cjeCookie}',
        items: ${JSON.stringify(items)}
      };
      (function(a,b,c,d){
        a='https://tradegpt-beta.tradealgo.com/proxydirectory/tags/810100192253/tag.js';
        b=document;c='script';d=b.createElement(c);d.src=a;
        d.type='text/java'+c;d.async=true;
        d.id='cjapitag';
        a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a)
      })();
    `;

  const script = document.createElement("script");
  script.id = scriptId;
  script.type = "text/javascript";
  script.innerHTML = scriptCode;

  document.head.appendChild(script);
}
export function injectTrackingJS(id: number) {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.innerHTML = `
      (_ckm = window._ckm || []).push(function cfgev() {
          CKMLib.configureEvents({
              domain: 'https://tatrck.net',
              offer_id: 2,
              event_id: ${id}
          });
          CKMLib.fireEvent();
      });
      if (typeof CKMLib != 'string') {
          CKMLib = 'loading';
          var pix = document.createElement('script');
          pix.type = 'text/javascript';
          pix.async = true;
          pix.src = '//affiliates.tradealgo.com/local_assets/js/jssdk/lib.js';
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(pix, s);
      }
  `;
  document.head.appendChild(script);
}

export async function injectTikTokScript() {
  try {
    // Unique identifier for the script
    const scriptId = "tk-tracking";

    // Check if the script already exists
    const existingScript = document.getElementById(scriptId);
    if (!existingScript) {
      const script = document.createElement("script");
      script.id = scriptId; // Assign the unique ID to the script
      script.type = "text/javascript";
      script.innerHTML = `
        !function (w, d, t) {
          w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
          ttq.load('CNJ6KVJC77U1PP7E3UN0');
          ttq.page();
        }(window, document, 'ttq');
      `;

      // Append the script to the head of the document
      document.head.appendChild(script);
    }
  }
  catch (error) {
    console.log(error, "error injecting TikTok script");
  }
}

export async function injectCjPageVisit(pageType, userId, email) {
  const scriptId = "cj-page-visit";
  const scriptEl = document.getElementById(scriptId);
  if (scriptEl) {
    scriptEl.parentNode.removeChild(scriptEl);
  }
  const emailHash = SHA256(email).toString();

  const scriptCode = `
    if (!window.cj) window.cj = {};
    cj.sitePage = {
      enterpriseId: 1575841,
      pageType: '${pageType}',
      userId: '${userId}',
      emailHash: '${emailHash}',
      referringChannel: 'N/A', // required for Cross Channel Journey
      cartSubtotal: 0,
      items: []
    };
    (function (a, b, c, d) {
      a = 'https://tradegpt-beta.tradealgo.com/proxydirectory/tags/810100192253/tag.js';
      b = document; c = 'script'; d = b.createElement(c); d.src = a;
      d.type = 'text/java' + c; d.async = true;
      d.id = 'cjapitag';
      a = b.getElementsByTagName(c)[0]; a.parentNode.insertBefore(d, a)
    })();
  `;

  const script = document.createElement("script");
  script.id = scriptId;
  script.type = "text/javascript";
  script.innerHTML = scriptCode;

  document.head.appendChild(script);
}

export function removeTikTokScript() {
  const scriptId = "tk-tracking";
  const scriptEl = document.getElementById(scriptId);
  if (scriptEl) {
    scriptEl.parentNode.removeChild(scriptEl);
  }
}

export function addSessionDataToAuthRedirectUrl(
  url: string,
  customToken: string,
) {
  if (!urlIsAppScheme(url)) {
    return url;
  }

  const updatedUrl = new URL(url);
  const searchParams = updatedUrl.searchParams;
  searchParams.set("customToken", customToken ?? "");

  return updatedUrl.toString();
}
