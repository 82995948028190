import { PromptsState, SamplePrompt } from "../../assets/interfaces/interfaces";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

const initialState: PromptsState = {
  prompts: [], // Initialized as an empty array
  status: "idle",
  error: null,
};
function pickFourRandomItems(arr: SamplePrompt[]) {
  const result: SamplePrompt[] = [];
  // If the array has less than 4 items, return a copy of the array
  if (arr.length <= 4) {
    return arr.slice();
  }
  while (result.length < 4) {
    const randomIndex = Math.floor(Math.random() * arr.length);
    const item = arr[randomIndex];
    // Only add the item if it's not already in the result
    if (!result.includes(item)) {
      result.push(item);
    }
  }

  return result;
}

function extractTitleAndDescription(text: string) {
  const titleRegex = /\*\*(.*?)\*\*/;
  const titleMatch = text.match(titleRegex);

  if (titleMatch && titleMatch[1]) {
    // Extract title and description
    const title = titleMatch[1];
    const description = text.replace(titleRegex, "").trim();

    return { title, description };
  }
  else {
    // If no title found, return the whole text as description
    return { title: "", description: text };
  }
}

// Define the async thunk
export const fetchPrompts = createAsyncThunk(
  "prompts/fetchPrompts",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_SPL_URL}/tradegpt-recommended-prompts?limit=20`, { withCredentials: true });
      return data;
    }
    catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

// Create the slice
export const promptsSlice = createSlice({
  name: "prompts",
  initialState,
  reducers: {
    // standard reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrompts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPrompts.fulfilled, (state, action: PayloadAction<(SamplePrompt & { query: string })[]>) => {
        state.status = "succeeded";
        const allPrompts = action.payload.map(item => ({
          ...extractTitleAndDescription(item?.query),
          type: item.type,
        }));
        state.prompts = pickFourRandomItems(allPrompts);
      })
      .addCase(fetchPrompts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch messages";
      });
  },
});

// Export the reducer from the slice
export default promptsSlice.reducer;
