import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Link, Typography, Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import "./ChangeLogsPage.scss";

const ChangeLogsPage = () => {
  const changeLogs = useSelector((state: { changeLogs }) => state.changeLogs.logs);
  const isLoading = useSelector((state: { changeLogs }) => state.changeLogs.isLoading);
  const isDarkMode = useSelector((state: { theme }) => state.theme.darkMode);
  const [activeId, setActiveId] = useState("");

  useEffect(() => {
    if (changeLogs.length && activeId === "") {
      setActiveId(String(changeLogs[0].id));
    }
  }, [changeLogs]);

  const scrollToLog = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onMouseEnter = (e) => {
    if (e.currentTarget && e.currentTarget.id) {
      const id = e.currentTarget.id.replace("log-", "");
      setActiveId(id);
    }
  };

  const handleLinkClick = (id) => {
    setActiveId(String(id));
    scrollToLog(`log-${id}`);
  };

  return (
    <div className="h-screen w-full pt-16 text-mui-black-87 dark:text-white">
      <div className="flex flex-col w-full max-h-full xl:w-[960px] mx-auto">
        <div className="px-4 mt-4">
          <Typography variant="h4">Release Notes</Typography>
        </div>
        <div className="px-4 mt-2 mb-8 text-mui-black-60 dark:text-mui-white-70">
          <Typography variant="h5">A changelog of the latest updates for TradeGPT</Typography>
        </div>
        <div className="flex flex-1 overflow-y-auto divide-x gap-8">
          <div className="flex flex-2 flex-col scrollable-section gap-8 pb-4">
            {
              isLoading
                ? (
                    <>
                      <Skeleton sx={{ bgcolor: isDarkMode ? "grey.200" : "#e0e0e0" }} height={100} />
                      <Skeleton sx={{ bgcolor: isDarkMode ? "grey.200" : "#e0e0e0" }} height={100} />
                      <Skeleton sx={{ bgcolor: isDarkMode ? "grey.200" : "#e0e0e0" }} height={100} />
                    </>
                  )
                : changeLogs.map(log => (
                  <div id={`log-${log.id}`} key={`log-${log.id}`} onMouseEnter={onMouseEnter}>
                    <div className="flex px-4">
                      <Typography variant="h6" fontWeight="medium">{log.title}</Typography>
                    </div>
                    <div className="px-4 opacity-50 text-mui-black-60 dark:text-mui-white-70">
                      <Typography variant="caption">
                        {moment.utc(log.date).format("MMM D, YYYY")}
                        {" "}
                        • v
                        {log.version}
                      </Typography>
                    </div>
                    <div className="mt-2">
                      <ReactMarkdown className="markdown-body change-log-markdown ">
                        {log.description}
                      </ReactMarkdown>
                    </div>
                  </div>
                ))
            }
          </div>
          <div className="hidden md:flex flex-col gap-4 flex-1 mt-1 scrollable-section pl-2 pb-2">
            {
              isLoading
                ? (
                    <>
                      <Skeleton sx={{ bgcolor: isDarkMode ? "grey.200" : "#e0e0e0" }} height={30} />
                      <Skeleton sx={{ bgcolor: isDarkMode ? "grey.200" : "#e0e0e0" }} height={30} />
                      <Skeleton sx={{ bgcolor: isDarkMode ? "grey.200" : "#e0e0e0" }} height={30} />
                    </>
                  )
                : changeLogs.map(log => (
                  <Link
                    key={`link-${log.id}`}
                    color="inherit"
                    variant="body2"
                    underline="hover"
                    className={`flex flex-col cursor-pointer px-4 py-1.5 hover:bg-mui-gray-04 dark:hover:bg-mui-light-gray-08 gap-1 ${activeId === String(log.id) ? "bg-mui-gray-08" : ""} ${activeId === String(log.id) ? "dark:bg-mui-light-gray-16" : ""}`}
                    onClick={() => handleLinkClick(log.id)}
                  >
                    <p className="text-base">{log.title}</p>
                    <p className="text-mui-black-60 dark:text-mui-white-70">{moment.utc(log.date).format("MMM D, YYYY")}</p>
                  </Link>
                ))
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeLogsPage;
