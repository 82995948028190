import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";
import { showNotification } from "../../redux/slices/notificationSlice";
import { OauthSignInFucntion } from "../../assets/interfaces/interfaces";
import { v4 as uuidv4 } from "uuid";

// Function to handle Microsoft Sign-In
export const signInWithMicrosoft: OauthSignInFucntion = async (closeModal, dispatch, navigate) => {
  try {
    const auth = getAuth();
    const provider = new OAuthProvider("microsoft.com");
    const result = await signInWithPopup(auth, provider);
    const credential = OAuthProvider.credentialFromResult(result);
    const token = credential && credential.accessToken;
    const user = result.user;
    if (navigate) {
      const newSessionId = uuidv4();
      navigate(`/chat/${newSessionId}`);
    }
    dispatch(showNotification({ message: "Sign In Successful!", severity: "success", horizontal: "right" }));
    closeModal();
    return { token, user };
  }
  catch (error) {
    console.log({ error }, "in sign in with Microsoft");
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    const email = error.email; // Email might not be available in the case of Microsoft
    const credential = OAuthProvider.credentialFromError(error);
    dispatch(showNotification({ message: errorMessage, severity: "error", horizontal: "right" }));
    return { errorCode, errorMessage, email, credential };
  }
};
