// src/redux/slices/themeSlice.js
import { createSlice } from "@reduxjs/toolkit";
const local = localStorage.getItem("tradegpt-darkmode");
if (!local) {
  localStorage.setItem("tradegpt-darkmode", "true");
}
const initialState = {
  darkMode: local === "true" || local === null,
};
const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleDarkMode: (state) => {
      state.darkMode = !state.darkMode;
    },
  },
});

export const { toggleDarkMode } = themeSlice.actions;
export default themeSlice.reducer;
