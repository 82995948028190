import { createSlice, createAsyncThunk, PayloadAction, current } from "@reduxjs/toolkit";
import axios from "axios";
import { ChatMessage, ChatState, FetchChatsArg } from "../../assets/interfaces/interfaces";
import moment from "moment";

const initialState: ChatState = {
  messages: [], // Initialized as an empty array
  tempMessages: [],
  status: "idle",
  error: null,
};

// Define the async thunk
export const fetchPastChats = createAsyncThunk(
  "chat/fetchPastChats",
  async (arg: FetchChatsArg, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_SPL_URL}/trade-chats-list/${arg.userId}`, { withCredentials: true });
      const sortedByDate = data.sort((a: ChatMessage, b: ChatMessage) => {
        // Convert date strings to moment objects and then compare
        const dateA = moment(a.date);
        const dateB = moment(b.date);

        if (dateA.isAfter(dateB)) {
          return -1; // a is newer than b
        }
        if (dateB.isAfter(dateA)) {
          return 1; // b is newer than a
        }
        return 0; // dates are equal
      });
      return sortedByDate;
    }
    catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

// Create the slice
export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    clearMessages: (state) => {
      state.messages = []; // Reset messages to an empty array
    },
    addMessageOptimistically: (state, action: PayloadAction<ChatMessage>) => {
      if (current(state).messages.find(({ sessionId }) => action.payload.sessionId === sessionId)) return;
      state.tempMessages.unshift(action.payload);
      state.messages.unshift(action.payload);
    },
    deleteOptimisticMessage: (state, action: PayloadAction<string>) => {
      const currState = current(state);
      const remainingTemp = currState.tempMessages.filter(({ sessionId }) => sessionId !== action.payload);
      state.tempMessages = remainingTemp;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPastChats.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPastChats.fulfilled, (state, action: PayloadAction<ChatMessage[]>) => {
        const currState = current(state);
        const notYetGenerated = currState.tempMessages.filter(chatMessage => action.payload.every((newChatMessage) => {
          return newChatMessage.sessionId !== chatMessage.sessionId;
        }));
        state.status = "succeeded";
        state.messages = notYetGenerated.concat(action.payload);
      })
      .addCase(fetchPastChats.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch messages";
      });
  },
});

export const { clearMessages, addMessageOptimistically, deleteOptimisticMessage } = chatSlice.actions;

// Export the reducer from the slice
export default chatSlice.reducer;
