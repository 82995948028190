import { all } from "redux-saga/effects";
import finchatSaga from "./finchatSaga";
import watchSignOutSaga from "./authSaga";
import rootWebSocketSaga from "./websocketSaga";

export default function* rootSaga() {
  yield all([
    finchatSaga(),
    watchSignOutSaga(),
    rootWebSocketSaga(),
  ]);
}
