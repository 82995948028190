import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import { CrossIcon } from "../Plans/icons/GPTIcons/CrossIcon";

export default function ConfirmDeleteModal({
  open,
  handleClose,
  handleChatDelete,
}: {
  open: boolean;
  handleClose;
  handleChatDelete;
}) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="dialog-model"
      >
        <DialogTitle className="dialog-title relative">
          <h6 className="font-medium">Clear Conversation History</h6>
          <IconButton
            size="small"
            onClick={handleClose}
            className="cross-icon"
            sx={{ position: "absolute", top: 0, right: 0 }}
          >
            <CrossIcon className="fill-current" />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog-content">
          <DialogContentText
            id="alert-dialog-slide-description"
            className="dialog-text"
          >
            <p className="body2">
              Are you sure you would like to delete all your chats? The deletion
              is irreversible.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-buttons">
          <Button
            variant="outlined"
            size="large"
            onClick={handleClose}
            className="button-outlined"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            size="large"
            className="button-danger"
            onClick={handleChatDelete}
          >
            Confirm deletion
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
