import { FormEvent, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useSignin } from "./useSignIn"; // Adjust the path as necessary
import {
  DefaultSignInProps,
  ValidationFormFunc,
} from "../../assets/interfaces/interfaces";
import { showNotification } from "../../redux/slices/notificationSlice";
import { useDispatch } from "react-redux";

export const validateSignInForm: ValidationFormFunc = (email, password) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const errors = { email: "", password: "" };
  let isValid = true;

  if (!email) {
    errors.email = "Email is required.";
    isValid = false;
  }
  else if (!emailRegex.test(email)) {
    errors.email = "Please enter a valid email address.";
    isValid = false;
  }

  if (!password) {
    errors.password = "Password is required.";
    isValid = false;
  }

  return { valid: isValid, errors };
};

const DefaultSignIn: React.FC<DefaultSignInProps> = ({
  emailValue,
  setFlow,
  setOpen,
}) => {
  const [email, setEmail] = useState<string>(emailValue);
  const [password, setPassword] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const signin = useSignin();
  const dispatch = useDispatch();
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    const { valid, errors } = validateSignInForm(email, password);
    // Update error states
    setEmailError(errors.email);
    setPasswordError(errors.password);
    if (!valid) return;
    try {
      await signin(email, password, setOpen);
      dispatch(
        showNotification({
          message: "Sign In Successful!",
          severity: "success",
          horizontal: "right",
        }),
      );
    }
    catch (error) {
      console.log({ error });
      dispatch(
        showNotification({
          message: error.code.replace("auth/", "").replaceAll("-", " "),
          severity: "error",
          horizontal: "right",
        }),
      );
    }
  };
  return (
    <div className="w-[550px] h-[534px] justify-center relative bg-white rounded-[10px] flex-col items-center gap-10 inline-flex">
      <div className=" items-start inline-flex">
        <div className="text-center text-zinc-900 text-[28px] font-bold font-['Inter'] leading-[30px]">
          Enter Password
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="flex-col justify-center items-center gap-6 flex"
      >
        <TextField
          label="Email"
          variant="outlined"
          value={email}
          onChange={e => setEmail(e.target.value)}
          className="w-[295px] mb-4"
          error={!!emailError}
          helperText={emailError}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={e => setPassword(e.target.value)}
          className="w-[295px]"
          error={!!passwordError}
          helperText={passwordError}
        />
        <div className="justify-start items-start gap-1 inline-flex">
          <div className="cursor-pointer text-center text-blue-600 text-sm font-normal font-['Inter'] leading-[18px]">
            Forgot Password?
          </div>
        </div>
        <Button
          type="submit"
          variant="contained"
          className="w-[295px] h-[50px] text-white font-inter text-[16px] font-medium leading-[30px] tracking-[-0.32px] !normal-case !bg-[#1C1C1E]"
        >
          Continue
        </Button>
        <div className="flex-col justify-start items-center gap-4 flex">
          <div className="justify-start items-start gap-1 inline-flex">
            <div className="text-center text-zinc-900 text-sm font-normal font-['Inter'] leading-[18px]">
              Don’t have an account?
            </div>
            <div
              onClick={() => setFlow(2)}
              className="text-center cursor-pointer text-blue-600 text-sm font-normal font-['Inter'] leading-[18px]"
            >
              Sign up here
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DefaultSignIn;
