import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    "fontFamily": "Inter, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Helvetica, Arial, sans-serif",
    "h1/light-300": {
      fontSize: "6rem",
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "116.7%",
      textTransform: "none",
      textDecorationLine: "none",
    },
    "h2/light-300": {
      fontSize: "3.75rem",
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "120%",
      textTransform: "none",
      textDecorationLine: "none",
    },
    "h3/regular-400": {
      fontSize: "3rem",
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "116.7%",
      textTransform: "none",
      textDecorationLine: "none",
    },
    "h4/regular-400": {
      fontSize: "2.125rem",
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "123.5%",
      textTransform: "none",
      textDecorationLine: "none",
    },
    "h5/regular-400": {
      fontSize: "1.5rem",
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "133.4%",
      textTransform: "none",
      textDecorationLine: "none",
    },
    "h5/regular-nums": {
      fontSize: "1.5rem",
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "133.4%",
      textTransform: "none",
      textDecorationLine: "none",
    },
    "h5/bold-700": {
      fontSize: "1.5rem",
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "133.4%",
      textTransform: "none",
      textDecorationLine: "none",
    },
    "h6/medium-500": {
      fontSize: "1.25rem",
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "160%",
      textTransform: "none",
      textDecorationLine: "none",
    },
    "h6/medium-nums": {
      fontSize: "1.25rem",
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "160%",
      textTransform: "none",
      textDecorationLine: "none",
    },
    "h6/bold-700": {
      fontSize: "1.25rem",
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "160%",
      textTransform: "none",
      textDecorationLine: "none",
    },
    "body1/regular-400": {
      fontSize: "1rem",
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "150%",
      textTransform: "none",
      textDecorationLine: "none",
    },
    "body1/regular-nums": {
      fontSize: "1rem",
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "150%",
      textTransform: "none",
      textDecorationLine: "none",
    },
    "body2/regular-400": {
      fontSize: "0.875rem",
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "150%",
      textTransform: "none",
      textDecorationLine: "none",
    },
    "body2/regular-nums": {
      fontSize: "0.875rem",
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "150%",
      textTransform: "none",
      textDecorationLine: "none",
    },
    "subtitle1/regular-400": {
      fontSize: "1rem",
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "175%",
      textTransform: "none",
      textDecorationLine: "none",
    },
    "subtitle2/medium-500": {
      fontSize: "0.875rem",
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "157%",
      textTransform: "none",
      textDecorationLine: "none",
    },
    "overline/regular-400": {
      fontSize: "0.75rem",
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "266%",
      textTransform: "uppercase",
      textDecorationLine: "none",
    },
    "caption/regular-400": {
      fontSize: "0.75rem",
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "166%",
      textTransform: "none",
      textDecorationLine: "none",
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          // Map the new variant to render a <h1> by default
          "h1/light-300": "h1",
          "h2/light-300": "h2",
          "h3/regular-400": "h3",
          "h4/regular-400": "h4",
          "h5/regular-400": "h5",
          "h5/regular-nums": "h5",
          "h5/bold-700": "h5",
          "h6/medium-500": "h6",
          "h6/medium-nums": "h6",
          "h6/bold-700": "h6",
          "body1/regular-400": "span",
          "body1/regular-nums": "span",
          "body2/regular-400": "span",
          "body2/regular-nums": "span",
          "subtitle1/regular-400": "h2",
          "subtitle2/medium-500": "h2",
          "overline/regular-400": "span",
          "caption/regular-400": "caption",
        },
      },
    },
  },
});
