import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";
import { showNotification } from "../../redux/slices/notificationSlice";
import { OauthSignInFucntion } from "../../assets/interfaces/interfaces";
import { v4 as uuidv4 } from "uuid";

export const signInWithApple: OauthSignInFucntion = async (closeModal, dispatch, navigate) => {
  try {
    const auth = getAuth();
    const provider = new OAuthProvider("apple.com");
    const result = await signInWithPopup(auth, provider);
    // Apple does not provide a credential in the same way, so this part differs from Google
    const user = result.user;
    // Create or update the Firestore document for the user
    if (navigate) {
      const newSessionId = uuidv4();
      navigate(`/chat/${newSessionId}`);
    }
    dispatch(showNotification({ message: "Sign In Successful with Apple!", severity: "success", horizontal: "right" }));
    closeModal();
    return { user }; // Token is typically not used with Apple like it is with Google
  }
  catch (error) {
    console.log(error, "error in sighn in with apple");
    dispatch(showNotification({ message: error.message, severity: "error", horizontal: "right" }));
    return { errorCode: error.code, errorMessage: error.message, email: error.email };
  }
};
