import { getAuth, signInWithEmailAndPassword, User as FUser } from "firebase/auth";
import { useDispatch } from "react-redux";
import { setUser, setAuthLoading, clearUser } from "./authActions";
import { AppDispatch } from "../../redux/store";
import { v4 as uuidv4 } from "uuid";
import useEnhancedNavigate from "../Session/helpers";
import { User } from "../../assets/interfaces/interfaces";

export const useSignin = () => {
  const dispatch = useDispatch<AppDispatch>(); // Use the typed dispatch
  const auth = getAuth();
  const navigate = useEnhancedNavigate();
  const signin = async (email: string, password: string, setOpen, landing?: boolean) => {
    let isSuccess = false;
    try {
      dispatch(setAuthLoading(true)); // Start loading
      const userCredential = await signInWithEmailAndPassword(auth, email, password);

      const firebaseUser: FUser & Partial<User> & { signInAttempts?: number | string } = userCredential.user;
      // Fetch additional user details from Firestore
      if (firebaseUser) {
        const userProfile = {
          accessToken: firebaseUser.accessToken,
          userId: firebaseUser.uid,
          display_name: firebaseUser.displayName,
          email: firebaseUser.email,
          photoURL: firebaseUser.photoURL || userCredential.user.photoURL,
          phoneNumber: firebaseUser.phoneNumber,
          country: firebaseUser.country,
          state: firebaseUser.state,
          name: firebaseUser.name,
          ip: firebaseUser.ip,
          sign_in_attempts: firebaseUser.signInAttempts,
          feature_flags: firebaseUser.feature_flags,
        };
        dispatch(setUser(userProfile));
        isSuccess = true;
      }
      else {
        // Handle the case where Firestore document doesn't exist
        dispatch(clearUser());
      }
      setOpen(false);
    }
    catch (error) {
      console.error(error);
      dispatch(clearUser());
      throw error; // You can handle the error as needed
    }
    finally {
      dispatch(setAuthLoading(false)); // Stop loading
      console.log("setting auth loading");
      if (isSuccess && landing) {
        const newSessionId = uuidv4();
        navigate(`/chat/${newSessionId}`);
      }
    }
  };

  return signin;
};
